import { Location } from '../../../../functions/src/shared/contracts/Location';
import { IIcon } from '../../util/gui-decorators';
class LocationService {
    public getIconFor(location: Location): IIcon {
        return {
            customSvg: `location/tiles/${location}_tile`,
        };
    }
}
export const locationService = new LocationService();
