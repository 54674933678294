import store from '@/store/store';
import { axiosHttp } from '@/util/AxiosHttp';
import { formatDate } from '@/util/util-functions';
import axios from 'axios';
import {
    IGlossDetail,
    ISignDetail,
    ISignOverview,
    ISignOverviewsWithTotal,
} from '../../../../functions/src/shared/contracts/api/GlossContract';
import { IRegion } from '../../../../functions/src/shared/contracts/api/MasterdataContract';
import { Constants } from '../Constants';

class SignService {
    public async getSummaryName(sign: ISignOverview | ISignDetail): Promise<string> {
        await store.dispatch('cache/loadRegions');
        const regions = store.getters['cache/regions'] as IRegion[];
        const regionAbbreviations = (sign.regions as string[])
            .map(regionString => {
                const region = regions.find(r => r.name === regionString || r.id === regionString);
                return region && region.abbreviation;
            })
            .filter(r => !!r);
        return [sign.glossName, ...regionAbbreviations].join('-');
    }

    public async setSignOfTheWeek(date?: Date, glossId?: string, signId?: number): Promise<ISignOverview> {
        const response = await axiosHttp.http.post<ISignOverview>(
            `${Constants.baseOperationsUrl}/signs/signOfTheWeek`,
            {
                glossId,
                signId,
                startingDay: date && formatDate(date),
            },
        );
        return response.data;
    }

    public async getSignOfTheWeek(date?: Date): Promise<ISignOverview> {
        // Not custom axiosHttp because caching needs to be disabled for this call.
        let url = `${Constants.baseApiUrl}/signs/signOfTheWeek`;
        if (date) {
            url = `${url}?startingDay=${formatDate(date)}`;
        }
        const response = await axios.get(url);
        return response.data;
    }

    public async getSimilarSigns(sign: ISignDetail): Promise<ISignOverviewsWithTotal> {
        const response = await axiosHttp.http.get<ISignOverviewsWithTotal>(`${Constants.baseApiUrl}/signs/similar`, {
            params: {
                eh: sign.endHandshape,
                el: sign.endLocation,
                sh: sign.startHandshape,
                sl: sign.startLocation,
            },
        });
        return response.data;
    }

    public async getGlossDetail(glossId: string): Promise<IGlossDetail> {
        const response = await axiosHttp.http.get<IGlossDetail>(`glosses/${glossId}`);
        return response.data;
    }

    public getSignOfTheWeekWeeks(): Date[] {
        const nrOfSignsOfTheWeekToShow = 4;
        const now = new Date();
        const day = now.getDay();
        const diff = now.getDate() - day + (day === 0 ? -6 : 1);
        const weeks: Date[] = [new Date(now.setDate(diff))];
        for (let i = 1; i < nrOfSignsOfTheWeekToShow; i++) {
            weeks.push(new Date(now.setDate(now.getDate() + 7)));
        }
        return weeks;
    }
}

export const signService = new SignService();
