import { IVGTCConfig } from './ConfigContract';

export class Constants {
    public static baseUrl: string;
    public static baseApiUrl: string;
    public static baseOperationsUrl: string;
    public static baseDataUrl: string;
    public static contactAddress: string;
    public static analyticsUrl: string;
    public static faqUrl: string = 'https://www.vlaamsegebarentaal.be/faq';
    public static methodologyUrl: string = 'https://www.vlaamsegebarentaal.be/methodologie';
    public static privacyPolicyUrl: string = 'https://www.vgtc.be/privacy';

    public static init(config: IVGTCConfig): void {
        Constants.baseApiUrl = `${config.baseUrl}/api`;
        Constants.baseDataUrl = `${config.baseUrl}/data`;
        Constants.baseOperationsUrl = `${config.baseUrl}/operations`;
        Constants.baseUrl = config.baseUrl;
        Constants.contactAddress = config.contactAddress;
        Constants.analyticsUrl = config.analyticsUrl;
    }
}
