import { IVGTCConfig } from '../ConfigContract';

declare const doorbell: {
    getProperty(name: string): any;
    send(message: string, email?: string, success?: () => any, error?: (e: string) => any): void;
    setProperty(name: string, value: any): void;
};

class DoorbellService {
    public readonly INVALID_EMAIL_ERROR: string = 'Invalid email address';

    public init(config: IVGTCConfig): void {
        (window as any).doorbellOptions = {
            appKey: config.doorbellConfig.key,
            hideButton: true,
            id: config.doorbellConfig.appId,
        };
        ((w, d, t) => {
            let hasLoaded = false;
            function l() {
                if (hasLoaded) {
                    return;
                }
                hasLoaded = true;
                (window as any).doorbellOptions.windowLoaded = true;
                const g: any = d.createElement(t);
                g.id = 'doorbellScript';
                g.type = 'text/javascript';
                g.async = true;
                g.src =
                    'https://embed.doorbell.io/button/' +
                    (window as any).doorbellOptions.id +
                    '?t=' +
                    new Date().getTime();
                (d.getElementsByTagName('head')[0] || d.getElementsByTagName('body')[0]).appendChild(g);
            }
            if (w.attachEvent) {
                w.attachEvent('onload', l);
            } else if (w.addEventListener) {
                w.addEventListener('load', l, false);
            } else {
                l();
            }
            if (d.readyState === 'complete') {
                l();
            }
        })(window as any, document, 'script');
    }

    public submitFeedback(message: string, email?: string, properties?: { [key: string]: Object }): Promise<void> {
        return new Promise((resolve, reject) => {
            for (const key in properties) {
                if (properties.hasOwnProperty(key)) {
                    doorbell.setProperty(key, properties[key]);
                }
            }
            doorbell.send(
                message,
                email,
                () => {
                    this.clearProperties(properties || {});
                    resolve();
                },
                e => {
                    this.clearProperties(properties || {});
                    reject(e);
                },
            );
        });
    }

    private clearProperties(properties: { [key: string]: Object }) {
        for (const key in properties) {
            if (properties.hasOwnProperty(key)) {
                doorbell.setProperty(key, undefined);
            }
        }
    }
}

export const doorbellService = new DoorbellService();
