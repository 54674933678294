<template>
    <div>
        <div class="sign-feedback__sub-header__text mb-4">{{ $t('sign_feedback.step5.subtitle') }}</div>
        <div class="row">
            <div class="col-6 pr-5">
                <div class="d-flex justify-content-between mb-2"><span v-html="$t('sign_feedback.step5.sign') "></span>
                </div>
                <SearchResultCard :search-result="feedbackGloss.gloss"
                                  :show-region="false"
                                  :show-detail="false">
                </SearchResultCard>
            </div>
            <div class="col-6">
                <div v-if="match" v-html="$t('sign_feedback.step5.explanation_match')"></div>
                <div v-else v-html="$t('sign_feedback.step5.explanation_no_match')"></div>
                <FeedbackQuestionBlock v-model="answers.hands" :icon="['far','hand']"
                                       question="sign_feedback.step5.question_handshape"></FeedbackQuestionBlock>
                <FeedbackQuestionBlock v-model="answers.location" :icon="['fas','person']"
                                       question="sign_feedback.step5.question_location"></FeedbackQuestionBlock>
                <FeedbackQuestionBlock v-model="answers.move" :icon="['fas','up-down-left-right']"
                                       question="sign_feedback.step5.question_move"></FeedbackQuestionBlock>
                <FeedbackQuestionBlock v-model="answers.mouth" :icon="['fas','lips']"
                                       question="sign_feedback.step5.question_mouth"></FeedbackQuestionBlock>
                <div class="d-flex align-items-center justify-content-end mt-5">
                    <Button type="cancel"
                            @cancel="cancel"></Button>
                    <Button class="ml-5" type="button" label="sign_feedback.step5.send" :icon="['far', 'share']"
                            :disabled="disabled || loading"
                            :loading="loading"
                            @click="nextStep"></Button>
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import Button from '@/components/general/Button.vue';
import SearchResultCard from '@/components/search/SearchResultCard.vue';
import FeedbackQuestionBlock from '@/components/signFeedback/FeedbackQuestionBlock.vue';
import { ISignOverview } from '../../../../functions/src/shared/contracts/api/GlossContract';

@Component({ components: { FeedbackQuestionBlock, Button, SearchResultCard } })
export default class FeedbackQuestions extends Vue {
    @Prop() feedbackGloss: { gloss: ISignOverview, score: number, feedbackGloss: boolean };
    @Prop() match: boolean;
    @Prop() loading: boolean;

    public answers = { hands: null, location: null, move: null, mouth: null };

    @Emit('nextStep')
    public nextStep() {
        return this.answers;
    }

    @Emit('cancel')
    public cancel() {
        return;
    }

    public get disabled() {
        const values = Object.values(this.answers);
        return !values.every(v => typeof v === 'string');
    }
}
</script>

<style lang="scss">
</style>

