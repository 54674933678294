import Vue from 'vue';
import Router from 'vue-router';
import store from './store/store';
import Admin from './views/Admin.vue';
import ContactInfo from './views/ContactInfo.vue';
import FeedbackVideo from './views/FeedbackVideo.vue';
import GlossDetail from './views/GlossDetail.vue';
import Login from './views/Login.vue';
import Root from './views/Root.vue';
import Search from './views/Search.vue';
import SignFeedback from '@/views/SignFeedback.vue';

Vue.use(Router);

const router = new Router({
    base: process.env.BASE_URL,
    mode: 'history',
    routes: [
        {
            children: [
                {
                    alias: '/',
                    component: Search,
                    meta: {
                        hideNav: true,
                    },
                    name: 'search',
                    path: '/search',
                },
                {
                    component: GlossDetail,
                    name: 'glossdetail',
                    path: '/gloss/:id',
                }, {
                    component: SignFeedback,
                    name: 'signfeedback',
                    path: '/signBuddy',
                    meta: {
                        preLoad: false,
                    },
                },
                {
                    component: ContactInfo,
                    meta: {
                        hideFooter: true,
                    },
                    name: 'info',
                    path: '/info',
                },
                {
                    component: Login,
                    name: 'login',
                    path: '/login',
                },
                {
                    component: Admin,
                    meta: {
                        requiresAuth: true,
                    },
                    name: 'admin',
                    path: '/admin',
                },
                {
                    component: FeedbackVideo,
                    meta: {
                        requiresAuth: true,
                    },
                    name: 'feedbackvideo',
                    path: '/admin/feedbackVideo/:name',
                },
            ],
            component: Root,
            name: 'root',
            path: '/',
            redirect: '/search',
        },
        {
            path: '*',
            redirect: '/',
        },
    ],
});

router.beforeEach((to, from, next) => {
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
    const isSignedIn = store.getters['user/isSignedIn'];

    if (requiresAuth && !isSignedIn) {
        next({
            name: 'login',
            query: {
                redirect: to.fullPath,
            },
        });
    } else {
        next();
    }
});

export default router;
