export enum Handshape {
    //Shape_B = 'B',
    //Shape_B_q = 'B"',
    Shape_B_B_q = 'B;B"',
    Shape_S = 'S',
    //Shape_5 = '5',
    //Shape_5_q = '5"',
    Shape_5_5_q = '5;5"',
    //Shape_O = 'O',
    //Shape_O_c = 'O^',
    Shape_O_O_c = 'O;O^',
    //Shape_C = 'C',
    //Shape_OB = 'OB',
    Shape_C_OB = 'C;OB',
    //Shape_1 = '1',
    //Shape_1_q = '1"',
    Shape_1_1_q = '1;1"',
    //Shape_bO = 'bO',
    //Shape_bO_p = 'bO+',
    Shape_bO_bO_p = 'bO;bO+',
    //Shape_bC = 'bC',
    //Shape_bC_p = 'bC+',
    Shape_bC_bC_p = 'bC;bC+',
    //Shape_T = 'T',
    //Shape_T_p = 'T+',
    Shape_T_T_p = 'T;T+',
    //Shape_Q = 'Q',
    //Shape_Q_p = 'Q+',
    Shape_Q_Q_p = 'Q;Q+',
    Shape_Y = 'Y',
    Shape_8 = '8',
    Shape_C8 = 'C8',
    //Shape_V = 'V',
    //Shape_V_q = 'V"',
    Shape_V_V_q = 'V;V"',
    //Shape_H = 'H',
    //Shape_H_q = 'H"',
    Shape_H_H_q = 'H;H"',
    //Shape_2bO = '2bO',
    //Shape_2bO_c = '2bO^',
    Shape_2bO_2bO_c = '2bO;2bO^',
    Shape_M = 'M',
    Shape_3 = '3',
    //Shape_A = 'A',
    //Shape_A_q = 'A"',
    Shape_A_A_q = 'A;A"',
    Shape_L = 'L',
    Shape_W = 'W',
    // Shape_W_q = 'W"',
    Shape_W_W_q = 'W;W"',
    // Shape_bY = 'bY',
    // Shape_Y1 = 'Y1',
    Shape_bY_Y1 = 'bY;Y1',
}
