import store from '@/store/store';
import Vue from 'vue';
import VueGtag from 'vue-gtag';
import router from '@/router';

class GoogleAnalytics {
    public init(trackingId: string) {
        Vue.use(VueGtag, {
            enabled: store.getters['user/hasConsentedToCookies'],
            config: {
                id: trackingId,
            },
        }, router);

        store.watch(
            (state, getters) => getters['user/hasConsentedToCookies'] as boolean,
            consented => {
                if (consented) {
                    (Vue as any).$gtag.optIn();
                } else {
                    (Vue as any).$gtag.optOut();
                }
            },
        );
    }
}

export const ga = new GoogleAnalytics();
