<template>
    <div class="search-result d-flex flex-column justify-content-start">
    <span v-if="showRegion" class="search-result__region d-flex align-items-end flex-grow-1">
      <RegionIcon :regions="searchResult && searchResult.regions"
                  class="search-result__region__icon" />
      <span class="ml-2">{{ searchResult.regions | translateRegions | join(', ') }}</span>
    </span>
        <div class="mt-1">
            <VideoPlayer class="search-result__video"
                         :videoSrc="searchResult && searchResult.video"
                         :videoDownloadName="videoDownloadName"
                         @loadingVideoFailed="reportError">
                <template slot="missing-video">
                    <MissingSignVideo :sign="searchResult" />
                </template>
            </VideoPlayer>
            <div>
                <b-badge v-for="label in labelTranslations"
                         pill
                         variant="accent"
                         class="label-badge">
                    {{ label.name }}
                </b-badge>
            </div>
            <div class="search-result__info mt-2 d-flex justify-content-between align-items-start">
                <span>{{ searchResult.translations | join(', ') }}</span>
                <router-link v-if="showDetail"
                             class="btn btn-sm search-result__info__button flex-shrink-0 d-flex align-items-center"
                             :to="{ name: 'glossdetail', params: { id: searchResult.glossName }, query:{sid:searchResult.signId}}">
                    <font-awesome-icon class="mr-2"
                                       :icon="['fal', 'book']" />
                    <span>{{ $t('search.show_detail') }}</span>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { ISignOverview } from '../../../../functions/src/shared/contracts/api/GlossContract';
import { signService } from '../../shared/services/SignService';
import { getLastURLSegment } from '../../util/util-functions';
import RegionIcon from '../general/RegionIcon.vue';
import MissingSignVideo from '../video/MissingSignVideo.vue';
import VideoPlayer from '../video/VideoPlayer.vue';
import { namespace } from 'vuex-class';
import { ILabel } from '../../../../functions/src/shared/contracts/api/MasterdataContract';

const cacheModule = namespace('cache');
@Component({ components: { MissingSignVideo, RegionIcon, VideoPlayer } })
export default class SearchResultCard extends Vue {
    public videoDownloadName: string | null = null;

    @Prop() private searchResult!: ISignOverview;
    @Prop({ default: true }) private showDetail!: boolean;
    @Prop({ default: true }) private showRegion!: boolean;

    @cacheModule.Getter
    private labels!: ILabel[];

    public async created(): Promise<void> {
        this.videoDownloadName = await signService.getSummaryName(this.searchResult);
    }

    @Emit('error')
    public reportError(): ISignOverview {
        return this.searchResult;
    }

    public get videoUrl(): string {
        return getLastURLSegment(this.searchResult.video);
    }

    public get labelTranslations(): ILabel[] {
        return (
            (this.searchResult &&
                this.searchResult.labels &&
                this.labels &&
                this.labels.filter(l => this.searchResult.labels.includes(l.id))) ||
            []
        ).sort((a: ILabel, b: ILabel) => a.name.localeCompare(b.name));
    }
}
</script>

<style lang="scss">
.search-result__info {
    color: $accent-color;
    font-size: 1.125rem;
    font-weight: bold;
}

.search-result__region {
    color: $card-text-color;
}

.search-result__region__icon {
    height: 1.5rem;
    width: 2.5rem;
}

.btn.search-result__info__button {
    background-color: $accent-color;
    color: $accent-text-color;

    &:hover {
        color: $accent-text-color-hover;
        background-color: $accent-color-hover;
    }
}

.label-badge {
    background-color: $accent-color;
    color: $accent-text-color;
    font-weight: normal !important;
    margin-right: 3px;
}
</style>


