import { EndPoints } from '@/EndPoints';
import router from '@/router';
import { axiosHttp } from '@/util/AxiosHttp';
import { ISignDetail, ISignOverview } from '../../../../functions/src/shared/contracts/api/GlossContract';
import { doorbellService } from './DoorbellService';

class FeedbackService {
    public async giveGeneralFeedback(
        feedbackText?: string,
        feedbackVideo?: Blob,
        feedbackVideoName?: string,
        email?: string,
        rating?: number,
    ): Promise<void> {
        const ratingString = rating ? rating : '/';
        let properties: { [key: string]: Object } = {
            rating: ratingString,
        };
        if (feedbackVideo) {
            const videoLink = await this.uploadFeedbackVideo(feedbackVideo, feedbackVideoName);
            properties = { ...properties, video_link: videoLink };
        }

        const message = `Rating: ${ratingString}
  
        Aanvulling:
        ${feedbackText || '/'}

        Video feedback: ${properties.video_link || '/'}`;
        await doorbellService.submitFeedback(message, email, properties);
    }

    public async giveFeedbackOnSign(
        sign: ISignDetail | ISignOverview,
        feedbackText?: string,
        feedbackVideo?: Blob,
        feedbackVideoName?: string,
        selectedProblem?: string,
        email?: string,
    ): Promise<void> {
        let properties: { [key: string]: Object } = {
            sign_gloss_name: sign.glossName,
            sign_region: sign.regions.join(', '),
            sign_signbankId: sign.signId,
        };
        if (selectedProblem) {
            properties = {
                ...properties,
                selected_problem: selectedProblem,
            };
        }
        if (feedbackVideo) {
            const videoLink = `${await this.uploadFeedbackVideo(feedbackVideo, feedbackVideoName)}?glossId=${sign.glossName}&signId=${
                sign.signId
                }`;
            properties = {
                ...properties,
                video_link: videoLink,
            };
        }
        const message = `${this.getSignDetailSummary(sign)}
        
        Geselecteerd probleem:
        ${selectedProblem || '/'}
                
        Aanvulling:
        ${feedbackText || '/'}

        Video feedback: ${properties.video_link || '/'}`;

        await doorbellService.submitFeedback(message, email, properties);
    }

    public async getFeedbackVideo(videoName: string): Promise<Blob> {
        const httpResponse = await axiosHttp.http.get(`${EndPoints.apiFeedbackVideos}/${videoName}`, {
            responseType: 'blob',
        });
        return new Blob([httpResponse.data as string]);
    }

    public async deleteFeedbackVideo(videoName: string): Promise<void> {
        await axiosHttp.http.delete(`${EndPoints.apiFeedbackVideos}/${videoName}`);
    }

    private async uploadFeedbackVideo(feedbackVideo: Blob, feedbackVideoName?: string): Promise<string> {
        const formData = new FormData();
        formData.append('video', feedbackVideo, feedbackVideoName || 'videoFeedback.webm');
        const response = await axiosHttp.http.post(EndPoints.apiFeedbackVideos, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        const videoName = response.data;
        return `${window.location.origin}${
            router.resolve({
                name: 'feedbackvideo',
                params: {
                    name: videoName,
                },
            }).href
            }`;
    }

    private getSignDetailSummary(signDetail: ISignDetail | ISignOverview): string {
        return `Gloss: ${signDetail.glossName}
        Variant (Regio:) ${signDetail.regions.join(', ')}
        signbankId: ${signDetail.signId}
        woordenboek-link: ${window.location.origin}${
            router.resolve({
                name: 'glossdetail',
                params: {
                    id: signDetail.glossName,
                },
                query: {
                    sid: `${signDetail.signId}`,
                },
            }).href
            }`;
    }
}

export const feedbackService = new FeedbackService();
