<template>
  <component class="vgtc-svg-icon"
             :class="{'vgtc-svg-icon--fill':fill}"
             v-bind:is="svg" />
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({ components: {} })
export default class SvgIcon extends Vue {
    @Prop()
    public icon!: string;

    @Prop({ default: false })
    public fill!: boolean;

    public loaded = false;
    public component: any = null;

    public get svg() {
        return () => import(`@/assets/svg/${this.icon}.svg`);
    }
}
</script>
<style lang="scss">
.vgtc-svg-icon {
    height: 1rem;
    font-size: inherit;
    &--fill {
        fill: currentColor;
        path {
            fill: currentColor;
        }
    }
}
</style>

