<template>
  <div class="d-flex justify-content-center flex-column">
    <b-form v-if="!showPasswordReset"
            class="login__form mx-auto mt-5"
            @submit.prevent="onSubmit">
      <b-form-group :label="$t('general.email')">
        <b-form-input type="email"
                      v-model="email" />
      </b-form-group>
      <b-form-group :label="$t('general.password')">
        <b-form-input type="password"
                      v-model="password" />
      </b-form-group>
      <b-button class="mt-4 vgtc-button justify-content-center"
                variant="primary"
                block
                type="submit">
        {{$t('general.login')}}
      </b-button>
      <span class="mt-4 d-flex justify-content-center login__form__forgot-password"
            @click="showResetPasswordForm()">
        {{$t('general.forgot_password')}}
      </span>
      <b-alert class="d-flex justify-content-center mt-5"
               variant="danger"
               :show="error">
        {{error}}
      </b-alert>
    </b-form>
    <b-form v-else
            class="login__form mx-auto mt-5"
            @submit.prevent="resetPassword()">
      <b-form-group :label="$t('general.email')">
        <b-form-input type="email"
                      v-model="email" />
      </b-form-group>
      <b-button class="mt-4 vgtc-button justify-content-center"
                variant="primary"
                block
                type="submit">
        {{$t('general.request_password_reset')}}
      </b-button>
      <b-alert class="d-flex justify-content-center mt-5"
               variant="success"
               :show="successCountDown"
               @dismiss-count-down="successCountDownChanged">
        {{successMessage}}
      </b-alert>
      <b-alert class="d-flex justify-content-center mt-5"
               variant="danger"
               :show="error">
        {{error}}
      </b-alert>
    </b-form>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { authService } from '../shared/services/AuthService';

const userModule = namespace('user');

@Component({})
export default class Login extends Vue {
    public email: string | null = null;
    public password: string | null = null;
    public error: string | null = null;

    public showPasswordReset: boolean = false;
    public successCountDown = 0;
    public successMessage: string | null = null;

    @userModule.Action
    private signIn!: (payload: { email: string; password: string }) => Promise<void>;

    public async onSubmit(): Promise<void> {
        if (!this.email || !this.password) {
            return;
        }
        this.error = null;
        try {
            await this.signIn({ email: this.email, password: this.password });
            this.$router.push((this.$router.currentRoute.query.redirect as string) || 'admin');
        } catch (e:any) {
            if (e && e.code) {
                switch (e.code) {
                    case 'auth/user-disabled':
                        this.error = this.$t('general.account_blocked').toString();
                        return;
                    case 'auth/wrong-password':
                    case 'auth/user-not-found':
                        this.error = this.$t('general.wrong_credentials').toString();
                        return;
                }
            }
            this.error = this.$t('general.error').toString();
        }
    }

    public showResetPasswordForm(): void {
        this.showPasswordReset = true;
    }

    public async resetPassword(): Promise<void> {
        if (!this.email) {
            return;
        }
        this.error = null;
        try {
            await authService.requestResetPassword(this.email);
        } catch (e:any) {
            if (e.code && e.code === 'auth/user-not-found') {
                this.error = this.$t('general.unkown_email').toString();
            } else {
                this.error = this.$t('general.try_again_later').toString();
            }
            return;
        }
        this.successMessage = this.$t('general.reset_mail_sent', { email: this.email }).toString();
        this.successCountDown = 5;
        this.email = null;
    }

    public successCountDownChanged(cd: number): void {
        this.successCountDown = cd;
        if (cd === 0) {
            this.email = null;
            this.password = null;
            this.showPasswordReset = false;
        }
    }
}
</script>

<style lang="scss">
.login__form {
    width: 22rem;
}

.login__form__forgot-password {
    color: $accent-color;
    cursor: pointer;

    &:hover {
        color: $accent-color-hover;
    }
}
</style>
