<template>
    <div class="footer pt-5">
        <div class="container d-flex justify-content-around align-items-baseline">
            <div class="d-flex flex-grow-1 justify-content-between h-100">
                <div class="d-flex flex-column">
                    <VGTCLogo class="mt-n2"></VGTCLogo>
                </div>
                <div class="d-flex flex-column ml-md-3">
                    <div class="footer__text--links d-flex flex-row mb-2">
                        <font-awesome-icon :icon="['fas','envelope']"></font-awesome-icon>
                        <a class="pl-2"
                           href="mailto:info@vgtc.be">info@vgtc.be</a>
                    </div>
                    <div class="footer__text--links text-underline d-flex flex-row pt-2">
                        <font-awesome-icon :icon="['far','globe']"></font-awesome-icon>
                        <div class="d-flex flex-column pl-2">
                            <a href="https://www.vgtc.be"
                               target="_blank">www.vgtc.be</a>
                            <a class="pt-2"
                               href="https://www.vgtc.be/contact"
                               target="_blank">www.vgtc.be/contact</a>
                        </div>
                    </div>
                </div>
                <div class="footer__social-media d-flex flex-column h-100 justify-content-between">
                    <div class="d-flex justify-content-end">
                        <a href="https://www.facebook.com/VGTCentrum/"
                           target="_blank">
                            <font-awesome-icon class="fa-2x mx-3"
                                               :icon="['fab','facebook-square']"></font-awesome-icon>
                        </a>
                        <a href="https://twitter.com/VGTCentrum"
                           target="_blank">
                            <font-awesome-icon class="fa-2x mx-3"
                                               :icon="['fab','twitter-square']"></font-awesome-icon>
                        </a>
                        <a href="https://www.linkedin.com/company/vlaams-gebarentaalcentrum?trk=top_nav_home"
                           target="_blank">
                            <font-awesome-icon class="fa-2x mx-3"
                                               :icon="['fab','linkedin']"></font-awesome-icon>
                        </a>
                        <a href="https://www.instagram.com/vgtcentrum/"
                           target="_blank">
                            <font-awesome-icon class="fa-2x ml-3"
                                               :icon="['fab','instagram']"></font-awesome-icon>
                        </a>
                    </div>
                    <div class="d-flex align-self-end mb-3">
                        <a href="https://www.ae.be"
                           target="_blank"
                           class="d-flex align-items-center footer__social-media__ae">
                            <SvgIcon icon="aeLogo" class="mt-n1 mr-n1"></SvgIcon>
                            <div class="footer__social-media__ae__year ml-1">2024.</div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import SvgIcon from '../SvgIcon.vue';
import VGTCLogo from './VGTCLogo.vue';

@Component({ components: { SvgIcon, VGTCLogo } })
export default class Footer extends Vue {
}
</script>

<style lang="scss">
.footer {
    height: $footer-height;
    background-color: $footer-background-color;
    color: $footer-text-color;
    bottom: 0;
    left: 0;
    right: 0;
}

.footer__text {
    &--address {
        font-size: $font-size-small;
        font-style: italic;
        letter-spacing: 0.03rem;
    }

    &--links {
        a {
            font-size: $font-size-small;
            color: $footer-text-color;
        }
    }
}

.footer__social-media {
    svg {
        color: $footer-text-color;
        fill: $footer-text-color;
    }
}

.footer__social-media__ae {
    text-decoration: none;

    svg {
        height: 1.8rem;
    }

    &:link {
        text-decoration: none;
    }

    &:hover {
        text-decoration: none;
    }

    &:active {
        text-decoration: none;
    }

    &:visited {
        text-decoration: none;
    }
}

.footer__social-media__ae__year {
    font-size: $font-size-small;
    color: $footer-text-color;
}

.contact-info__link__icon {
    width: 1.5rem !important;
    height: 1.5rem !important;
    fill: $footer-background-color;
    color: $footer-background-color;

    &--ae {
        width: 2.2rem !important;
        height: auto !important;
    }
}
</style>
