<template>
    <div class="empty-state pt-0 row align-items-stretch">
        <div class="col-md-6">
            <div class="px-3 py-3">
                <div class="empty-state__sign-ot-week__header">
                    <font-awesome-icon class="empty-state__sign-ot-week__header__icon mr-2"
                                       :icon="['fas', 'sign-language']" />
                    <span class="empty-state__sign-ot-week__header__text">{{ $t('empty_state.sign_ot_week') }}</span>
                </div>
                <div class="divider divider--light my-1"></div>
                <div v-if="loading"
                     class="d-flex justify-content-center py-4">
                    <b-spinner />
                </div>
                <SearchResultCard v-if="signOfTheWeek"
                                  class="mt-4 mx-xl-5 px-lg-5"
                                  :searchResult="signOfTheWeek" />
            </div>
        </div>
        <div
            class="col-md-6 empty-state__call-to-action d-flex flex-column justify-content-center align-items-center py-5">
              <span class="empty-state__call-to-action__text px-5 text-medium text-bold">
          {{ $t('empty_state.signbuddy.help') }}
              </span>
            <SignBuddy class="icon-sign-buddy mt-4"></SignBuddy>
            <Button class="mt-4 help-btn" type="button" label="empty_state.signbuddy.action" :icon="['fas', 'thumbs-up']"
                    @click="openSignbuddyInNewTab"
            ></Button>
        </div>
    </div>
</template>
<script lang="ts">
import EmptyStateImage from '@/assets/svg/emptystate.svg';
import { Component, Vue } from 'vue-property-decorator';
import { ISignOverview } from '../../../../functions/src/shared/contracts/api/GlossContract';
import { signService } from '../../shared/services/SignService';
import SearchResultCard from '../search/SearchResultCard.vue';
import SignBuddy from '@/assets/svg/SignBuddy.svg';
import Button from '@/components/general/Button.vue';

@Component({ components: { Button, SignBuddy, EmptyStateImage, SearchResultCard } })
export default class EmptyState extends Vue {
    public signOfTheWeek: ISignOverview | null = null;

    public loading = false;

    public async created(): Promise<void> {
        this.loading = true;
        this.signOfTheWeek = await signService.getSignOfTheWeek();
        this.loading = false;
    }

    public openSignbuddyInNewTab() {
        const route = this.$router.resolve({ name: 'signfeedback' });
        window.open(route.href, '_blank');

    }
}
</script>

<style lang="scss" scoped>
.empty-state {
    border: $thin-border-size solid $brand-color;
    border-radius: $default-border-radius;
    color: $background-text-color;
}

.empty-state__sign-ot-week__header {
    color: $brand-color;
    font-size: 1.5rem;
}

.empty-state__call-to-action {
    background-color: $header-color;
    color: $header-text-color;
}

.empty-state__call-to-action__text {
    font-family: $font-family-accent;
    text-align: center;
    letter-spacing: 0.05rem;
}

.icon-sign-buddy {
    height: 150px;
    filter: drop-shadow(4px 4px 1px #354e8a);
}
.help-btn{
    filter: drop-shadow(4px 4px 1px #354e8a);}
</style>
