<template>
  <div>
    <div class="ml-2 d-flex align-items-center search-header__label">
      <font-awesome-icon class="mr-2 mb-2"
                         :icon="['fas', 'search']" />
      <div class="ml-2 mb-2">{{ $t('search.search_on') }}</div>
    </div>
    <SearchOptionCardContainer v-scroll-to="'#search-popover'"
                               :searchOptions="searchOptions"
                               @activeOptionCardChanged="changeActiveOption"
                               class="d-none d-md-flex" />
    <SearchOptionPopoverContainer v-if="selectedSearchOption"
                                  id="search-popover"
                                  @closeMe="closePopovers()"
                                  :activeSearchOption="selectedSearchOption"
                                  class="pb-5" />
  </div>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import SearchOptionCardContainer from './SearchOptionCardContainer.vue';
import SearchOptionPopoverContainer from './SearchOptionPopoverContainer.vue';
import { searchOptions } from './searchOptions/options';
import { ISearchOption } from './searchOptions/SearchOptionModel';
const searchModule = namespace('search');

@Component({ components: { SearchOptionCardContainer, SearchOptionPopoverContainer } })
export default class SearchOptionContainer extends Vue {
    public searchOptions: ISearchOption[] = searchOptions;

    @searchModule.Getter
    public selectedSearchOption!: ISearchOption;

    @searchModule.Action('selectSearchOption')
    private selectSearchOption!: (searchOption: ISearchOption) => Promise<void>;

    @searchModule.Action('clearSearchOptionSelection')
    private clearSearchOptionSelection!: () => Promise<void>;

    public changeActiveOption(newActiveOption: ISearchOption) {
        this.selectSearchOption(newActiveOption);
    }

    public closePopovers(): void {
        this.clearSearchOptionSelection();
    }
}
</script>