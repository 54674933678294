import Vuex, { ActionTree, MutationTree, StoreOptions } from 'vuex';

import { cache } from './Cache';
import { search } from './SearchStore';

import Vue from 'vue';
import { ICacheState, IDisplayState, IRootState, ISearchState, IUserState } from './contract';
import { display } from './DisplayStore';
import { user } from './UserStore';

Vue.use(Vuex);

const rootState: IRootState = {};

const mutations: MutationTree<IRootState> = {};

const actions: ActionTree<IRootState, IRootState> = {};

const storeOptions: StoreOptions<IRootState> = {
    actions,
    modules: {
        cache,
        display,
        search,
        user,
    },
    mutations,
    state: rootState,
};

class VGTCStore extends Vuex.Store<IRootState> {
    public declare state: IVGTCState;

    constructor(store: StoreOptions<IRootState>) {
        super(store);
    }
}

interface IVGTCState extends IRootState {
    cache: ICacheState;
    display: IDisplayState;
    search: ISearchState;
    user: IUserState;
}

export default new VGTCStore(storeOptions);
