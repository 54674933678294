<template>
  <div class="d-flex align-items-center flex-wrap mb-n3 flex-grow-1">
    <div class=" d-inline-flex search-filter-tag-list__title align-self-start mb-3 mb-md-0 mr-3">
      <slot></slot>
    </div>
    <div class="d-flex align-items-center search-filter-tag-list__item mb-2"
         v-for="(tag, i) in tags"
         :key="i">
      <SearchFilterTag :tag="tag"
                       v-on:delete="removeTag" />
    </div>
    <button class="search-filter-tag-list__remove-all d-flex align-items-center ml-auto link"
            @click="newSearch">
      {{$t('search.removeAll')}}
      <font-awesome-icon class="search-filter-tag-list__remove-all__icon ml-2"
                         :icon="['fal', 'trash-alt']" />
    </button>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import AmountIndicator from '../AmountIndicator.vue';
import SearchFilterTag from './SearchFilterTag.vue';
import { ITag } from './TagModel';

const searchModule = namespace('search');

@Component({ components: { AmountIndicator, SearchFilterTag } })
export default class SearchFilterTagList extends Vue {
    @Prop()
    public tags!: ITag[];

    @searchModule.Action('removeTag')
    public removeTag!: (tag: ITag) => Promise<void>;

    @searchModule.Action('clear')
    public newSearch!: () => Promise<void>;
}
</script>

<style lang="scss">
.search-filter-tag-list__item {
    &:not(:first-child):not(:nth-child(2))::before {
        content: '+';
        font-size: $font-size-medium;
        margin: 0 0.5rem;
    }
}
.search-filter-tag-list__title {
    width: 100%;
    @include media-breakpoint-up(md) {
        width: unset;
    }
}

.search-filter-tag-list__remove-all {
    font-size: 0.875rem;
    border: none;
    background-color: transparent;
}

.search-filter-tag-list__remove-all__icon {
    font-size: $font-size-big;
}
</style>