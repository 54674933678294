<template>
    <div v-if="loading" class="text-center pt-4">
        <div v-if="loadingState ===0">
            <div class="d-flex align-items-center justify-content-center mb-5">
                <font-awesome-icon class="mr-3" size="2x"
                                   :icon="['far', 'paper-plane']" />
                <div>{{ $t('sign_feedback.step3.explanation_send') }}</div>
            </div>
            <b-spinner class="color-brand"></b-spinner>
            <div class="color-brand">{{ $t('sign_feedback.step3.send') }}</div>
        </div>
        <div v-else-if="loadingState === 1">
            <div class="d-flex align-items-center justify-content-center mb-5">
                <font-awesome-icon class="mr-3" size="2x"
                                   :icon="['fas', 'film']" />
                <div>{{ $t('sign_feedback.step3.explanation_process') }}</div>
            </div>
            <b-spinner class="color-brand"></b-spinner>
            <div class="color-brand">{{ $t('sign_feedback.step3.process') }}</div>
        </div>
        <div v-else>
            <div class="d-flex align-items-center justify-content-center mb-5">
                <SignBuddy class="icon-sign-buddy mr-3"></SignBuddy>
                <div>{{ $t('sign_feedback.step3.explanation_analyse') }}</div>
            </div>
            <b-spinner class="color-brand"></b-spinner>
            <div class="color-brand">{{ $t('sign_feedback.step3.analyse') }}</div>
        </div>
    </div>
    <div v-else class="pt-4 d-flex flex-column align-items-center">
        <div>{{ $t('sign_feedback.error.send') }}</div>
        <div class="mt-4">
            <Button type="button"
                    label="sign_feedback.error.send_again"
                    :icon="['far', 'share']"
                    @click="sendRecording"></Button>
        </div>
    </div>
</template>
<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import VideoPlayer from '@/components/video/VideoPlayer.vue';
import MissingSignVideo from '@/components/video/MissingSignVideo.vue';
import SearchResultCard from '@/components/search/SearchResultCard.vue';
import Button from '@/components/general/Button.vue';
import SignBuddy from '@/assets/svg/SignBuddy.svg';

@Component({ components: { SearchResultCard, MissingSignVideo, VideoPlayer, Button, SignBuddy } })
export default class ProcessSignLoading extends Vue {

    @Prop() loading!: boolean;

    public loadingState = 0;

    created() {
        setTimeout((() => this.loadingState++), 3000);
        setTimeout((() => this.loadingState++), 6000);
    }

    @Emit('nextStep')
    public nextStep() {
        return;
    }

    @Emit('sendRecording')
    public sendRecording() {
        this.loadingState = 0;
        return;
    }
}
</script>

<style lang="scss" scoped>
.icon-sign-buddy {
    height: 40px;
}
</style>

