<template>
  <div>
    <router-link class="d-flex gloss-detail__header__back align-items-center"
                 :to="{name: 'search', query: searchParams}">
      <font-awesome-icon class="mr-2 ml"
                         :icon="[iconStyle || 'fal', 'arrow-left']" />
      <span class="d-none d-md-block">{{$t('gloss_detail.back')}}</span>
      <font-awesome-icon class="ml-1"
                         :icon="[iconStyle || 'fal', 'search']" />
    </router-link>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Dictionary } from 'vue-router/types/router';
import { namespace } from 'vuex-class';
import { tagService } from '../../shared/services/TagService';
import { ITag } from '../search/TagModel';

const searchModule = namespace('search');

@Component({})
export default class BackToSearch extends Vue {
    @Prop()
    public iconStyle!: string;

    @searchModule.Getter
    public searchTags!: ITag[];

    public get searchParams(): Dictionary<string | string[]> {
        const searchParams = tagService.mapTagsToQueryParams(this.searchTags);
        return searchParams;
    }
}
</script>

<style lang="scss">
</style>