<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

interface IAppEventMap {
    newSearchTriggered: string;
    searchBarFocussed: string;
    videoStarted: string;
}

@Component
export default class EventHub extends Vue {
    public emitAppEvent<K extends keyof IAppEventMap>(event: K, ...args: any[]): this {
        this.$emit.apply(this, [event, ...args]);
        return this;
    }

    public onAppEvent<K extends keyof IAppEventMap>(event: K | K[], callback: Function): this {
        this.$on(event, callback);
        return this;
    }

    public unsubscribe<K extends keyof IAppEventMap>(event: K | K[], callback: Function): this {
        this.$off(event, callback);
        return this;
    }
}
export const eventHub = new EventHub();
</script>
