<template>
  <svg :height="size"
       :width="size"
       :viewBox="`0 0 ${size} ${size}`">
    <circle :cx="size/2"
            :cy="size/2"
            class="count-down-button__background"
            :r="radius">
    </circle>
    <circle :cx="size/2"
            :cy="size/2"
            :r="radius"
            class="count-down-button__count-down"
            :stroke-dasharray="fillStrokeDashArray"
            fill="none"
            :stroke-width="strokeWidth"
            stroke="white"></circle>
    <rect :x="size/3"
          :y="size/3"
          rx="2"
          ry="2"
          :class="{'d-none':!running}"
          :width="size/3"
          :height="size/3"
          fill="white"></rect>
    <text x="50%"
          y="52%"
          :class="{'d-none':running}"
          class="count-down-button__label"
          alignment-baseline="middle"
          text-anchor="middle">REC</text>
  </svg>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import Looper from '../../shared/Looper';

@Component({})
export default class CountDownRecordButton extends Vue {
    @Prop({ default: 6 }) public strokeWidth!: number;
    @Prop({ default: 1000 }) public tickSize!: number;
    @Prop({ default: 5 * 60 * 1000 }) public countDownMilliseconds!: number;
    @Prop({ default: false }) public paused!: boolean;
    public elapsedMilliseconds: number = 0;
    private size: number = 100;
    private looper: Looper | null = null;

    public get radius(): number {
        return (this.size * 0.9) / 2;
    }

    public created() {
        this.looper = new Looper(this.tickSize, this.tick, () => this.countDownGreaterThanElapsed(this), this.onTimeUp);
        if (this.running) {
            this.looper.start();
        }
    }

    public get fillStrokeDashArray(): string {
        const max: number = 2 * Math.PI * this.radius;
        return `${max * this.fillPercentage}, ${max}`;
    }

    public get fillPercentage(): number {
        return 1 - this.elapsedMilliseconds / (this.countDownMilliseconds > 0 ? this.countDownMilliseconds : 1);
    }

    public get running(): boolean {
        return !this.paused;
    }

    @Watch('paused')
    private updateState(): void {
        if (!this.looper) {
            return;
        }
        if (this.paused) {
            this.looper.stop();
        } else {
            this.looper.start();
        }
    }

    @Emit('tick')
    private tick(): void {
        this.elapsedMilliseconds += this.tickSize;
        return;
    }

    private countDownGreaterThanElapsed(th: CountDownRecordButton): boolean {
        return th.countDownMilliseconds > th.elapsedMilliseconds;
    }

    @Emit('timerFinished')
    private onTimeUp(): void {
        this.elapsedMilliseconds = 0;
        return;
    }
}
</script>

<style lang="scss">
.count-down-button__label {
    color: white;
    fill: white;
    font-size: 1.5rem;
}

.count-down-button__count-down {
    stroke: $accent-text-color;
}

.count-down-button__background {
    fill: $record-button-color;
}

.count-down-button__pulse-background {
    fill: $record-button-stroke-color;
    stroke: fadeout($record-button-stroke-color, 50);
}
</style>