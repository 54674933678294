<template>
  <div class="row no-gutters mr-n4">
    <div v-for="(option,i) in searchOptions"
         :key="i"
         class="col-xl col-md-6 mt-1 mb-1">
      <SearchOptionCard class="mr-4 menu-item"
                        :nrSelected="getNrSelectedItems(option)"
                        :searchOption="option"
                        :isSelected="isSelectedOption(option)"
                        @onClick="cardClicked" />
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { SearchType } from '../SearchType';
import { ITag } from '../TagModel';
import SearchOptionCard from './SearchOptionCard.vue';
import { ISearchOption } from './searchOptions/SearchOptionModel';

const searchModule = namespace('search');

@Component({ components: { SearchOptionCard } })
export default class SearchOptionCardContainer extends Vue {
    @Prop()
    public searchOptions!: ISearchOption[];

    @searchModule.Getter
    private selectedSearchOption!: ISearchOption;

    @searchModule.Getter
    private searchTags!: ITag[];

    @Emit('activeOptionCardChanged')
    public cardClicked(clickedOptionCard: ISearchOption): void {
        return;
    }

    public isSelectedOption(option: ISearchOption): boolean {
        return this.selectedSearchOption && option.optionName === this.selectedSearchOption.optionName;
    }

    public getNrSelectedItems(option: ISearchOption): number {
        return this.searchTags.filter(t => t.searchType === option.searchType).length;
    }
}
</script>

<style lang="scss">
</style>

