import { Handshape } from '../../../../functions/src/shared/contracts/Handshape';
import { IIcon } from './../../util/gui-decorators';

class HandshapeService {
    // public async getAllHandshapes(): Promise<IHandshape[]> {
    //     const response = await axiosHttp.http.get(`${Constants.baseApiUrl}/handshapes`);
    //     return response.data as IHandshape[];
    // }

    public getIconFor(handshape: Handshape): IIcon | undefined {
        const foundHandshape = this.getHandshapesWithIcon().find(hs => hs.handshape === handshape);
        return foundHandshape && foundHandshape.displayIcon;
    }

    private getHandshapesWithIcon() {
        return [
            {
                displayIcon: {
                    customSvg: 'handshapes/06 1 en 1 accent',
                },
                handshape: Handshape.Shape_1_1_q,
            },
            {
                displayIcon: {
                    customSvg: 'handshapes/16 2bO en 2bO circonflex',
                },
                handshape: Handshape.Shape_2bO_2bO_c,
            },
            {
                displayIcon: {
                    customSvg: 'handshapes/18 3',
                },
                handshape: Handshape.Shape_3,
            },
            {
                displayIcon: {
                    customSvg: 'handshapes/03 5 en 5 accent',
                },
                handshape: Handshape.Shape_5_5_q,
            },
            {
                displayIcon: {
                    customSvg: 'handshapes/12 8',
                },
                handshape: Handshape.Shape_8,
            },
            {
                displayIcon: {
                    customSvg: 'handshapes/19 A en A accent',
                },
                handshape: Handshape.Shape_A_A_q,
            },
            {
                displayIcon: {
                    customSvg: 'handshapes/01 B en B accent',
                },
                handshape: Handshape.Shape_B_B_q,
            },
            {
                displayIcon: {
                    customSvg: 'handshapes/13 C8',
                },
                handshape: Handshape.Shape_C8,
            },
            {
                displayIcon: {
                    customSvg: 'handshapes/15 H en H accent',
                },
                handshape: Handshape.Shape_H_H_q,
            },
            {
                displayIcon: {
                    customSvg: 'handshapes/20 L',
                },
                handshape: Handshape.Shape_L,
            },
            {
                displayIcon: {
                    customSvg: 'handshapes/17 M',
                },
                handshape: Handshape.Shape_M,
            },
            {
                displayIcon: {
                    customSvg: 'handshapes/05 C en OB',
                },
                handshape: Handshape.Shape_C_OB,
            },
            {
                displayIcon: {
                    customSvg: 'handshapes/04 O en O circonflex',
                },
                handshape: Handshape.Shape_O_O_c,
            },
            {
                displayIcon: {
                    customSvg: 'handshapes/10 Q en Q plus',
                },
                handshape: Handshape.Shape_Q_Q_p,
            },
            {
                displayIcon: {
                    customSvg: 'handshapes/02 S',
                },
                handshape: Handshape.Shape_S,
            },
            {
                displayIcon: {
                    customSvg: 'handshapes/08 T en T plus',
                },
                handshape: Handshape.Shape_T_T_p,
            },
            {
                displayIcon: {
                    customSvg: 'handshapes/14 V en V accent',
                },
                handshape: Handshape.Shape_V_V_q,
            },
            {
                displayIcon: {
                    customSvg: 'handshapes/21 W en W accent',
                },
                handshape: Handshape.Shape_W_W_q,
            }, {
                displayIcon: {
                    customSvg: 'handshapes/21 W',
                },
                handshape: Handshape.Shape_W,
            },
            {
                displayIcon: {
                    customSvg: 'handshapes/22 bY en Y1',
                },
                handshape: Handshape.Shape_bY_Y1,
            },
            {
                displayIcon: {
                    customSvg: 'handshapes/11 Y',
                },
                handshape: Handshape.Shape_Y,
            },
            {
                displayIcon: {
                    customSvg: 'handshapes/09 bC en bC plus',
                },
                handshape: Handshape.Shape_bC_bC_p,
            },
            {
                displayIcon: {
                    customSvg: 'handshapes/07 bO en bO plus',
                },
                handshape: Handshape.Shape_bO_bO_p,
            },
        ];
    }
}

export const handshapeService = new HandshapeService();
