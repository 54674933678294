<template>
    <div>
        <div class="row pt-4">
            <div class="col-8 pr-3">
                <div class="box mb-3" ref="videoColumnRef">
                    <div v-if="loading"
                         class="d-flex justify-content-center py-4">
                        <b-spinner />
                    </div>
                    <video ref="videoRef" class="input_video" :width="videoColumnRef?.clientWidth || 0"></video>
                    <canvas
                        ref="canvasRef"
                        class="output_canvas"
                    ></canvas>
                    <div class="countdown-timer mt-n1 box" v-if="mediaPipeIsLoaded"
                         :style="{width:videoRef?.clientWidth+'px'}">
                        <div class="countdown-timer-bar" :class="{'animate':saveLandmarks}"
                             :style="{width:videoRef?.clientWidth+'px'}"></div>
                    </div>
                    <div v-if="countdown"
                         class="countdown h-100 d-flex justify-content-center align-items-center"
                         :style="{width:videoColumnRef?.clientWidth || 0 +'px'}">
                        <Countdown @done="countdownDone"></Countdown>
                    </div>
                    <div v-if="saveLandmarks" class="record-sign d-flex align-items-center">
                        <font-awesome-icon
                            class="text-danger mx-1"
                            size="xs"
                            :icon="['fas', 'circle']" />
                        {{ $t('sign_feedback.step2.recording') }}
                    </div>
                </div>
                <div class="d-flex align-items-center justify-content-between btn-box">
                    <Button v-if="!saveLandmarks && !countdown" type="cancel"
                            @cancel="cancel"></Button>
                    <Button v-if="!saveLandmarks && !firstRecordingDone"
                            type="button"
                            :disabled="!mediaPipeIsLoaded"
                            :loading="!mediaPipeIsLoaded"
                            label="sign_feedback.step2.start_recording"
                            :icon="['fas', 'circle']"
                            @click="startRecording"></Button>
                    <div v-if="firstRecordingDone && !saveLandmarks && landmarks.length" class="d-flex">
                        <Button type="button"
                                class="mr-3"
                                label="sign_feedback.step2.restart_recording"
                                :icon="['fas', 'repeat']"
                                @click="startRecording"></Button>
                        <Button type="button"
                                label="sign_feedback.step2.send_recording"
                                :icon="['far', 'share']"
                                @click="sendRecording"></Button>
                    </div>
                </div>
            </div>

            <div class="col-4 w-100">
                <SearchResultCard class="w-100 mt-n1"
                                  :search-result="sign"
                                  :show-region="false"
                                  :show-detail="false">
                </SearchResultCard>
                <div class="row mb-3 mt-4">
                    <span class="col-10 " v-html="$t('sign_feedback.step2.explanation_exercise')"></span>
                    <span class="col-2 text-center text-primary">
                        <font-awesome-icon
                            size="xl"
                            :icon="['far', 'repeat']" />
                    </span>
                </div>
                <div class="row mb-3">
                    <span class="col-10 " v-html="$t('sign_feedback.step2.explanation_light')"></span>
                    <span class="col-2 text-center text-primary">
                        <font-awesome-icon
                            size="xl"
                            :icon="['far', 'lightbulb']" />
                    </span>
                </div>
                <div class="row mb-3">
                    <span class="col-10 " v-html="$t('sign_feedback.step2.explanation_edge')"></span>
                    <span class="col-2 text-center text-primary">
                        <font-awesome-icon
                            size="xl"
                            :icon="['far', 'vector-square']" />
                    </span>
                </div>
                <div class="row">
                    <span class="col-10 " v-html="$t('sign_feedback.step2.explanation_slow')"></span>
                    <span class="col-2 text-center text-primary">
                        <font-awesome-icon
                            size="xl"
                            :icon="['far', 'turtle']" />
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import MissingSignVideo from '@/components/video/MissingSignVideo.vue';
import SearchResultCard from '@/components/search/SearchResultCard.vue';
import * as Camera from '@mediapipe/camera_utils';
import * as drawing_utils from '@mediapipe/drawing_utils';
import * as Holistic from '@mediapipe/holistic';
import Button from '@/components/general/Button.vue';
import Countdown from '@/components/signFeedback/Countdown.vue';
import { ISignOverview } from '../../../../functions/src/shared/contracts/api/GlossContract';

@Component({ components: { Countdown, Button, SearchResultCard, MissingSignVideo } })
export default class RecordSign extends Vue {

    @Prop() sign: ISignOverview;
    @Prop() holistic: any;

    @Emit('nextStep')
    public agreeAndNext() {
        return;
    }

    @Emit('cancel')
    public cancel() {
        return;
    }

    public videoColumnRef = null;
    public canvasRef = null;
    public videoRef = null;
    public loading = true;
    public mediaPipeIsLoaded = false;
    public recordingTimeMS = 4000;
    public camera = null;

    // camera is recording/working/visible on screen, but landmarks are not saved yet, only when pushing the start button.
    public saveLandmarks = false;
    public firstRecordingDone = false;
    public countdown = false;
    public landmarks = [];

    public mounted() {
        this.videoColumnRef = this.$refs['videoColumnRef'];
        this.canvasRef = this.$refs['canvasRef'];
        this.videoRef = this.$refs['videoRef'];
        this.canvasRef.width = this.videoColumnRef.clientWidth;
        this.canvasRef.height = this.videoRef.videoHeight;

        this.holistic.onResults(this.onResults);

        this.camera = new Camera.Camera(this.videoRef, {
            onFrame: async () => {
                if (this.holistic && !this.mediaPipeIsLoaded || this.saveLandmarks) {
                    await this.holistic.send({ image: this.videoRef });
                }
            },
        });
        this.camera.start()
            .then(() => {
                this.loading = false;
            })
            .catch((err) => console.log('Failed to start camera with error: ', err));
    }

    public beforeDestroy() {
        if (this.holistic) {
            this.holistic.reset();
        }
        this.camera.stop();
        this.camera = null;
    }

    public startRecording() {
        this.landmarks = [];
        this.countdown = true;
        this.firstRecordingDone = true;
    }

    public sendRecording() {
        this.$emit('nextStep', this.landmarks);
    }

    public countdownDone() {
        this.countdown = false;
        this.saveLandmarks = true;
        setTimeout(() => this.stopRecording(), this.recordingTimeMS);
    }

    public stopRecording() {
        this.saveLandmarks = false;
        const canvasCtx = this.canvasRef.getContext('2d')!;
        canvasCtx.save();
        canvasCtx.clearRect(
            0,
            0,
            this.canvasRef.width,
            this.canvasRef.height,
        );
    }

    private getLandMarks(frameLandmarks, landmarks, amountIfEmpty) {
        if (landmarks) {
            for (let i = 0; i < landmarks.length; i++) {
                frameLandmarks.push(landmarks[i].x);
                frameLandmarks.push(landmarks[i].y);
                frameLandmarks.push(landmarks[i].z);
            }
        } else {
            for (let i = 0; i < amountIfEmpty; i++) {
                frameLandmarks.push(NaN);
                frameLandmarks.push(NaN);
                frameLandmarks.push(NaN);
            }
        }
    }

    public onResults(results: Holistic.Results) {
        this.mediaPipeIsLoaded = true;
        if (this.saveLandmarks) {
            const frameLandmarks = [];

            this.getLandMarks(frameLandmarks, results.poseLandmarks, 33);
            this.getLandMarks(frameLandmarks, results.leftHandLandmarks, 21);
            this.getLandMarks(frameLandmarks, results.rightHandLandmarks, 21);
            this.getLandMarks(frameLandmarks, results.faceLandmarks, 468);

            this.landmarks.push(frameLandmarks);

            this.drawSkeleton(results);
        }
    };

    private drawSkeleton(results: Holistic.Results) {
        this.canvasRef.width = this.videoRef.clientWidth;
        this.canvasRef.height = this.videoRef.clientHeight;
        const canvasCtx = this.canvasRef.getContext('2d')!;
        canvasCtx.save();
        canvasCtx.clearRect(
            0,
            0,
            this.canvasRef.width,
            this.canvasRef.height,
        );


// Connect elbows to hands. Do this first so that the other graphics will draw
// on top of these marks.
        canvasCtx.lineWidth = 1;
        if (results.poseLandmarks) {
            if (results.rightHandLandmarks) {
                canvasCtx.strokeStyle = 'rgba(255,255,255,0.5)';
                this.connect(canvasCtx, [[
                    results.poseLandmarks[Holistic.POSE_LANDMARKS.RIGHT_ELBOW],
                    results.rightHandLandmarks[0],
                ]]);
            }
            if (results.leftHandLandmarks) {
                canvasCtx.strokeStyle = 'rgba(255,255,255,0.5)';
                this.connect(canvasCtx, [[
                    results.poseLandmarks[Holistic.POSE_LANDMARKS.LEFT_ELBOW],
                    results.leftHandLandmarks[0],
                ]]);
            }
        }

// POSE
        drawing_utils.drawConnectors(
            canvasCtx, results.poseLandmarks, Holistic.POSE_CONNECTIONS,
            { color: 'rgba(255,255,255,0.5)', lineWidth: 1 });
        drawing_utils.drawLandmarks(
            canvasCtx,
            Object.values(Holistic.POSE_LANDMARKS_LEFT)
                .map(index => results.poseLandmarks[index]),
            { visibilityMin: 0.65, color: 'rgba(255,255,255,0.5)', lineWidth: 1, fillColor: 'rgba(255,138,0,0.5)' });
        drawing_utils.drawLandmarks(
            canvasCtx,
            Object.values(Holistic.POSE_LANDMARKS_RIGHT)
                .map(index => results.poseLandmarks[index]),
            { visibilityMin: 0.65, color: 'rgba(255,255,255,0.5)', lineWidth: 1, fillColor: 'rgba(0,217,231,0.5)' });

// HANDS
        drawing_utils.drawConnectors(
            canvasCtx, results.rightHandLandmarks, Holistic.HAND_CONNECTIONS,
            { color: 'rgba(255,255,255,0.5)', lineWidth: 2 });
        drawing_utils.drawLandmarks(canvasCtx, results.rightHandLandmarks, {
            color: 'rgba(255,255,255,0.5)',
            fillColor: 'rgba(0,217,231,0.5)',
            lineWidth: 1,
            radius: (data) => {
                return drawing_utils.lerp(data.from!.z!, -0.15, .1, 10, 1);
            },
        });
        drawing_utils.drawConnectors(
            canvasCtx, results.leftHandLandmarks, Holistic.HAND_CONNECTIONS,
            { color: 'rgba(255,255,255,0.5)', lineWidth: 1 });
        drawing_utils.drawLandmarks(canvasCtx, results.leftHandLandmarks, {
            color: 'rgba(255,255,255,0.5)',
            fillColor: 'rgba(255,138,0,0.5)',
            lineWidth: 1,
            radius: (data) => {
                return drawing_utils.lerp(data.from!.z!, -0.15, .1, 10, 1);
            },
        });

        canvasCtx.restore();
    }

    private connect(
        ctx: CanvasRenderingContext2D,
        connectors:
            Array<[Holistic.NormalizedLandmark, Holistic.NormalizedLandmark]>):
        void {
        const canvas = ctx.canvas;
        for (const connector of connectors) {
            const from = connector[0];
            const to = connector[1];
            if (from && to) {
                if (from.visibility && to.visibility &&
                    (from.visibility < 0.1 || to.visibility < 0.1)) {
                    continue;
                }
                ctx.beginPath();
                ctx.moveTo(from.x * canvas.width, from.y * canvas.height);
                ctx.lineTo(to.x * canvas.width, to.y * canvas.height);
                ctx.stroke();
            }
        }
    }
}
</script>

<style lang="scss">
.box {
    position: relative;
}

.input_video,
.output_canvas {
    transform: rotateY(180deg);
}

.output_canvas {
    position: absolute;
    top: 0;
    left: 0;
}

.search-mock {
    position: absolute;
    top: 100px;
    left: 0;
    font-weight: 900;
    color: white;
}

.record-sign {
    position: absolute;
    top: 10px;
    right: 20px;
    border: 1px solid black;
    background-color: black;
    opacity: 0.7;
    border-radius: 5px;
    padding: 3px 5px;
    color: white;
    font-size: 12px;
}

.countdown {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.countdown-timer, .countdown-timer-bar {
    height: 8px;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 0.15rem;
}

.countdown-timer {
    margin-bottom: 2rem;
}

.countdown-timer-bar {
    background-color: $accent-color;
    transform-origin: left;
}

@keyframes countdown {
    to {
        transform: scaleX(0);
    }
}

.animate {
    animation: countdown 4s linear forwards;
}

.btn-box {
    height: 40px;
}

</style>

