<template>
  <div class="d-flex">
    <button @click="emitTextActive"
            class="d-flex p-1 p-sm-2 p-md-3 align-items-center binary-context-switch__button binary-context-switch__button--first flex-grow-1"
            :class="{'binary-context-switch__button--selected':value.textInputActive}">
      <font-awesome-icon class="binary-context-switch__button__icon mx-auto mr-1 mr-md-3"
                         :icon="['far','text']" />
      <span class="binary-context-switch__button-label">
        <slot name="text"></slot>
      </span>
    </button>
    <button @click="emitVideoActive"
            class="d-flex p-1 p-sm-2 p-md-3 align-items-center binary-context-switch__button binary-context-switch__button--second flex-grow-1"
            :class="{'binary-context-switch__button--selected':value.videoInputActive}">
      <font-awesome-icon class="binary-context-switch__button__icon mr-1 mr-md-3"
                         :icon="['fas','video']" /><span class="binary-context-switch__button-label">
        <slot name="video"></slot>
      </span>
    </button>
  </div>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class VideoTextSwitch extends Vue {
    @Prop() public value!: { textInputActive: boolean; videoInputActive: boolean };

    public emitTextActive(): void {
        this.emit({ textInputActive: true, videoInputActive: false });
    }

    public emitVideoActive(): void {
        this.emit({ textInputActive: false, videoInputActive: true });
    }

    @Emit('input')
    private emit(value: { textInputActive: boolean; videoInputActive: boolean }) {
        return;
    }
}
</script>

<style lang="scss">
.binary-context-switch__button {
    padding: 0.5rem;
    border: solid 0.1rem $brand-color;
    color: $card-text-color;
    background-color: transparent;
    box-shadow: none !important;
    outline: none !important;
    max-width: 50%;
    &--first {
        border-top-left-radius: $default-border-radius !important;
        border-bottom-left-radius: 0 !important;
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }
    &--second {
        border-top-right-radius: $default-border-radius !important;
        border-bottom-right-radius: 0 !important;
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
    }

    &--selected {
        background-color: $brand-color;
        color: $default-text-color !important;
        fill: $default-text-color !important;
    }
}

.binary-context-switch__button__icon {
    padding: 0.5rem;
    border-radius: $default-border-radius;
    font-size: 3rem;
    @include media-breakpoint-up(md) {
        font-size: 4rem;
    }
}

.binary-context-switch__button-label {
    font-size: $font-size-small;
    @include media-breakpoint-up(md) {
        font-size: $font-size-default;
    }
}
</style>