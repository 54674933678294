<template>
    <div class="vgtc-card mt-md-5 p-4 d-flex flex-column">
        <div class="search-results__overview d-flex justify-content-between align-items-center pb-4 mb-4">
            <div class="d-flex flex-column text-nowrap flex-lg-row flex-grow-1">
                <div class="d-flex flex-grow-1">
                    <SearchFilterTagList :tags="searchTags">
                        <AmountIndicator :amount="newSearchOngoing ? undefined : nrOfSearchResults"
                                         class="mr-1 mr-md-2 align-self-baseline" />
                        <span class="search-results__overview__text align-self-baseline">
              {{ $tc('search.results', nrOfSearchResults) }}
            </span>
                    </SearchFilterTagList>
                </div>
            </div>
        </div>
        <b-spinner class="align-self-center"
                   v-if="newSearchOngoing" />
        <div v-else>
            <div v-if="nrOfSearchResults"
                 class="row align-items-baseline mt-n5">
                <SearchResultCard class="col-12 col-md-6 col-xl-4 mt-5"
                                  v-for="(result) in sortedSearchResults"
                                  :key="result.signId"
                                  :searchResult="result" />
            </div>
            <div v-else
                 class="search-results__no-results d-flex flex flex-column align-items-center">
                <div class="d-flex flex-column flex-sm-row align-items-center mb-2">
                    <NoResults class="search-results__no-results__img mr-3" />
                    <div class="d-flex flex-column">
                        <span class="search-results__no-results__title mt-3">{{ $t('search.no_results') }}</span>
                        <i18n class="search-results__no-results__title__subtext mt-2 mt-sm-0"
                              v-if="nrOfRelatedSearchResult"
                              path="search.related_results_found"
                              tag="span"
                              :places="{nrOfResults: nrOfRelatedSearchResult}">
              <span v-scroll-to="'#relatedResults'"
                    class="link"
                    place="below">{{ $t('search.related_results_found_below') }}</span>
                        </i18n>
                    </div>
                </div>
                <i18n class="search-results__no-results__help my-3"
                      :path="nrOfRelatedSearchResult ? 'search.no_results_help_alternative' : 'search.no_results_help'"
                      tag="span">
          <span class="link"
                @click="newSearch"
                place="new_search">{{ $t('search.new_search') }}</span>
                    <a class="link"
                       :href="faqUrl"
                       target="_blank"
                       place="faq">{{ $t('search.no_results_help_page') }}</a>
                </i18n>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import NoResults from '@/assets/svg/no-results.svg';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { ISignOverview } from '../../../../functions/src/shared/contracts/api/GlossContract';
import { Constants } from '../../shared/Constants';
import AmountIndicator from '../AmountIndicator.vue';
import { eventHub } from '../EventHub.vue';
import SearchFilterTagList from './SearchFilterTagList.vue';
import SearchResultCard from './SearchResultCard.vue';
import { ITag } from './TagModel';

const searchModule = namespace('search');

@Component({ components: { AmountIndicator, NoResults, SearchFilterTagList, SearchResultCard } })
export default class SearchResults extends Vue {
    @Prop()
    public nrOfSearchResults!: number;

    @Prop()
    public nrOfRelatedSearchResult!: number;

    @Prop()
    public searchResults!: ISignOverview[];

    public readonly faqUrl = Constants.faqUrl;

    @searchModule.Getter
    public searchTags!: ITag[];

    @searchModule.Getter
    public newSearchOngoing!: boolean;

    @searchModule.Action
    private clear!: () => Promise<void>;

    public newSearch(): void {
        eventHub.emitAppEvent('newSearchTriggered');
        this.clear();
    }

    public get sortedSearchResults() {
        // for every glossName sort the results so 'Vlaanderen' comes first
        let sortedResults = [...this.searchResults];

        let groupedResults = {};
        sortedResults.forEach(item => {
            if (!groupedResults[item.glossName]) {
                groupedResults[item.glossName] = [];
            }
            groupedResults[item.glossName].push(item);
        });

        Object.keys(groupedResults).forEach(glossName => {
            groupedResults[glossName].sort((a, b) => {
                const aHasVlaanderen = a.regions.includes('Vlaanderen');
                const bHasVlaanderen = b.regions.includes('Vlaanderen');

                if (aHasVlaanderen && !bHasVlaanderen) {
                    return -1;
                } else if (!aHasVlaanderen && bHasVlaanderen) {
                    return 1;
                } else {
                    return 0;
                }
            });
        });

        let finalSortedResults = [];
        sortedResults.forEach(item => {
            if (groupedResults[item.glossName].length > 0) {
                finalSortedResults.push(groupedResults[item.glossName].shift());
            }
        });

        return finalSortedResults;
    }
}
</script>

<style lang="scss">
.search-results__overview {
    border-bottom: $divider;
}

.search-results__overview__text {
    color: $text-title-color;
    font-size: $font-size-medium;
    letter-spacing: 0.075rem;
    @include media-breakpoint-up(md) {
        font-size: $font-size-big;
    }
}

.search-results__no-results__img {
    height: 5rem;
}

.search-results__no-results__title {
    font-size: 1.375rem;
    font-family: $font-family-accent;
    font-weight: 500;
}

.search-results__no-results__help {
    font-family: $font-family-accent;
    font-weight: 500;
}

.search-results__no-results__new-search {
    color: $accent-color;
}

.search-results__no-results__new-search__icon {
    font-size: 1.75rem;
}
</style>
