import { axiosHttp } from '@/util/AxiosHttp';
import { ISyncExecution } from '../../../../functions/src/shared/contracts/SyncExecutionContract';
import { Constants } from '../Constants';

class AdminService {
    public async getLastSync(): Promise<ISyncExecution> {
        const response = await axiosHttp.http.get(`${Constants.baseOperationsUrl}/syncs/lastSuccessful`);
        return response.data;
    }
}

export const adminService = new AdminService();
