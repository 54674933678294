<template>
    <div v-if="type === 'cancel'" @click="cancel" class="cancel-button">
        <font-awesome-icon class="mr-2"
                           :icon="['fas', 'xmark']" />
        <span class="text-underline">{{ label ? $t(label) : $t('sign_feedback.cancel') }}</span>
    </div>
    <button v-else-if="type==='button'" :disabled="disabled || loading"
            :class="`${disabled? 'vgtc-button--disabled': 'vgtc-button box-shadow-none'}`" @click="click">
        <b-spinner small v-if="loading" class="mr-2"></b-spinner>
        <font-awesome-icon v-else-if="icon" class="mr-2"
                           :icon="icon" />
        <span>{{ $t(label) }}</span></button>

</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

@Component({ components: {} })
export default class Button extends Vue {

    @Prop({ default: 'button' }) public type!: 'cancel' | 'button';
    @Prop({ default: false }) public disabled!: boolean;
    @Prop({ default: false }) public loading!: boolean;
    @Prop() public label?: string;
    @Prop() public icon?: string[];

    @Emit('click')
    public click() {
        return;
    }

    @Emit('cancel')
    public cancel() {
        return;
    }
}
</script>

<style lang="scss">
.vgtc-button--disabled {
    align-items: center;
    display: flex;
    padding: 0.5rem 1rem 0.5rem 1rem;
    border-radius: $default-border-radius;
    color: $disabled-text-color;
    background-color: $disabled-color;
    border: $thin-border-size solid $disabled-color;
}

.cancel-button {
    cursor: pointer;
}
</style>

