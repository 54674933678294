import store from '@/store/store';
import { DirectiveOptions } from 'vue';

const requireAdminFunc = (el: HTMLElement) => {
    const displayAttribute = 'require-admin-display';
    const toggleVisibility = () => {
        if (store.getters['user/isSignedIn']) {
            el.style.display = el.getAttribute(displayAttribute) as string;
        } else {
            const style = getComputedStyle(el);
            el.setAttribute(displayAttribute, style.display || 'block');
            el.setAttribute('style', 'display: none !important');
        }
    };
    store.watch(
        state => {
            return (state as any).user.userInfo;
        },
        () => {
            toggleVisibility();
        },
    );

    toggleVisibility();
};

const requireAdmin: DirectiveOptions = {
    inserted(el) {
        requireAdminFunc(el);
    },
    update(el) {
        requireAdminFunc(el);
    },
};

export default requireAdmin;
