import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { getInfoModal, IInfoModal } from '../components/search/option/searchOptions/infoModals/infomodals';
import { SearchType } from '../components/search/SearchType';
import { IDisplayState, IRootState } from './contract';
import store from './store';

const displayState: IDisplayState = {
    isFooterVisible: true,
    isInfoModalVisible: false,
    loadedInfoModal: undefined,
};

const mutations: MutationTree<IDisplayState> = {
    setFooterVisible(state: IDisplayState, visible: boolean) {
        state.isFooterVisible = visible;
    },
    setInfoModalVisible(state: IDisplayState, visible: boolean) {
        state.isInfoModalVisible = visible;
    },
    loadInfoModal(state: IDisplayState, searchType: SearchType) {
        state.loadedInfoModal = getInfoModal(searchType);
    },
};

const onBackInInfoModal = () => {
    store.dispatch('display/hideInfoModal', true);
};
const onKeyPressInInfoModal = (ev: KeyboardEvent) => {
    if (ev.key === 'Escape') {
        store.dispatch('display/hideInfoModal');
    }
};
const onClickHandler = (event: MouseEvent) => {
    if (!(document.querySelector('.info-modal > [role="document"]') as Element).contains(event.target as Node)) {
        store.dispatch('display/hideInfoModal');
    }
};

const actions: ActionTree<IDisplayState, IRootState> = {
    async showFooter({ commit, state }) {
        await commit('setFooterVisible', true);
    },
    async hideFooter({ commit, state }) {
        await commit('setFooterVisible', false);
    },
    async showInfoModal({ commit, state }, searchType: SearchType) {
        await commit('loadInfoModal', searchType);
        await commit('setInfoModalVisible', true);
        stopBodyScrolling(true);
        history.pushState({}, document.title);
        window.addEventListener('popstate', onBackInInfoModal);
        window.addEventListener('keyup', onKeyPressInInfoModal);
        setTimeout(() => {
            window.addEventListener('click', onClickHandler);
        });
    },
    async hideInfoModal({ commit, state }, fromBackEvent: boolean) {
        setTimeout(async () => {
            await commit('setInfoModalVisible', false);
        });
        stopBodyScrolling(false);
        window.removeEventListener('popstate', onBackInInfoModal);
        window.removeEventListener('keyup', onKeyPressInInfoModal);
        window.removeEventListener('click', onClickHandler);
        if (!fromBackEvent) {
            history.back();
        }
    },
};

const getters: GetterTree<IDisplayState, IRootState> = {
    isFooterVisible(state): boolean {
        return state.isFooterVisible;
    },
    isInfoModalVisible(state): boolean {
        return state.isInfoModalVisible;
    },
    loadedInfoModal(state): IInfoModal | undefined {
        return state.loadedInfoModal;
    },
};

const namespaced = true;

export const display: Module<IDisplayState, IRootState> = {
    actions,
    getters,
    mutations,
    namespaced,
    state: displayState,
};

// Hacky implementation to prevent scrolling background with modal
// (not working for safari IOS because safari...)

function stopBodyScrolling(bool: boolean) {
    if (bool) {
        document.body.classList.add('freeze-page');
        document.querySelector('html')!.classList.add('freeze-page');
        //document.body.addEventListener('touchmove', freezeVp, false);
    } else {
        document.body.classList.remove('freeze-page');
        document.querySelector('html')!.classList.remove('freeze-page');
        //document.body.removeEventListener('touchmove', freezeVp, false);
    }
}
