export default class Looper {
    /**
     * The duration (in ms) between each function execution.
     */
    private readonly tickLength: number;

    /**
     * The total duration (in ms) that the looper has run.
     */
    private ticks: number = 0;

    /**
     * The function which is looped.
     */
    private readonly loopingFunction: () => void;
    private readonly invariant: () => boolean;
    private readonly endingFunction: () => void;

    private running: boolean = false;
    private timerId: number = -1;

    constructor(tickLength: number, loopingFunction: () => void, invariant: () => boolean = () => true, endingFunction: () => void = () => { return }) {
        this.tickLength = tickLength;
        this.loopingFunction = loopingFunction;
        this.invariant = invariant;
        this.endingFunction = endingFunction;
    }

    public start(): void {
        this.running = true;
        this.timerId = setInterval(() => this.loop(this), this.tickLength) as unknown as number;
    }

    public stop(): void {
        this.running = false;
    }

    public get nrOfTicks(): number {
        return this.nrOfTicks;
    }

    private loop(th: Looper): void {
        if (!th.running || !th.invariant()) {
            clearInterval(th.timerId);
            this.endingFunction();
            return;
        }
        th.ticks++;
        this.loopingFunction();
    }
}