<template>
  <button v-RequireAdmin
          class="btn d-flex align-items-center"
          @click="signOut()">
    <font-awesome-icon class="mr-md-2"
                       :class="{'mr-3':showLabelMobile}"
                       :icon="['fas', 'sign-out']" />
    <span class="d-md-block"
          :class="{'d-none' : !showLabelMobile, 'd-block' : showLabelMobile}">{{ $t('general.signOut') }}</span>
  </button>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import RequireAdmin from '../../directives/RequireAdminDirective';

const userModule = namespace('user');

@Component({ directives: { RequireAdmin } })
export default class SignOut extends Vue {
    @Prop({ default: true }) public showLabelMobile!: boolean;

    @userModule.Action
    public signOut() {
        return;
    }
}
</script>

<style lang="scss">
</style>