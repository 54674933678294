import store from '@/store/store';
import { groupBy } from '@/util/util-functions';
import { Dictionary } from 'vue-router/types/router';
import { IGlossDetail } from '../../../../functions/src/shared/contracts/api/GlossContract';
import { ICategory, ILabel, ILocation } from '../../../../functions/src/shared/contracts/api/MasterdataContract';
import { Region } from '../../../../functions/src/shared/contracts/Region';
import { SearchType } from '../../components/search/SearchType';
import {
    CategoryTag,
    GlossTag,
    HandshapeTag,
    ITag,
    LocationTag,
    RegionTag,
    TextTag,
    LabelTag
} from '../../components/search/TagModel';
import { axiosHttp } from '../../util/AxiosHttp';
import { Constants } from '../Constants';

class TagService {
    private queryParamsToMapFunction: { [key: string]: (param: string) => Promise<ITag> } = {
        c: (p: string) => {
            const category = store.state.cache.categories.find((c: ICategory) => c.id === p);
            return category
                ? Promise.resolve(CategoryTag(category.name, p))
                : Promise.reject(Error(`category ${p} not found`));
        },
        g: async (p: string) => {
            const gloss = await axiosHttp.http.get<IGlossDetail>(`${Constants.baseApiUrl}/glosses/${p}`);
            return Promise.resolve(GlossTag(gloss.data.translations[0], p));
        },
        h: (p: string) => {
            return Promise.resolve(HandshapeTag(p));
        },

        l: (p: string) => {
            const location = store.state.cache.locations.find((l: ILocation) => l.id === p);
            return location
                ? Promise.resolve(LocationTag(location.name, location.id))
                : Promise.reject(Error(`location ${p} not found`));
        },
        q: (p: string) => {
            return Promise.resolve(TextTag(p));
        },
        r: (p: string) => {
            return Promise.resolve(RegionTag(p, p as Region));
        },
        lb: (p: string) => {
            const label = store.state.cache.labels.find((l: ILabel) => l.id === p);
            return label
                ? Promise.resolve(LabelTag(label.name, p))
                : Promise.reject(Error(`label ${p} not found`));
        }
    };

    private tagsToQueryParamFunctions: { [key: string]: string } = {
        [SearchType.Category]: 'c',
        [SearchType.Gloss]: 'g',
        [SearchType.Handshape]: 'h',
        [SearchType.Location]: 'l',
        [SearchType.Region]: 'r',
        [SearchType.Text]: 'q',
        [SearchType.Label]: 'lb'
    };

    public async mapQueryParametersToTags(queryParameters: Dictionary<string | string[]>): Promise<ITag[]> {
        const results: ITag[][] = await Promise.all(
            Object.keys(this.queryParamsToMapFunction).map(async qp => {
                let tagValues = queryParameters[qp];
                if (tagValues) {
                    if (!(tagValues instanceof Array)) {
                        tagValues = [tagValues];
                    }
                    return await Promise.all(tagValues.map((tv: string) => this.queryParamsToMapFunction[qp](tv)));
                }
                return [];
            }),
        );
        return results.flat();
    }

    public mapTagsToQueryParams(tags: ITag[]): Dictionary<string | string[]> {
        const groupedTags: { [searchType: string]: ITag[] } = groupBy('searchType', tags);
        const result: { [key: string]: string[] } = {};
        Object.keys(groupedTags).forEach(searchType => {
            result[this.tagsToQueryParamFunctions[searchType]] = groupedTags[searchType].map(tag => tag.value);
        });
        return result;
    }
}

export const tagService = new TagService();
