import { SearchType } from '../../SearchType';
import { ISearchOption } from './SearchOptionModel';

// search option Components are loaded dynamically
export const searchOptions: ISearchOption[] = [
    {
        component: () => import('./HandshapeSearchOption.vue'),
        optionName: 'search.search_on_handshape',
        searchType: SearchType.Handshape,
    },
    {
        component: () => import('./LocationSearchOption.vue'),
        optionName: 'search.search_on_location',
        searchType: SearchType.Location,
    },
    {
        component: () => import('./CategorySearchOption.vue'),
        optionName: 'search.search_on_category',
        searchType: SearchType.Category,
    },
    {
        component: () => import('./RegionSearchOption.vue'),
        optionName: 'search.search_on_region',
        searchType: SearchType.Region,
    },
    {
        component: () => import('./LabelSearchOption.vue'),
        optionName: 'search.search_on_label',
        searchType: SearchType.Label,
    },
];
