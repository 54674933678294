<template>
  <font-awesome-icon v-if="icon.faIcon"
                     :icon="[icon.faIcon.iconVariant, icon.faIcon.iconDescription]" />
  <SvgIcon v-else
           class='fa svg-search-type-icon'
           :icon="icon.customSvg"
           :fill="true" />
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { IIcon } from '../../util/gui-decorators';
import SvgIcon from '../SvgIcon.vue';
import { SearchType } from './SearchType';

@Component({ components: { SvgIcon } })
export default class SearchTypeIcon extends Vue {
    @Prop()
    public searchType!: SearchType;

    public get icon(): IIcon {
        switch (this.searchType) {
            case SearchType.Category:
                return { faIcon: { iconDescription: 'th-large', iconVariant: 'fas' } };
            case SearchType.Handshape:
                return { faIcon: { iconDescription: 'hand-paper', iconVariant: 'fas' } };
            case SearchType.Image:
                return { faIcon: { iconDescription: 'image', iconVariant: 'fas' } };
            case SearchType.Location:
                return { faIcon: { iconDescription: 'user', iconVariant: 'fas' } };
            case SearchType.Gloss:
                return { faIcon: { iconDescription: 'sign-language', iconVariant: 'fas' } };
            case SearchType.Label:
                return { faIcon: { iconDescription: 'tag', iconVariant: 'fas'}  };
            case SearchType.Region:
                return { customSvg: 'belgium' };
        }
        return { faIcon: { iconDescription: 'image', iconVariant: 'fas' } };
    }
}
</script>

<style lang="scss">
.svg-search-type-icon {
    //height: 1.5rem;
    fill: currentColor;
}
</style>

