<template>
  <div v-if="!hasConsentCookie"
       class="cookie-banner p-3 p-sm-4 d-flex flex-column flex-md-row align-items-center">
    <div class="d-flex align-items-center">
      <Cookies class="cookie-banner__cookie d-none d-sm-block mr-3 flex-grow-0 flex-shrink-0" />
      <i18n path="cookie_banner.text">
        <a place="policy"
           class="cookie-banner__link"
           :href="privacyPolicyUrl"
           target="_blank">{{ $t('cookie_banner.privacy_policy') }}</a>
      </i18n>
    </div>
    <div class="d-flex justify-content-end justify-content-md-start flex-md-column justify-content-lg-end flex-lg-row mt-3 mt-md-0">
      <div>
        <button class="vgtc-button cookie-banner__button ml-md-3"
                @click="acceptCookies()">
          <font-awesome-icon :icon="['far', 'check']"
                             class="mr-2 fa-lg" />
          {{ $t('cookie_banner.agree') }}
        </button>
      </div>
      <div>
        <button class="vgtc-button cookie-banner__button cookie-banner__button--refuse ml-3 mt-md-3 mt-lg-0"
                @click="refuseCookies()">
          <font-awesome-icon :icon="['far', 'times']"
                             class="mr-2 fa-lg" />
          {{ $t('cookie_banner.refuse') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Cookies from '@/assets/svg/cookies.svg';
import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter, namespace } from 'vuex-class';
import { Constants } from '../../shared/Constants';
import { cookieService } from '../../shared/services/CookieService';

const userModule = namespace('user');

@Component({ components: { Cookies } })
export default class CookieBanner extends Vue {
    public readonly privacyPolicyUrl = Constants.privacyPolicyUrl;

    @userModule.Getter
    public hasConsentCookie!: boolean;

    public created() {
        this.initializeCookies();
    }

    @userModule.Action
    public initializeCookies(): void {
        return;
    }

    @userModule.Action
    public acceptCookies(): void {
        return;
    }

    @userModule.Action
    public refuseCookies(): void {
        return;
    }
}
</script>

<style lang="scss">
.cookie-banner {
    position: fixed;
    bottom: 0;
    min-height: 6rem;
    width: 100%;
    background: $cookie-banner-color;
    color: $cookie-banner-text-color;
    font-size: $font-size-small;
    opacity: 0.95;
    z-index: $zindex-cookie-banner;

    @include media-breakpoint-up(md) {
        font-size: $font-size-slightly-smaller;
    }
}

.cookie-banner__cookie {
    height: 4rem;
    width: 4rem;
}

.cookie-banner__link {
    color: $accent-color-light;

    &:hover {
        color: $accent-color-hover;
    }
}

.vgtc-button.cookie-banner__button {
    white-space: nowrap;
    background-color: $accent-color;

    &--refuse {
        background-color: transparent;
        border-color: $cookie-banner-text-color;
    }
}
</style>