import { initializeApp } from 'firebase/app';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import axios, { AxiosResponse } from 'axios';
import BootstrapVue from 'bootstrap-vue';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'bootstrap/dist/css/bootstrap.css';
import Vue from 'vue';
import 'vue-loading-overlay/dist/vue-loading.css';
import App from './App.vue';
import { EndPoints } from './EndPoints';
import './filters/joinFilter';
import './filters/translationFilter';
import './init/classComponentHooks';
import './init/cookies';
import * as faIcons from './init/font-awesome-icons';
import i18n from './init/i18n';
import './init/polyfills';
import { ga } from './init/vue-analytics';
import './init/vue-scrollto';
import router from './router';
import { IVGTCConfig } from './shared/ConfigContract';
import { Constants } from './shared/Constants';
import { doorbellService } from './shared/services/DoorbellService';
import store from './store/store';
import './styles/build.scss';
import { axiosHttp } from './util/AxiosHttp';
import { getAuth } from 'firebase/auth';

Vue.use(BootstrapVue);

const response: AxiosResponse<IVGTCConfig> = await axios.get<IVGTCConfig>('config.json');
const config = response.data;
Constants.init(config);

const app = initializeApp(config.firebaseConfig);
const auth = getAuth(app);

await store.dispatch('user/setUserFromCurrent');

ga.init(config.googleAnalyticsTrackingId);
axiosHttp.init();
doorbellService.init(config);
EndPoints.init();

library.add(faIcons);
Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.config.productionTip = false;

new Vue({
    i18n,
    render: h => h(App),
    router,
    store,
}).$mount('#app');

export { auth };
