<template>
    <div class="container vgtc-card pt-3 pb-5">
        <div class="d-flex align-items-start justify-content-between ml-4 mr-3">
            <div class="d-flex align-items-center">
                <font-awesome-icon class="mr-2 color-brand" size="2xl"
                                   :icon="icon" />
                <div class="ml-2 sign-feedback__header__text">{{ $t(title) }}</div>
            </div>
        </div>
        <div class="divider divider--light my-1 mx-3"></div>
        <div class="mt-4 px-3">
            <slot></slot>
        </div>
    </div>

</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({ components: {} })
export default class SignFeedbackCard extends Vue {
    @Prop() title: string;
    @Prop() icon: string[];
}
</script>

<style lang="scss" scoped>
.sign-feedback {
    &__header__text {
        font-size: 1.5rem;
        color: $text-title-color;
        font-weight: bold;
        font-family: $font-family-accent;
        letter-spacing: 0.075rem;
        @include media-breakpoint-up(sm) {
            font-size: 2.1rem;
        }
    }
}
</style>

