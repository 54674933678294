import { SearchType } from '@/components/search/SearchType';
import { ITag } from '@/components/search/TagModel';
import { logService } from './LogService';
import Vue from 'vue';

enum EventCategory {
    SEARCH = 'Search',
    VIDEO = 'Videos',
}

interface IEvent {
    category: EventCategory;
    action: string;
}

class EventService {
    public readonly Events: { [key: string]: IEvent } = {
        ADD_TAG: { category: EventCategory.SEARCH, action: 'searched on term' },
        ADD_TAG_CATEGORY: { category: EventCategory.SEARCH, action: 'add cateogry to search' },
        ADD_TAG_HANDSHAPE: { category: EventCategory.SEARCH, action: 'add handshape to search' },
        ADD_TAG_LOCATION: { category: EventCategory.SEARCH, action: 'add location to search' },
        ADD_TAG_SIGN: { category: EventCategory.SEARCH, action: 'add sign to search' },
        ADD_TAG_TEXT: { category: EventCategory.SEARCH, action: 'add text to search' },
        SEARCH_CATEGORIES: { category: EventCategory.SEARCH, action: 'categories used in search' },
        SEARCH_FROM_DETAILS_PAGE: { category: EventCategory.SEARCH, action: 'search triggered from details page' },
        SEARCH_HANDSHAPES: { category: EventCategory.SEARCH, action: 'handshapes used in search' },
        SEARCH_LOCATIONS: { category: EventCategory.SEARCH, action: 'locations used in search' },
        SEARCH_NO_RESULTS: { category: EventCategory.SEARCH, action: 'search had no results' },
        SEARCH_ON_CATEGORY_USED: { category: EventCategory.SEARCH, action: '#searches on category' },
        SEARCH_ON_HANDSHAPE_USED: { category: EventCategory.SEARCH, action: '#searches on handshape' },
        SEARCH_ON_LOCATION_USED: { category: EventCategory.SEARCH, action: '#searches on location' },
        SEARCH_ON_TEXT_USED: { category: EventCategory.SEARCH, action: '#searches on text' },
        VIDEO_FAILED_TO_LOAD: { category: EventCategory.VIDEO, action: 'loading video failed' },
        VIDEO_LOOP: { category: EventCategory.VIDEO, action: 'loop video' },
        VIDEO_PAUSE_ACTION: { category: EventCategory.VIDEO, action: 'pause video' },
        VIDEO_PLAY_ACTION: { category: EventCategory.VIDEO, action: 'play video' },
        VIDEO_PLAY_SLOWLY: { category: EventCategory.VIDEO, action: 'slow down video' },
    };

    public track(event: IEvent, eventLabel?: string) {
        try {
            if ((Vue as any).$gtag) {
                (Vue as any).$gtag.event(event.action, {
                    event_category: event.category,
                    event_label: eventLabel,
                    value: eventLabel,
                });
            }
        } catch (err: any) {
            logService.logError(err, 'Could not track GA event');
        }
    }

    public trackAddTags(tags: ITag[]): void {
        // For now this dictionary is overkill, because only one type of tag is searched at a time.
        // const groupKey: keyof ITag = 'searchType'; // This makes sure that typescript checks whether the string 'searchType' is realy a property of ITag
        // const tagsDictionary: {[searchType:string]: ITag[]} = groupBy<ITag, keyof ITag>(tags, groupKey);

        // Track all searches individually for counting:
        tags.forEach(tag => this.track(this.Events.ADD_TAG, tag.value));

        // Improve this later because of overhead?
        const categoryTags: ITag[] = tags.filter(tag => tag.searchType === SearchType.Category);
        const locationTags: ITag[] = tags.filter(tag => tag.searchType === SearchType.Location);
        const handshapeTags: ITag[] = tags.filter(tag => tag.searchType === SearchType.Handshape);
        //const textTags: ITag[] = tags.filter(tag => tag.searchType === SearchType.Text);
        //const signTags: ITag[] = tags.filter(tag => tag.searchType === SearchType.Sign);

        if (categoryTags.length > 0) {
            this.track(this.Events.SEARCH_ON_CATEGORY_USED);
            this.sortAndTrackTags(categoryTags, this.Events.SEARCH_CATEGORIES);
        }
        if (locationTags.length > 0) {
            this.track(this.Events.SEARCH_ON_LOCATION_USED);
            this.sortAndTrackTags(locationTags, this.Events.SEARCH_LOCATIONS);
        }
        if (handshapeTags.length > 0) {
            this.track(this.Events.SEARCH_ON_HANDSHAPE_USED);
            this.sortAndTrackTags(handshapeTags, this.Events.SEARCH_HANDSHAPES);
        }
    }

    private sortAndTrackTags(tags: ITag[], event: IEvent) {
        const sortedTags = tags
            .map(tag => tag.value)
            .sort((tagV1: string, tagV2: string) => {
                return tagV1 > tagV2 ? 1 : tagV1 < tagV2 ? -1 : 0;
            });
        this.track(event, sortedTags.join(';'));
    }
}

export const eventService = new EventService();
