<template>
  <div class="amount-indicator"
       :class="[sizeClass, typeClass]">
    <div class="d-flex align-items-baseline justify-content-center">
      {{ amount === 0 || amount > 0 ? amount : '-' }}
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class AmountIndicator extends Vue {
    @Prop()
    public amount!: number | undefined;

    @Prop()
    public size!: string | undefined;

    @Prop()
    public type!: string | undefined;

    public get sizeClass(): string {
        const cls = this.size || 'normal';
        return `amount-indicator--size-${cls}`;
    }

    public get typeClass(): string {
        const cls = this.type || 'default';
        return `amount-indicator--type-${cls}`;
    }
}
</script>

<style lang="scss">
.amount-indicator {
    font-size: $font-size-medium;
    border: $thin-border-size solid $card-text-color;
    border-radius: 1.125rem;
    display: inline-block;
    min-width: 2.25rem;
    min-height: 2.25rem;
    padding: 0.125rem 0.5rem;

    &--size-small {
        $size: 1.25rem;
        min-width: $size;
        min-height: $size;
        border-radius: $size;
        font-size: 0.75rem;
        padding: 0;
        padding-left: 0.125rem;
    }

    &--type-accent {
        background-color: $accent-color;
        color: $accent-text-color;
        border-color: $accent-color;
    }
}

.amount-indicator__number {
    height: 100%;
}
</style>