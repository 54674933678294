<template>
    <div class="d-flex flex-column h-100">
        <div class="flex flex-grow-1">
            <Navbar v-if="showNavbar" />
            <router-view />
        </div>
        <Footer v-if="showFooter"
                class="flex-shrink-0 d-none d-md-flex " />
        <div v-if="isInfoModalVisible"
             class="modal root__info-modal"
             tabindex="-1"
             role="dialog">
            <InfoModal class="d-flex" />
        </div>
        <CookieBanner />
    </div>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Footer from '../components/brand/Footer.vue';
import CookieBanner from '../components/general/CookieBanner.vue';
import Navbar from '../components/Navbar.vue';
import InfoModal from '../components/search/option/searchOptions/infoModals/InfoModal.vue';
import { ISearchOption } from '../components/search/option/searchOptions/SearchOptionModel';
import { logService } from '../shared/services/LogService';

const cacheModule = namespace('cache');
const displayModule = namespace('display');
const searchModule = namespace('search');

@Component({ components: { CookieBanner, Footer, InfoModal, Navbar } })
export default class Root extends Vue {
    public showNavbar = true;
    public hideFooter = false;

    public get showFooter(): boolean {
        return this.isFooterVisible && !this.hideFooter;
    }

    @cacheModule.Action
    private preloadData!: () => Promise<void>;

    @displayModule.Action
    private hideInfoModel!: () => Promise<void>;

    @searchModule.Getter
    private selectedSearchOption!: ISearchOption | undefined;

    @searchModule.Getter
    private searchTags!: any[];

    @displayModule.Getter
    private isFooterVisible!: boolean;

    @displayModule.Getter
    private isInfoModalVisible!: boolean;

    public async created(): Promise<void> {
        if (this.$router && this.$router.currentRoute &&
            this.$router.currentRoute && this.$router.currentRoute.meta) {
            this.showNavbar = !this.$router.currentRoute.meta.hideNav;
        }
        // currentRoute.meta is not reactive. Hence an event after each reroute needs to be triggered.
        this.$router.afterEach(to => {
            if (to && to.meta) {
                this.showNavbar = !to.meta.hideNav;
                this.hideFooter = to.meta.hideFooter;
            }
        });
        try {
            if (this.$router.currentRoute.meta.preLoad !== false)
                await this.preloadData();
        } catch (e: any) {
            logService.logError(e);
        }
    }
}
</script>
<style lang="scss">
.root__info-modal.modal {
    display: block;
    top: $navbar-mobile-hero-height;
}
</style>

