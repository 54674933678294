import store from '@/store/store';
import axios, {  AxiosInstance } from 'axios';
import { cacheAdapterEnhancer } from 'axios-extensions';
import { Constants } from '@/shared/Constants';
import firebase from 'firebase/compat';

class AxiosHttp {
    public http: AxiosInstance;

    constructor() {
        this.http = axios;
    }

    public init(): void {
        const defaultAdapter = axios.getAdapter(axios.defaults.adapter);
        this.http = axios.create({
            baseURL: Constants.baseApiUrl,
            adapter: cacheAdapterEnhancer( defaultAdapter),
            headers: {
                'Cache-Control': 'no-cache',
            },
        });

        this.http.interceptors.request.use(async config => {
            const user = store.getters['user/user'] as firebase.User;
            if (!user) {
                return config;
            }
            const token = await user.getIdToken();
            if (token) {
                config.headers.Authorization = `Bearer ${token}`;
            }
            return config;
        });
    }
}

export const axiosHttp = new AxiosHttp();
