<template>
    <div class="p-3 sign-of-the-week">
    <span class="mr-1 text-bold">
      {{ $t('admin.sign_of_week') }} {{ weekText }}:
    </span>
        <div class="row pt-3">
            <div class="col-6 d-flex flex-column justify-content-between sign-column-1">
                <div>
                    <router-link v-if="sign"
                                 :to="{name: 'glossdetail', params: { id: sign.glossName}, query:{sid: sign.signId}}">
                        {{ sign.translations | join(', ') }} ({{ sign.regions | join(', ') }})
                    </router-link>
                    <span v-else>{{ $t('admin.no_sign_yet') }}</span>
                </div>
                <div><p>{{ $t('admin.choose_sign_of_the_week_info') }}</p>
                    <button @click="setNewSignOfWeek()"
                            class="vgtc-button mt-2">{{ $t('admin.choose_sign_of_the_week_randomly') }}
                    </button>
                </div>
            </div>
            <div class="col-6 d-flex justify-content-center">
                <SearchResultCard v-if="sign" class="w-50" :search-result="sign"></SearchResultCard>
            </div>
        </div>

        <b-alert v-if="error"
                 class="mt-2"
                 variant="danger"
                 show>
            <font-awesome-icon :icon="['far', 'triangle-exclamation']" size="xl" class="mr-2" />
            {{ error }}
        </b-alert>
        <b-alert v-if="info"
                 class="mt-2"
                 variant="info"
                 show>
            <font-awesome-icon :icon="['far','square-info']" size="xl" class="mr-2"></font-awesome-icon>
            {{ info }}
        </b-alert>
    </div>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { ISignOverview } from '../../../../functions/src/shared/contracts/api/GlossContract';
import { signService } from '../../shared/services/SignService';
import { formatDate } from '../../util/util-functions';
import SearchResultCard from '@/components/search/SearchResultCard.vue';

@Component({
    components: { SearchResultCard },
})
export default class SignOfTheWeek extends Vue {
    public error: string = '';
    public info: string = '';

    @Prop()
    public sign!: ISignOverview;

    @Prop()
    public week!: Date;

    public async setNewSignOfWeek(): Promise<void> {
        try {
            this.error = '';
            const newSign = await signService.setSignOfTheWeek(this.week);
            this.onNewSign(newSign);
            this.info = this.$t('admin.new_sign_chosen').toString();
        } catch (error) {
            this.info = '';
            this.error = `${this.$t('admin.something_went_wrong')} ${error}`;
        }
        setTimeout(() => {
            this.info = '';
            this.error = '';
        }, 3000);
    }

    public get weekText(): string {
        return formatDate(this.week);
    }

    @Emit('new-sign')
    private onNewSign(sign: ISignOverview): ISignOverview {
        return sign;
    }
}
</script>

<style lang="scss">
.sign-of-the-week {
    border: $card-border;
}

.sign-column-1 {
    border-right: 1px solid $card-border-color;
}
</style>
