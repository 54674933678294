export enum SearchType {
    Category = 'Category',
    Handshape = 'Handshape',
    Image = 'Image',
    Label = 'Label',
    Location = 'Location',
    Gloss = 'Gloss',
    Text = 'Text',
    Region = 'Region',
}
