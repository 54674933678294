<template>
  <a class="btn faq-link d-flex"
     :href="faqUrl"
     target="_blank">
    <font-awesome-icon class="faq-link__icon mr-md-2"
                       :icon="['fas', 'question-circle']" />
    <span class="d-none d-md-block">{{ $t('general.help')}}</span>
  </a>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { Constants } from '../../shared/Constants';

@Component({})
export default class FAQLink extends Vue {
    public readonly faqUrl = Constants.faqUrl;
}
</script>

<style lang="scss">
.faq-link__icon {
    font-size: 1.33em;
}
</style>