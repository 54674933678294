<template>
  <div class="d-flex flex-column w-100">
    <div class="video-player__container">
      <div class="video-recorder__button-container video-recorder__button-container--top">

      </div>
      <video class="video-player__video"
             :class="{'blurred-unclickable':loading}"
             :ref="videoRef"
             playsinline>
      </video>
      <div class="d-flex justify-content-center video-recorder__button-container w-100">
        <button v-show="!recordedVideoExists && !loading"
                @click="toggleRecording()"
                class="btn video-recorder__button">
          <CountDownRecordButton @timerFinished="stopRecording"
                                 :paused="!recording"
                                 :countDownMilliseconds="fiveMinutes"
                                 :tickSize="500" />
        </button>
        <!-- <button v-show="!recordedVideoExists"
                @click="$emit('alternativeButtonClicked')"
                class="btn r-0 b-0 position-absolute vgtc-button vgtc-button--color-brand">
          <slot></slot>
        </button> -->
        <div class="d-flex flex-grow-1"
             v-if="recordedVideoExists ">
          <button @click="resetRecorder()"
                  class="d-flex w-25 position-absolute video-recorder__button__redo flex-column vgtc-button vgtc-button--color-brand justify-content-center">
            <font-awesome-icon class=""
                               :icon="['far','redo']" />
            <span class="text-center">{{$t('feedback.redo')}}</span>
          </button>
          <button @click="togglePlayback()"
                  class="btn mx-auto video-recorder__button">
            <font-awesome-icon class="video-recorder__button p-1 video-recorder__button--play-pause fa-4x"
                               :icon="playIcon"> </font-awesome-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { detect } from 'detect-browser';
import RecordRTC from 'recordrtc';
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import CountDownRecordButton from './CountDownRecordButton.vue';

enum RecorderState {
    Recording = 'recording',
    Stopped = 'stopped',
    Inactive = 'inactive',
    Paused = 'paused',
}

@Component({ components: { CountDownRecordButton } })
export default class RTCVideoRecorder extends Vue {
    @Prop({ default: 1000 }) public updateTime!: number;
    @Prop({ default: -1 }) public byteSizeLimit!: number;
    @Prop() public value!: Blob | null;

    public currentVideoSize: number = 0;
    public fiveMinutes: number = 5 * 60 * 1000;
    public loading: boolean = false;
    private browser: string = (detect() && detect().name) || '';
    private recorder!: recordrtc.RecordRTCPromisesHandler;
    private stream: MediaStream | null = null;
    private videoRef: string = 'videoRecorderElement';
    private recording: boolean = false;
    private videoPlaying: boolean = false;
    private readonly recordOptions = {
        audioBitsPerSecond: 128000,
        mimeType: 'video/webm', // or video/webm\;codecs=h264 or video/webm\;codecs=vp9
        timeSlice: this.updateTime,
        type: 'video',
        videoBitsPerSecond: 256000,
    };

    public async created(): Promise<void> {
        this.videoRef = `videoRecorderElement-${Math.random()}`;
    }

    public mounted(): void {
        const videoEl = this.getVideoElement();
        videoEl.muted = true;
        videoEl.addEventListener('pause', () => {
            this.videoPlaying = false;
        });
        videoEl.addEventListener('play', () => {
            this.videoPlaying = true;
        });
        this.updateVideoEl();
    }

    public destroyed(): void {
        this.destroy();
    }

    public get recordIcon(): string[] {
        return this.recording ? ['far', 'stop-circle'] : ['far', 'dot-circle'];
    }

    public get playIcon(): string[] {
        return this.videoPlaying ? ['fas', 'pause-circle'] : ['fas', 'play-circle'];
    }

    public get recordedVideoExists(): boolean {
        return this.value !== null && this.value !== undefined;
    }

    public async toggleRecording() {
        if (!this.recorder) {
            return;
        }
        if ((await this.recorder.getState()) === RecorderState.Recording) {
            await this.stopRecording();
        } else {
            this.startRecording();
        }
    }

    public togglePlayback(): void {
        const videoEl = this.getVideoElement();
        if (!videoEl) {
            return;
        }
        if (videoEl.paused) {
            videoEl.play();
            videoEl.muted = false;
        } else {
            videoEl.pause();
            videoEl.muted = true;
        }
    }

    public async resetRecorder(): Promise<void> {
        if (this.recorder) {
            this.recorder.reset();
        }
        this.getVideoElement().muted = true;
        this.emitBlob(null);
    }

    private destroy(): void {
        this.destroyStream();
        if (this.recorder) {
            this.recorder.destroy();
        }
    }

    private async initRecorder() {
        if (!(this.browser === 'chrome' || this.browser === 'chromium-webview' || this.browser === 'firefox')) {
            this.$emit('incompatibleWithBrowser');
            return;
        }
        this.loading = true;
        await this.initStream();
        this.recorder = new RecordRTC.RecordRTCPromisesHandler(this.stream, this.recordOptions);
        this.getVideoElement().srcObject = this.stream;
        this.getVideoElement().play();
        this.loading = false;
    }

    private async startRecording(): Promise<void> {
        try {
            await this.recorder.startRecording();
            this.recording = true;
        } catch (err) {
            this.$emit('recordingFailed');
        }
    }

    private async stopRecording(): Promise<void> {
        this.loading = true;
        if (!((await this.recorder.getState()) === RecorderState.Recording)) {
            this.loading = false;
            return;
        }
        this.videoPlaying = false;
        this.recording = false;
        await this.recorder.stopRecording();
        const blob = await this.recorder.getBlob();
        this.destroyStream();
        this.emitBlob(blob);
        this.loading = false;
    }

    @Emit('input')
    private emitBlob(blob: Blob | null): void {
        return;
    }

    @Watch('value')
    private async updateVideoEl(): Promise<void> {
        const video = this.getVideoElement();
        if (video) {
            if (this.recordedVideoExists) {
                video.srcObject = null;
                video.src = URL.createObjectURL(this.value);
            } else {
                video.src = '';
                await this.initRecorder();
            }
        }
    }

    private destroyStream(): void {
        if (this.stream) {
            this.stream.getTracks().forEach(track => track.stop());
            this.stream = null;
        }
    }

    private async initStream(): Promise<void> {
        if (!this.stream || !this.stream.active) {
            this.stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
        }
    }

    private maxSizeExceeded(): boolean {
        if (this.byteSizeLimit === -1) {
            return false;
        }
        if (this.currentVideoSize >= this.byteSizeLimit) {
            return false;
        } else {
            return true;
        }
    }

    private getVideoElement(): HTMLVideoElement {
        return this.$refs[this.videoRef] as HTMLVideoElement;
    }
}
</script>

<style lang="scss">
.video-recorder__button {
    outline: none !important;
    box-shadow: none !important;
    svg {
        width: calc(10vw + 1rem) !important;
        height: auto;
        @include media-breakpoint-up(sm) {
            width: 4rem !important;
        }
        @include media-breakpoint-up(md) {
            width: 5rem !important;
        }
    }

    &--play-pause {
        background-color: white;
        border-radius: 50%;
        color: $brand-color !important;
        &:hover {
            color: $brand-color-dark !important;
        }
    }
}

.video-recorder__button__redo {
    bottom: 0;
    top: 0;
}

.video-recorder__button-container {
    z-index: $zindex-popover;
    bottom: -0.07rem;
    position: absolute;
    &--top {
        bottom: 0;
        top: 0;
        right: 0;
    }
}
</style>
