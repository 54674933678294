<template>
    <div>
        <div class="sign-feedback__sub-header__text">{{ $t('sign_feedback.step6.subtitle') }}</div>
        <div class="row mt-3">
            <div class="col-7 mt-2">
                <div> {{ $t('sign_feedback.step6.explanation1') }}</div>
                <div class="mt-2"> {{ $t('sign_feedback.step6.explanation2') }}</div>
            </div>
            <div class="col-5 mt-n5 mb-4">
                <VideoPlayer videoSrc="/video/Dankzij-jouw-hulp-kunnen-we-SignBuddy-verder-trainen.mp4"
                             :show-download-button="false" />
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-7">
                <div class="sign-feedback__sub-header__text">{{ $t('sign_feedback.step6.question_another_sign') }}
                </div>
                <div class="d-flex align-items-center justify-content-between mt-3">
                    <Button type="cancel"
                            @cancel="cancel"></Button>
                    <Button class="ml-5" type="button" label="sign_feedback.step6.another_sign"
                            :icon="['far', 'repeat']" :loading="loading" :disabled="loading"
                            @click="nextStep"></Button>
                </div>
            </div>
            <div class="col-5 text-center">
                <SignBuddy class="icon-sign-buddy text-primary mr-3 mt-n3"></SignBuddy>
            </div>
        </div>
        <div class="row pt-5">
            <div class="col-12">
                {{ $t('sign_feedback.step6.logos') }}
            </div>
        </div>
        <div class="row mt-1 d-flex logo-box justify-content-around align-items-center">
            <img class="logo-box-vgtc"
                 src="../../../public/img/Logo_VGTc.png">
            <img class="logo-box-ugent"
                 src="../../../public/img/Logo_UGent.png">
            <img class="logo-box-amai"
                 src="../../../public/img/Logo_Amai.png">
            <img class="logo-box-ai"
                 src="../../../public/img/Logo_AI_Vlaanderen.png">
            <img class="logo-box-vvw"
                 src="../../../public/img/Logo_Vlaanderen_verbeelding_werkt.png">
        </div>
    </div>
</template>
<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import Button from '@/components/general/Button.vue';
import SignBuddy from '@/assets/svg/SignBuddy.svg';
import VideoPlayer from '@/components/video/VideoPlayer.vue';

@Component({ components: { Button, VideoPlayer, SignBuddy } })
export default class ThankYou extends Vue {

    @Prop() loading!: boolean;

    @Emit('nextStep')
    public nextStep() {
        return;
    }

    @Emit('cancel')
    public cancel() {
        return;
    }
}
</script>

<style lang="scss" scoped>
.logo-box {
    &-vgtc {
        height: 180px;
    }

    &-ugent {
        height: 160px;
    }

    &-amai {
        height: 100px;
    }

    &-ai {
        height: 110px;
    }

    &-vvw {
        height: 80px;
    }
}

.icon-sign-buddy {
    height: 150px;
}
</style>

