<template>
    <div>
        <div class="d-flex d-lg-block">
            <BackToSearch class="d-flex d-lg-none header__link header__link--back text-underline px-3"
                          iconStyle="fas" />
            <div class="d-flex justify-content-between align-items-start ml-auto">
                <router-link class="d-none d-lg-flex"
                             :to="{ name: 'root'}">
                    <VGTCLogo />
                </router-link>
                <div class="d-flex flex-wrap justify-content-end">
                    <FeedbackButton />
                    <MethodologyLink class="header__link" />
                    <FAQLink class="header__link" />
                    <SignOut :showLabelMobile="false"
                             class="header__link" />
                </div>
            </div>
        </div>
        <FeedbackModal ref="feedbackModal" />
    </div>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import VGTCLogo from './brand/VGTCLogo.vue';
import FeedbackButton from './feedback/FeedbackButton.vue';
import FeedbackModal from './feedback/FeedbackModal.vue';
import BackToSearch from './general/BackToSearch.vue';
import FAQLink from './general/FAQLink.vue';
import MethodologyLink from './general/MethodologyLink.vue';
import SignOut from './general/SignOut.vue';

@Component({
    components: { BackToSearch, FAQLink, MethodologyLink, FeedbackButton, FeedbackModal, SignOut, VGTCLogo },
})
export default class Header extends Vue {
}
</script>
<style lang="scss">
.header__link,
.btn.header__link,
.header__link a {
    color: $header-text-color;
    font-size: $header-text-size !important;

    &:hover {
        background-color: $brand-color-dark;
        border-radius: $default-border-radius;
        color: $header-text-color;
        text-decoration: none;
    }

    &--back {
        font-size: 0.875rem;
    }
}

.header__button__icon {
    background-color: transparent;
    background: transparent;
    border: none;
}
</style>
