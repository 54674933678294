<template>
  <div class="position-absolute search-option-popover">
    <div class="container">
      <div class="search-option-popover-container mt-2 position-relative"
           :class="{'d-none': !activeSearchOption}">
        <div class="position-absolute vgtc-button-close">
          <button class="btn"
                  @click="closePopover">
            <font-awesome-icon :icon="['far', 'times']" />
          </button>
        </div>
        <component @searchPerformed="searchOnTags"
                   :activeTags="activeTags"
                   :is="activeSearchOption && activeSearchOption.component" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { Action, namespace } from 'vuex-class';
import { logService } from '../../../shared/services/LogService';
import { eventHub } from '../../EventHub.vue';
import { ITag, tagsEqual } from '../TagModel';
import { ISearchOption } from './searchOptions/SearchOptionModel';

const search = namespace('search');
const displayModule = namespace('display');

@Component
export default class SearchOptionPopoverContainer extends Vue {
    @Prop() public activeSearchOption!: ISearchOption;

    // Action that pushes the selected search categories to the Store on search
    @search.Action('updateTags')
    private updateTags!: ({ tagsToAdd, tagsToRemove, replace }: { tagsToAdd: ITag[]; tagsToRemove: ITag[]; replace: Boolean }) => Promise<void>;

    @search.Getter('searchCombined')
    private searchCombined!: Boolean;

    @search.Getter('searchTags')
    private searchTags!: ITag[];

    @displayModule.Getter('isInfoModalVisible')
    private isInfoModalVisible!: boolean;

    private outsideClickEventHandler!: (event: MouseEvent) => void;
    private searchBarFocussedEventHandler!: () => void;

    @Emit('closeMe')
    public closePopover(): void {
        return;
    }

    public mounted(): void {
        this.outsideClickEventHandler = (event: MouseEvent) => {
            if (this.isInfoModalVisible) {
                return;
            }
            let searchOptionClick = false;
            document.querySelectorAll('.search-option-card').forEach(el => {
                if (el.contains(event.target as Node)) {
                    searchOptionClick = true;
                }
            });
            if (
                !searchOptionClick &&
                !(this.$el.querySelector('.container') as Element).contains(event.target as Node)
            ) {
                this.closePopover();
                this.removeOnClickEventHandler();
            }
        };
        document.addEventListener('click', this.outsideClickEventHandler);
        this.searchBarFocussedEventHandler = () => {
            this.closePopover();
        };
        eventHub.onAppEvent('searchBarFocussed', this.searchBarFocussedEventHandler);
    }

    public destroyed(): void {
        this.removeOnClickEventHandler();
        eventHub.unsubscribe('searchBarFocussed', this.searchBarFocussedEventHandler);
    }

    public async searchOnTags(tags: ITag[]) {
        setTimeout(async () => {
            this.closePopover();
            if (tags) {
                try {
                    const tagsToRemove: ITag[] = this.activeTags.filter(
                        aTag => !tags.some(tag => tagsEqual(aTag, tag)),
                    );
                    await this.updateTags({ tagsToAdd: tags, tagsToRemove, replace: !this.searchCombined });
                } catch (err:any) {
                    logService.logError(err);
                }
            }
        }, 200);
    }

    public get activeTags(): ITag[] {
        return (
            (this.activeSearchOption &&
                (this.searchTags || []).filter(t => t.searchType === this.activeSearchOption.searchType)) ||
            []
        );
    }

    private removeOnClickEventHandler(): void {
        document.removeEventListener('click', this.outsideClickEventHandler);
    }
}
</script>

<style lang="scss">
.search-option-popover-container {
    background-color: $card-background-color;
    box-shadow: $box-shadow;
    border-radius: $default-border-radius;
    width: 100%;
    color: $card-text-color;
}

.search-option-popover {
    width: 100%;
    left: 0;
    z-index: $zindex-modal;
}
</style>

