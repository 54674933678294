<template>
  <div class="contact-info">
    <div class="contact-info__logo__background d-flex justify-content-start py-4 pl-4">
      <VGTCLogo class="contact-info__logo" />
    </div>
    <div class="pt-4 px-4">
      <div class="row ">
        <div class="col-9 d-flex flex-column">
          <div class="contact-info__link text-underline d-flex align-items-start mb-5">
            <font-awesome-icon class="fa-lg mr-3 contact-info__link__icon"
                               :icon="['far','globe']"></font-awesome-icon>
            <div class="d-flex flex-column">
              <a href="https://www.vgtc.be"
                 target="_blank">www.vgtc.be</a>
            </div>
          </div>
          <div class="contact-info__link d-flex align-items-center">
            <font-awesome-icon class="mr-3 contact-info__link__icon"
                               :icon="['fas','envelope']"></font-awesome-icon>
            <a href="mailto:info@vgtc.be">info@vgtc.be</a>
          </div>
        </div>

                <div class="col-3 d-flex justify-content-end flex-column align-items-end contact-info__link">
                    <a href="https://www.facebook.com/VGTCentrum/"
                       target="_blank">
                        <font-awesome-icon class="fa-2x mb-3"
                                           :icon="['fab','facebook-square']"></font-awesome-icon>
                    </a>
                    <a href="https://twitter.com/VGTCentrum"
                       target="_blank">
                        <font-awesome-icon class="fa-2x my-3"
                                           :icon="['fab','twitter-square']"></font-awesome-icon>
                    </a>
                    <a href="https://www.linkedin.com/company/vlaams-gebarentaalcentrum?trk=top_nav_home"
                       target="_blank">
                        <font-awesome-icon class="fa-2x my-3"
                                           :icon="['fab','linkedin']"></font-awesome-icon>
                    </a>
                    <a href="https://www.instagram.com/vlaams_gebarentaalcentrum/"
                       target="_blank">
                        <font-awesome-icon class="fa-2x mt-3"
                                           :icon="['fab','instagram']"></font-awesome-icon>
                    </a>
                </div>

            </div>
            <div class="row mt-5">
                <div class="col-9"></div>
                <div class="col-3 d-flex justify-content-end">
                    <a href="https://www.ae.be"
                       target="_blank"
                       class="d-flex align-items-baseline">
                        <SvgIcon class="contact-info__link__icon contact-info__link__icon--ae"
                                 icon="ae"></SvgIcon>
                        <div class="contact-info__link contact-info__link--small ml-1">2019.</div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import VGTCLogo from '../components/brand/VGTCLogo.vue';
import SvgIcon from '../components/SvgIcon.vue';

@Component({ components: { SvgIcon, VGTCLogo } })
export default class ContactInfo extends Vue {}
</script>

<style lang="scss">
.contact-info__logo__background {
    background: $footer-background-color;
}

.contact-info__logo {
    img {
        width: 75%;
    }
}

.contact-info {
    background-color: white;
    height: 100%;
}

.contact-info__address {
    font-size: $font-size-small;
    font-style: italic;
    letter-spacing: 0.03rem;
}

.contact-info__link {
    letter-spacing: 0.03rem;
    color: $footer-background-color;
    font-size: $font-size-default;
    a {
        color: $footer-background-color;
        font-size: $font-size-default;
    }

    &--small {
        font-size: 0.85rem;
    }
}

.contact-info__link__icon {
    width: 1.5rem !important;
    height: 1.5rem !important;
    fill: $footer-background-color;
    color: $footer-background-color;
    &--ae {
        width: 2.2rem !important;
        height: auto !important ;
    }
}
</style>
