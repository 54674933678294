<template>
  <a class="btn methodology-link d-flex"
     :href="methodologyUrl"
     target="_blank">
    <font-awesome-icon class="methodology-link__icon mr-md-2"
                       :icon="['fal', 'draw-circle']" />
    <span class="d-none d-md-block">{{ $t('general.methodology')}}</span>
  </a>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { Constants } from '../../shared/Constants';

@Component({})
export default class MethodologyLink extends Vue {
    public readonly methodologyUrl = Constants.methodologyUrl;
}
</script>

<style lang="scss">
.methodology-link__icon {
    font-size: 1.33em;
}
</style>