<template>
    <div class="container info-modal custom-modal mx-auto my-auto modal-dialog-scrollable">
        <div class="position-relative modal-dialog-scrollable flex-grow-1"
             role="document">
            <div class="custom-modal-content modal-content d-flex flex-column px-lg-4 py-lg-3 px-xl-5">
                <div class="d-flex align-items-center mb-md-3 modal-header box-shadow-md-none box-shadow-bottom">
                    <div class="d-flex align-items-center">
                        <font-awesome-icon class="info-modal__header__icon info-modal__header__icon--big mr-2"
                                           icon="info-circle" />
                        <div class="d-flex align-items-baseline">
                            <span class="mr-3 info-modal__header__text">{{ infoModal && infoModal.modalTitle }}</span>
                            <SearchTypeIcon v-if="infoModal"
                                            class="info-modal__header__icon align-self-center"
                                            :searchType="infoModal && infoModal.searchType" />
                        </div>
                    </div>
                    <div class="ml-auto d-flex align-items-center">
                        <button class="btn d-flex align-items-center"
                                @click="hideInfoModal()">
                            <span class="mr-2 d-none d-md-block">{{ $t('info.close') }}</span>
                            <font-awesome-icon :icon="['far', 'times']" />
                        </button>
                    </div>
                </div>
                <div class="modal-body d-md-flex flex-column flex-md-row row-md"
                     :class="{'align-items-center':infoModal.searchType !== 'Label'}">
                    <VideoPlayer :videoSrc="infoModal && infoModal.videoUrl"
                                 class="col-md video-element" />
                    <div v-if="infoModal" class="col-md">
                        <p v-for="text in infoModal.explanationText">
                            {{ text }}
                        </p>
                        <div v-if="infoModal.searchType === 'Label'">
                            <p v-for="extraInfo in extraLabelsInfoText">
                                <b>{{ extraInfo.key }}</b>{{ extraInfo.text }}
                            </p>
                        </div>
                    </div>
                </div>
                <div class="pt-1 pt-md-4 box-shadow-top box-shadow-md-none w-100">
                    <div class="text-center">{{ $t('info.another_question') }}</div>
                    <div class="row p-3">
                        <div class="col d-flex justify-content-end align-items-center ">
                            <a target="_blank"
                               :href="faqUrl"
                               class="link text-underline d-flex align-items-center">
                                <font-awesome-icon class="mr-2 info-modal__link__icon"
                                                   icon="question-circle" />
                                <span class="info-modal__link">{{ $t('info.see_help') }}</span></a>
                        </div>
                        <div class="col"><a :href="`mailto:${contactAddress}`">
                            <button class="vgtc-button">
                                <font-awesome-icon class="vgtc-button__icon"
                                                   :icon="['fal','comment']" />
                                {{ $t('info.contact_us') }}
                            </button>
                        </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div @click="hideInfoModal()"
             class="custom-modal__container"></div>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Constants } from '../../../../../shared/Constants';
import { IIcon } from '../../../../../util/gui-decorators';
import { eventHub } from '../../../../EventHub.vue';
import VideoPlayer from '../../../../video/VideoPlayer.vue';
import SearchTypeIcon from '../../../SearchTypeIcon.vue';
import { IInfoModal } from './infomodals';

const displayModule = namespace('display');

@Component({ components: { SearchTypeIcon, VideoPlayer } })
export default class InfoModal extends Vue {
    @Prop() public titleText!: string;
    @Prop() public titleIcon!: IIcon;

    public faqUrl: string = Constants.faqUrl;

    public contactAddress: string = Constants.contactAddress;

    @displayModule.Getter('loadedInfoModal')
    public infoModal!: IInfoModal;

    @displayModule.Action('hideInfoModal')
    public hideInfoModal!: () => Promise<void>;

    private searchBarFocussedEventHandler!: () => void;

    public mounted(): void {
        this.searchBarFocussedEventHandler = () => {
            this.hideInfoModal();
        };
        eventHub.onAppEvent('searchBarFocussed', this.searchBarFocussedEventHandler);
    }

    public destroyed(): void {
        eventHub.unsubscribe('searchBarFocussed', this.searchBarFocussedEventHandler);
    }

    public get extraLabelsInfoText() {
        return [
            {
                key: this.$t('info.labels_explanation_key_offensive').toString(),
                text: this.$t('info.labels_explanation_text_offensive').toString(),
            },
            {
                key: this.$t('info.labels_explanation_key_negative').toString(),
                text: this.$t('info.labels_explanation_text_negative').toString(),
            },
            {
                key: this.$t('info.labels_explanation_key_neologism').toString(),
                text: this.$t('info.labels_explanation_text_neologism').toString(),
            },
            {
                key: this.$t('info.labels_explanation_key_outdated').toString(),
                text: this.$t('info.labels_explanation_text_outdated').toString(),
            },
            {
                key: this.$t('info.labels_explanation_key_explicit').toString(),
                text: this.$t('info.labels_explanation_text_explicit').toString(),
            },
        ];
    }
}
</script>

<style lang="scss">
.info-modal__button {
    border-radius: $default-border-radius;
    color: $accent-text-color;
    background-color: $accent-color;
    border: $thin-border-size solid $accent-shadow-color;
    box-shadow: $box-shadow;
    text-align: start;

    &:hover {
        color: $accent-text-color-hover;
        background-color: $accent-color-hover;
    }
}

.info-modal__button__comment {
    font-size: $font-size-big;
}

.info-modal__header__text {
    color: $text-title-color;
    font-size: $font-size-medium;
    @include media-breakpoint-up(md) {
        font-size: $font-size-big;
    }
}

.info-modal__header__icon {
    color: $brand-color;
    font-size: $font-size-medium;

    &--big {
        font-size: $font-size-very-big;
    }
}

.info-modal__link__icon {
    font-size: $font-size-big;
}

.info-modal__link {
    @include media-breakpoint-down(sm) {
        font-size: 0.95rem;
    }
}

.video-element {
    @include media-breakpoint-up(md) {
        position: sticky !important;
        top: 0;
        align-self: flex-start;
    }
}
</style>
