<template>
    <div class="d-flex flex-column">
        <div class="search-popover__card-container">
            <div class="search-popover__card"
                 :class="{ 'search-popover__card--selected': selected }"
                 @click="toggleSelect()">
                <div class="d-flex h-100 w-100"
                     v-if="hasFAicon()">
                    <font-awesome-icon class="m-auto" :class="{'search-popover__card--icon': !fontawesomeSize}"
                                       :size="fontawesomeSize"
                                       :icon="[fontawesomeVariant, fontawesomeIcon]" />
                </div>
                <div class="d-flex h-100 w-100"
                     v-else>
                    <SvgIcon class="search-popover__card--icon m-auto"
                             :icon="icon && icon.customSvg" />
                </div>
            </div>
        </div>
        <div v-if="displayText"
             class="mx-auto pt-1 search-popover__label"
             :class="{ 'search-popover__label--selected': selected, 'search-popover__label--small': labelSmall }">
            {{ displayText }}
        </div>
        <div v-if="infoText"
             class="mx-auto pt-1 search-popover__label search-popover__label--small"
             :class="{ 'search-popover__label--selected': selected}">
            {{ $t(infoText) }}
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { IIcon } from '../../../../../util/gui-decorators';
import SvgIcon from '../../../../SvgIcon.vue';

@Component({ components: { SvgIcon } })
export default class Tile extends Vue {
    @Prop() public selected!: boolean;
    @Prop() public icon!: IIcon;
    @Prop() public displayText!: string;
    @Prop() public infoText!: string;
    @Prop({ default: false }) public labelSmall!: boolean;

    /* tslint:disable:no-empty */
    @Emit('cardSelected')
    public toggleSelect(): void {
    }

    /* tslint:enable:no-empty */

    public hasFAicon(): boolean {
        return this.icon && this.icon.faIcon ? true : false;
    }

    get fontawesomeVariant(): string | undefined {
        return this.icon && this.icon.faIcon && this.icon.faIcon.iconVariant;
    }

    get fontawesomeIcon(): string | undefined {
        return this.icon && this.icon.faIcon && this.icon.faIcon.iconVariant && this.icon.faIcon.iconDescription;
    }

    get fontawesomeSize(): string | undefined {
        return this.icon && this.icon.faIcon && this.icon.faIcon.iconVariant && this.icon.faIcon.iconSize;
    }
}
</script>

<style lang="scss">
.search-popover__card-container {
    position: relative;
    padding-top: 100%;
    width: 100%;
}

.search-popover__card {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: $category-card-background-color;
    border: $card-border;
    box-shadow: $box-shadow-light;
    border-radius: $default-border-radius;
    color: $category-card-front-color;

    &--selected {
        background-color: $brand-color;
        fill: $card-selected-svg-color;
        color: $card-selected-svg-color;
    }

    &--icon {
        width: 60% !important;
        height: 60% !important;
    }

    @media (hover: hover) {
        &:hover {
            cursor: pointer;
            filter: brightness(90%);
        }
    }
}

.search-popover__label {
    text-align: center;

    &--selected {
        color: $brand-color;
    }

    &--small {
        font-size: $font-size-small;
    }
}
</style>
