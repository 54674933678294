<template>
  <Slide :isOpen="slideOpen"
         @closeMenu="onSlideClose"
         @openMenu="onSlideOpen"
         :burgerIcon="false"
         noOverlay
         class="vgtc-side-menu d-md-none"
         :class="{'vgtc-side-menu--open':slideOpen}">
    <div class="vgtc-side-menu__content d-flex flex-column justify-content-between h-100">
      <SearchOptionCardContainer :searchOptions="searchOptions"
                                 @activeOptionCardChanged="changeActiveOption" />
      <div class="d-flex flex-column">
        <div class="menu-item">
          <SignOut class="vgtc-side-menu__item mx-2" />
        </div>
        <div class="menu-item">
          <div @click="showFeedbackModal"
               class="vgtc-side-menu__item mx-3 d-flex align-items-center">
            <font-awesome-icon class=" ml-1 mr-3 text-large"
                               :icon="['fas','comment-smile']" />
            <span class="text-normal-small text-normal-small--wide">
              {{$t('general.feedback')}}
            </span>
          </div>
        </div>

        <div class="menu-item">
          <a class="vgtc-side-menu__item mx-3 d-flex align-items-center"
             :href="methodologyUrl"
             target="_blank">
            <font-awesome-icon class="vgtc-side-menu__help__icon ml-1 mr-3 text-large"
                               :icon="['fal', 'draw-circle']" />
            <span class="text-normal-small text-normal-small--wide">
              {{ $t('general.methodology') }}
            </span>
          </a>
        </div>

        <div class="menu-item">
          <a class="vgtc-side-menu__item mx-3 d-flex align-items-center"
             :href="faqUrl"
             target="_blank">
            <font-awesome-icon class="vgtc-side-menu__help__icon ml-1 mr-3 text-large"
                               :icon="['fas', 'question-circle']" />
            <span class="text-normal-small text-normal-small--wide">
              {{ $t('general.help') }}
            </span>
          </a>
        </div>
        <div class="menu-item">
          <router-link class="vgtc-side-menu__item mx-3 d-flex align-items-center"
                       :to="{name:'info'}">
            <font-awesome-icon class="ml-1 mr-3 text-large"
                               :icon="['fas','envelope']"></font-awesome-icon>
            <span class="text-normal-small text-normal-small--wide">
              {{ $t('general.contact_vgtc') }}
            </span>
          </router-link>
        </div>
      </div>
    </div>
  </Slide>
</template>
<script lang="ts">
import { Slide } from 'vue-burger-menu';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Action, namespace } from 'vuex-class';
import { Constants } from '../shared/Constants';
import FeedbackButton from './feedback/FeedbackButton.vue';
import SignOut from './general/SignOut.vue';
import SearchOptionCardContainer from './search/option/SearchOptionCardContainer.vue';
import { searchOptions } from './search/option/searchOptions/options';
import { ISearchOption } from './search/option/searchOptions/SearchOptionModel';
const searchModule = namespace('search');

@Component({ components: { FeedbackButton, SearchOptionCardContainer, SignOut, Slide } })
export default class OffCanvasMenu extends Vue {
    public readonly faqUrl = Constants.faqUrl;
    public readonly methodologyUrl = Constants.methodologyUrl;
    public searchOptions: ISearchOption[] = searchOptions;
    public slideOpen: boolean = false;

    @searchModule.Action('selectSearchOption')
    private selectSearchOption!: (searchOption: ISearchOption) => Promise<void>;

    public toggleSlideMenu() {
        this.slideOpen = !this.slideOpen;
    }

    public changeActiveOption(newActiveOption: ISearchOption) {
        this.selectSearchOption(newActiveOption);
    }

    public onSlideClose(): void {
        this.slideOpen = false;
    }

    public onSlideOpen(): void {
        this.slideOpen = true;
    }

    public showFeedbackModal(): void {
        this.$bvModal.show('feedbackModal');
    }
}
</script>
<style lang="scss">
.vgtc-side-menu__item,
.btn.vgtc-side-menu__item {
    color: $background-color;
    height: 3.5rem;
}

.btn.vgtc-side-menu__item {
    svg {
        font-size: $font-size-big;
    }
}
</style>
