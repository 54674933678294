export default {
    nl: {
        admin: {
            tab_sign_of_the_week: 'Gebaar van de week',
            tab_google_analytics: 'Google Analytics',
            tab_database: 'Database',
            admin_operations: 'Beheerpaneel',
            choose_sign_of_the_week_info: 'Wil je een willekeurig gebaar voor deze week kiezen? Klik dan op de onderstaande knop.',
            choose_sign_of_the_week_randomly: 'Kies willekeurig gebaar',
            choosing_new_sign_of_the_week: 'Nieuw willekeurig gebaar aan het kiezen',
            analytics: 'Enkele gebeurtenissen worden naar Google Analytics gestuurd om de prestaties en interacties op deze website te kunnen analyseren. Hieronder vind je een stappenplan voor het vinden van deze specifieke gebeurtenissen in Google Analytics.',
            analytics_go_to_dashboard: 'Ga naar het Google Analytics dashboard van dit project.',
            analytics_go_to_report: 'Klik in het linker menu op \'Rapporten\'.',
            analytics_go_to_events: 'Ga onder \'Levenscyclus\' naar \'Betrokkenheid\', en selecteer vervolgens \'Gebeurtenissen: naam gebeurtenis\'.',
            analytics_go_to_events_table: 'Hier zie je een overzicht van alle geregistreerde gebeurtenissen.',
            analytics_go_to_specific_event: 'Onderaan de pagina vind je een tabel met al deze gebeurtenissen. Klik op een specifieke gebeurtenis om meer details te bekijken.',
            analytics_go_to_specific_event_value: 'Op de detailpagina van een gebeurtenis kun je in het blok \'Event Value\' de meegezonden waarden vinden. Bijvoorbeeld, voor de gebeurtenis \'search had no results\' zijn dit de waarden.',
            warning: 'Deze pagina kan belangrijke gegevens wijzigen. Zorg ervoor dat je precies weet wat je doet voordat je wijzigingen aanbrengt, en controleer alle gegevens zorgvuldig achteraf.',
            init: 'Database initialisatie',
            init_info: 'Wanneer je op de knop \'Initialiseer Database\' klikt, wordt het systeem voorbereid door de databases te initialiseren. <br>Dit proces omvat het vullen van de databanken met categorieën, handvormen, labels, locaties, regio\'s en geslachten. <br>Bovendien worden de benodigde indexen aangemaakt om efficiënte toegang tot de gegevens te waarborgen.',
            init_database: 'Initialiseer database',
            init_ended: 'Initialisatie succesvol beëindigd',
            init_failed: 'Initialisatie gefaald. Fout:',
            last_sync: 'Laatste succesvolle synchronisatie',
            new_sign_chosen: 'Er werd een nieuw gebaar gekozen.',
            no_sign_yet: 'Nog geen gebaar gekozen',
            sign_of_the_week: 'Hier kun je het gebaar van de week voor de komende 4 weken bekijken en een willekeurig gebaar kiezen.',
            sign_of_week: 'Gebaar van week',
            something_went_wrong: 'Er liep iets mis tijdens het kiezen van het gebaar. Fout:',
            sync: 'Database synchronisatie',
            sync_info: 'Bij het klikken op de knop wordt de Signbank gesynchroniseerd. <br>Dit omvat het ophalen, opschonen van gegevens en het bijwerken van indexen.',
            sync_database: 'Synchroniseer Sign Database',
            sync_ended:
                'Synchronisatie geëindigd. Houd de vgtc@info.be mailbox in de gaten om te zien of de synchronisatie succesvol was.',
            sync_failed: 'Syncrhonisatie gefaald. Fout:',
            sync_started: 'Synchronisatie gestart',
        },
        cookie_banner: {
            agree: 'Ja, ik ga akkoord',
            privacy_policy: 'privacy policy',
            refuse: 'Nee, liever niet',
            text:
                'Om de gebruikservaring voor het Woordenboek Vlaamse Gebarentaal te verbeteren maakt deze website gebruik van cookies. Je kan ons helpen door akkoord te gaan met het gebruik van cookies. Meer informatie over de cookies die we gebruiken vind je in onze {policy}',
        },
        empty_state: {
            signbuddy: {
                help: 'Help jij mee onze nieuwe zoekfunctie te verbeteren?',
                action: 'Ik help mee',
            },
            find_other_sign: 'Op zoek naar een ander gebaar?',
            sign_ot_week: 'Gebaar van de week',
        },
        feedback: {
            about: 'Feedback over',
            application: 'de applicatie',
            // age_category: 'Leeftijdscategorie',
            cancel: 'Annuleren',
            choose_video: 'Geen video gekozen',
            // dont_know: 'Ik ken het gebaar niet',
            // dont_wish_to_share: 'Zeg ik liever niet',
            delete_video: 'Video verwijderen',
            delete_video_confirm: 'Ben je zeker dat je de video wilt verwijderen?',
            email_address_placeholder: 'E-mailadres',
            give_feedback: 'Geef feedback',
            give_feedback_of_sign: 'Geef feedback over dit gebaar',
            give_feedback_text: 'Geef mening in het Nederlands',
            give_feedback_vgt: 'Geef mening in VGT',
            give_problem_text: 'Meld in het Nederlands',
            give_problem_vgt: 'Meld in VGT',
            got_question: 'Heb je een vraag? Bekijk eerst onze {0} pagina',
            help_us_filling: 'Help ons om het woordenboek te verbeteren',
            // know_and_use: 'Ik ken dit gebaar en ik gebruik het',
            // know_but_dont_use: 'Ik ken dit gebaar maar gebruik het niet',
            // living_region: 'Woonplaats',
            invalid_email: 'Het e-mailadres is niet correct geformatteerd.',
            keep: 'Ok',
            more_info: 'Meer informatie',
            optional_email_address: 'Laat je e-mailadres achter (Optioneel)',
            optional_textual_problem: 'Specifieer het probleem (Optioneel)',
            other_feedback: 'Heb je nog andere opmerkingen? (Optioneel)',
            pick_a_problem: 'Selecteer een probleem dat je ondervindt (Optioneel)',
            pick_rating_or_type_feedback:
                'Kies een score en/of vul iets in, in het feedback tekstveld. Of neem een video op in VGT.',
            privacy_policy: 'privacy policy',
            privacy_statement: {
                feedback:
                    'Bij het geven van feedback ga je akkoord dat de informatie die bovenstaand gegeven wordt, mag gebruikt worden voor onderzoeksdoeleinden opdat mensen die gebarentaal (willen) gebruiken beter opgeleid kunnen worden. Meer informatie vind je in onze {0}.',
                problem:
                    'Bij het melden van een probleem ga je akkoord dat de informatie die bovenstaand gegeven wordt, mag gebruikt worden voor onderzoeksdoeleinden opdat mensen die gebarentaal (willen) gebruiken beter opgeleid kunnen worden. Meer informatie vind je in onze {0}.',
            },
            problem: {
                mistake_in_categories: 'De aangegeven categorieën van het gebaar kloppen niet',
                mistake_in_handshapes: 'De aangegeven handvormen van het gebaar kloppen niet',
                mistake_in_locations: 'De aangegeven locaties van het gebaar kloppen niet',
                mistake_in_variants: 'De aangegeven varianten van het gebaar kloppen niet',
                other_problem: 'Een ander probleem',
                select_one: 'Selecteer een probleem...',
                video_contains_error: 'De video van het gebaar bevat een fout',
                video_not_working: 'De video van het gebaar werkt niet',
                video_unclear: 'De video van het gebaar is onduidelijk',
            },
            problem_or_text_required:
                'Selecteer een probleem uit de lijst of specifieer het probleem dat je ondervindt in het vrije tekst veld of neem je probleem op in VGT.',
            redo: 'Opnieuw Opnemen',
            report_problem: 'Meld een probleem',
            select_video: 'Selecteer Video (max 80MB)',
            send: 'Versturen',
            // sex: 'Geslacht',
            something_went_wrong: 'Er ging iets mis tijdens het verzenden van de feedback. Probeer het nog eens.',
            something_wrong_with_sign: 'Zie je iets fout op deze pagina?',
            video_delete_success: 'De video werd verwijderd',
            video_does_not_exist: 'Deze video bestaat niet meer',
            what_do_you_think: 'Wat vind je van het nieuwe woordenboek van het Vlaamse Gebarentaal Centrum?',
            wrong_video_format: 'Het formaat van de upload wordt niet ondersteund.',
            // year: 'jaar',
        },
        general: {
            account_blocked: 'Het account is geblokkeerd. Neem contact op met de technische dienst van VGTc.',
            cancel: 'Annuleren',
            contact_vgtc: 'Contacteer VGTc',
            email: 'Email',
            error: 'Er is een fout gebeurd bij het inloggen. Neem contact op met de technische dienst van VGTc',
            faq: 'FAQ',
            feedback: 'Feedback',
            forgot_password: 'Wachtwoord vergeten?',
            help: 'Help',
            login: 'Inloggen',
            methodology: 'Methodologie',
            ok: 'OK',
            password: 'Wachtwoord',
            request_password_reset: 'Wachtwoord reset aanvragen',
            reset_mail_sent: 'Er werd een reset mail verstuurd naar {email}',
            signOut: 'Uitloggen',
            unkown_email: 'Het opgegeven e-mail adres is niet gekend',
            wrong_credentials: 'Het e-mail adres of wachtwoord is onjuist.',
        },
        gloss_detail: {
            back: 'terug naar zoeken',
            no_categories_yet: 'Er zijn geen categoriën voor dit gebaar.',
            no_labels_yet: 'Er zijn geen labels voor dit gebaar.',
            no_handshape_yet: 'Er is nog geen handvorm beschikbaar.',
            no_location_yet: 'Er is nog geen locatie beschikbaar.',
            no_regional_variants: 'Er zijn geen varianten voor dit gebaar.',
            no_similar_signs: 'Er zijn geen gelijkaardige gebaren voor dit gebaar.',
            no_subsigns: 'Dit gebaar heeft geen samenstellingen.',
            regional_variants: 'Regionale varianten',
            etymology: 'Etymologie',
            set_as_sign_of_week: 'Instellen als gebaar van week',
            set_as_sign_ot_week_error: 'Er is iets misgelopen bij het instellen van het gebaar van de week',
            set_as_sign_ot_week_success: 'Ingesteld als gebaar van de week',
            sign_composition: 'Samenstelling',
            similar_signs: 'Gelijkaardige gebaren',
            no_etymology_video: 'Er zijn geen etymologie video\'s voor dit gebaar.',
        },
        header: {
            vgtc_dutch: 'Woordenboek Vlaamse Gebarentaal',
            welcome: 'Welkom op het verklarend woordenboek',
        },
        info: {
            another_question: 'Nog een andere vraag?',
            categories: 'Categorieën',
            categories_explanation:
                'Elk gebaar wordt gelinkt aan één of meerdere categorieën, op basis van de betekenis van het gebaar. Met deze functie kan je dus alle gebaren vinden die in dezelfde categorie voorkomen. Je kan ook op meerdere categorieën tegelijk zoeken.',
            close: 'Sluiten',
            contact_us: 'Contacteer ons',
            handshapes: 'Handvormen',
            handshapes_explanation:
                'De handvorm is één van de parameters waaruit een gebaar bestaat. Als je je vingers bijvoorbeeld spreidt in plaats van sluit, kan je een heel ander gebaar krijgen. Met deze functie kan je zoeken op de handvormen van de gebaren in het woordenboek. Als je op een tekening klikt, zie je alle gebaren waarin deze handvorm voorkomt. Je kan ook op meerdere handvormen tegelijk zoeken.',
            labels: 'Labels',
            labels_explanation1: 'Vanaf nu hebben sommige gebaren in het woordenboek een label. Ze geven extra informatie over hoe een gebaar gebruikt wordt, bijvoorbeeld de gevoelswaarde van een gebaar, het register of de stijl waarin het gebruikt wordt of de houding van de gebaarder. Deze gebaren zijn niet fout, je kan en mag ze gebruiken, al is het wellicht niet in elke situatie gepast. Een gebaar kan ook meerdere labels hebben.',
            labels_explanation_key_offensive: 'BELEDIGEND: ',
            labels_explanation_text_offensive: 'gebaren die de gesprekspartner of de persoon naar wie het verwijst kunnen beledigen/kwetsen.',
            labels_explanation_key_negative: 'NEGATIEF: ',
            labels_explanation_text_negative: 'gebaren die een negatief gevoel of beeld kunnen oproepen.',
            labels_explanation_key_neologism: 'NEOLOGISME: ',
            labels_explanation_text_neologism: 'nieuwe gebaren, gebaren die nog niet lang bestaan, gebaren die (nog) niet wijdverspreid zijn.',
            labels_explanation_key_outdated: 'VEROUDERD: ',
            labels_explanation_text_outdated: 'gebaren die niet meer of niet meer zo vaak worden gebruikt.',
            labels_explanation_key_explicit: 'EXPLICIET: ',
            labels_explanation_text_explicit: 'gebaren die mogelijk aanstootgevend / schokkend kunnen zijn door hun visuele motivatie.',
            locations: 'Locaties',
            locations_explanation:
                'Een gebaar wordt altijd uitgevoerd op een welbepaalde locatie ten opzichte van het lichaam. Voorbeelden van locaties zijn het hoofd van de gebaarder, het bovenlichaam of de ruimte vóór zich. Met deze functie kan je zoeken op de locatie van de gebaren in het woordenboek. Als je op een tekening klikt, zie je alle gebaren waarin deze locatie voorkomt. Gebruik de zoomfunctie om nauwkeuriger de juiste locatie aan te geven. Je kan ook op meerdere locaties tegelijk zoeken.',
            more_info: 'Meer informatie over {about} of andere parameters vind op de {faqLink}.',
            regions: 'Regio\'s',
            regions_explanation:
                'In Vlaamse Gebarentaal bestaan verschillende varianten. Een dove persoon uit Brugge zal bijvoorbeeld een ander gebaar voor ‘suiker’ gebruiken dan een dove persoon uit Antwerpen. VGT heeft 5 gebarenvarianten, vergelijkbaar met dialecten in de gesproken Vlaamse taal. Toch kunnen dove mensen uit verschillende regio\'s elkaar goed begrijpen. De varianten vormen dus geen probleem in de communicatie tussen gebarentaligen.',
            see_help: 'Bekijk de help pagina',
            what_are_categories: 'Wat zijn categorieën?',
            what_are_handshapes: 'Wat zijn handvormen?',
            what_are_locations: 'Wat zijn locaties?',
            what_are_labels: 'Wat zijn labels?',
            why_search_on_regions: 'Waarom filteren op regio?',
        },
        search: {
            did_you_mean: 'Bedoelde je misschien:',
            filters: 'Zoekcriteria',
            new_search: 'nieuwe zoekopdracht',
            no_results: 'Sorry, geen resultaten gevonden',
            no_results_help: 'Je kan een {new_search} proberen of de {faq} bekijken',
            no_results_help_alternative: 'Je kan ook een {new_search} proberen of de {faq} bekijken',
            no_results_help_page: 'help pagina',
            partial_match_for: 'Gedeeltelijke overeenkomst voor',
            placeholder: 'Zoek op woorden, letters, cijfers of thema\'s en onderwerpen',
            placeholder_short: 'Zoek op woorden, letters of cijfers',
            placeholder_veryshort: 'Zoeken...',
            related_results: 'Verwante zoekresultaten | Verwant zoekresultaat | Verwante zoekresultaten',
            related_results_found: 'We vonden wel {nrOfResults} verwante resultaten die je {below} kan bekijken.',
            related_results_found_below: 'onderaan',
            removeAll: 'Verwijder zoektermen',
            results: 'Resultaten voor: | Resultaat voor: | Resultaten voor: ',
            search: 'Zoek',
            search_on: 'Zoek op',
            search_on_category: 'Categorie',
            search_on_combination: 'Zoek in combinaties',
            search_on_handshape: 'Handvorm',
            search_on_img: 'Afbeelding',
            search_on_label: 'Label',
            search_on_location: 'Locatie',
            search_on_region: 'Regio',
            select_a_region: 'Selecteer een regio',
            select_one_or_more_categories: 'Selecteer één of meerdere categorieën',
            select_one_or_more_handshapes: 'Selecteer één of meerdere handvormen',
            select_one_or_more_labels: 'Selecteer één of meerdere labels',
            select_one_or_more_locations: 'Selecteer één of meerdere locaties',
            show_detail: 'Toon detail',
        },
        video: {
            problem_reported: 'Bedankt voor je feedback! Het probleem is gemeld bij het VGTc.',
            report: 'melden',
            report_sign_video_error:
                'De video voor dit gebaar werd niet gevonden. Je kan dit probleem aan ons {action}.',
            video_error: 'Er is een probleem met deze video.',
        },
        sign_feedback: {
            yes: 'Ja',
            no: 'Nee',
            slightly: 'Een beetje',
            unsure: 'Weet ik niet',
            next_step: 'Volgende stap',
            cancel: 'Annuleren',
            phone_screen: 'Fijn dat je wou helpen. Deze webpagina werkt jammer genoeg niet op dit toestel. Bezoek ons bij voorkeur op een desktop of laptop.',
            error: {
                title: 'Fout!',
                something_wrong: ' Er ging iets mis, probeer aub opnieuw.',
                go_to_start: 'Als het probleem zich blijft voordoen, keer dan terug naar de',
                start_page: 'startpagina',
                send: 'Er ging iets mis bij het verwerken van je opname, probeer het opnieuw.',
                send_again: 'Opnieuw verzenden',
            },
            intro: {
                header_title: 'Welkom bij SignBuddy',
                explanation_sub_header_title: 'Wat is SignBuddy?',
                explanation_sub_header_text: 'SignBuddy is een computermodel dat gebaren herkent in video, door gebruik te maken van artificiële intelligentie (AI). </br> In de toekomst zal iedereen hiermee gebaren kunnen opzoeken in het online woordenboek VGT-Nederlands. </br>SignBuddy is nog in ontwikkeling en maakt nu nog te veel fouten. Jij kan helpen om dit te verbeteren!<b> Help jij SignBuddy om beter te worden?</b>',
                usage_sub_header_title: 'Hoe werkt het?',
                usage_sub_header_camera: '<b>Neem een gebaar op</b> met je camera en stuur het naar SignBuddy.',
                usage_sub_header_robot: 'SignBuddy probeert het gebaar te <b>herkennen</b>.',
                usage_sub_header_question: 'Geef SignBuddy wat <b>extra informatie</b> om gebaren beter te leren onderscheiden.',
                consent: 'In de video die je opneemt, worden in de beweging een aantal belangrijke punten van je armen, handen en gezicht bepaald. Deze punten worden door ons bewaard en zullen gebruikt worden om SignBuddy te verbeteren. De verwerking van je video gebeurt op je eigen toestel. VGTC en UGent krijgen deze video dus nooit te zien en deze wordt nergens bewaard. Op die manier is je bijdrage dus volledig anoniem. Een uitgebreide beschrijving van wat er met deze data gebeurt, vind je ',
                agree_next: 'Akkoord, ga door',
                logo1: 'Om SignBuddy te ontwikkelen, werkt het VGTC samen met de onderzoeksgroep IDLab van de Universiteit Gent.',
                logo2: 'Het project wordt gefinancierd door Amai Vlaanderen.',
            },
            step1: {
                header_title: 'Stap 1. Bekijk zorgvuldig dit gebaar',
                explanation: 'Bekijk het gebaar <b class="text-highlight">\'{sign}\'</b>. In de volgende stap gaan we je vragen om dit gebaar zelf uit te voeren en er een <b class="text-primary">opname</b> van te maken. Dat kan heel eenvoudig met je <b class="text-primary">webcam</b>.',
                not_watching: 'Geen zorgen, SignBuddy kijkt nog niet mee.',
                question_title: 'Voor we beginnen, een korte vraag',
                question_text: 'Ken jij gebarentaal?',
            }, step2: {
                header_title: 'Stap 2. Maak een opname van het gebaar',
                explanation_exercise: '<b class="text-primary">Oefen</b> het gebaar een paar keer indien nodig.',
                explanation_light: 'Zorg voor <b class="text-primary">voldoende licht</b> voor de beste beeldkwaliteit.',
                explanation_edge: 'Let goed op dat je gebaart <b class="text-primary">binnen de randen</b> van het scherm.',
                explanation_slow: 'Voer het gebaar <b class="text-primary">rustig en duidelijk</b> uit.',
                start_recording: 'Start opname',
                restart_recording: 'Opnieuw opnemen',
                send_recording: 'Verstuur opname',
                recording: 'Opnemen',
            }, step3: {
                header_title: 'Stap 3. Even geduld, we verwerken je opname',
                explanation_send: 'Je opname wordt verstuurd...',
                send: 'verzenden...',
                explanation_process: 'Je beelden worden verwerkt...',
                process: 'verwerken...',
                explanation_analyse: 'SignBuddy denkt na...',
                analyse: 'nadenken...',
            }, step4: {
                header_title: 'Stap 4. Klaar!',
                explanation_match: 'Gelukt. SignBuddy heeft het juiste gebaar gevonden in het woordenboek',
                explanation_no_match: 'Jammer. SignBuddy heeft het gebaar niet teruggevonden',
                explanation_results: 'Hieronder zie je de {amount} gebaren die er volgens SignBuddy het meest op lijken:',
                question: 'Mogen we jou nog 4 vragen stellen om SignBuddy te laten bijleren?',
                yes_ask: 'Ja, vraag maar',
                no_thanks: 'Nee, liever niet',
            }, step5: {
                header_title: 'Heb jij tips voor SignBuddy?',
                subtitle: 'We hebben een paar vragen over het volgende gebaar',
                sign: 'Signbuddy vond dat dit <b>gebaar</b> leek op het gebaar dat je toonde',
                explanation_match: 'Dit was <b>het juiste gebaar</b>, maar we willen graag begrijpen waarom de matching score eerder laag was.',
                explanation_no_match: 'Dit was <b>niet het juiste gebaar</b>, maar we willen graag begrijpen waarom SignBuddy dit gebaar koos.',
                question_handshape: 'Was de <b>handvorm</b> van één of beide handen juist?',
                question_location: 'Was de <b>locatie</b> van één of beide handen juist?',
                question_move: 'Was de <b>beweging</b> van één of beide handen juist?',
                question_mouth: 'Was het <b>mondbeeld</b> van het gebaar juist?',
                send: 'Antwoorden verzenden',
            }, step6: {
                header_title: 'Bedankt voor je hulp!',
                subtitle: 'Dankzij jouw hulp kunnen we SignBuddy verder trainen',
                explanation1: 'Geweldig, door jouw hulp kan SignBuddy bijleren!',
                explanation2: 'Hoe meer voorbeelden, hoe beter. Wil je nog meer helpen? Klik dan zeker op de knop hieronder!',
                question_another_sign: 'SignBuddy is er klaar voor, wil je nog een gebaar proberen?',
                another_sign: 'Nog een gebaar',
                logos: 'De ontwikkeling van SignBuddy is mogelijk dankzij:',
            },
        },
    },
};
