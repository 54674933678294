import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { sendPasswordResetEmail, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { auth } from '@/main';
import User = firebase.User;

class AuthService {
    public signIn(email: string, password: string) {
        return signInWithEmailAndPassword(auth, email, password);
    }

    public signOut(): Promise<void> {
        return signOut(auth);
    }

    public requestResetPassword(email: string): Promise<void> {
        return sendPasswordResetEmail(auth, email);
    }

    public resetPassword(actionCode: string, password: string): Promise<void> {
        return firebase.auth().confirmPasswordReset(actionCode, password);
    }

    public validateResetLink(actionCode: string): Promise<string> {
        return firebase.auth().verifyPasswordResetCode(actionCode);
    }

    public getCurrentUser(): Promise<firebase.User | null> {
        return new Promise<firebase.User | null>(resolve => {
            const unsubscribe = auth.onAuthStateChanged((user: User | null) => {
                if (user && user.uid) {
                    resolve(user);
                } else {
                    resolve(null);
                }
                unsubscribe();
            });
        });
    }
}

export const authService = new AuthService();
