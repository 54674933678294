<template>
    <div class="container vgtc-card mt-md-5 py-4 px-0 d-flex flex-column mb-5">
        <h3 class="my-4 px-4">{{ $t('admin.admin_operations') }}</h3>
        <b-tabs
            active-nav-item-class="font-weight-bold text-uppercase "
            class="mt-4" content-class="mt-5 px-4" justified>
            <b-tab :title="$t('admin.tab_sign_of_the_week')" active>
                <div>
                    <p>{{ $t('admin.sign_of_the_week') }}</p>
                    <div v-if="loadingSigns" class="text-center mt-5 pt-4">
                        <b-spinner class="text-highlight"></b-spinner>
                    </div>
                    <SignOfTheWeek v-else class="mb-3"
                                   v-for="(signOfTheWeek, index) in signsOfTheWeek"
                                   :key="index"
                                   @new-sign="(sign) => signOfTheWeek.sign = sign"
                                   :sign="signOfTheWeek.sign"
                                   :week="signOfTheWeek.week" />
                </div>
            </b-tab>
            <b-tab :title="$t('admin.tab_google_analytics')">
                <p>{{ $t('admin.analytics') }}</p>
                <div class="mt-5 row">
                    <div class="step-cell">1</div>
                    <div class="col-11">
                        <p>{{ $t('admin.analytics_go_to_dashboard') }}</p>
                        <a
                            :href="analyticsUrl"
                            target="_blank"
                            class="ml-2 text-bold">
                            <SvgIcon icon="ic_analytics"></SvgIcon>
                            {{ $t('admin.tab_google_analytics') }}
                        </a>
                    </div>
                </div>
                <div class="row mt-5 pt-4">
                    <div class="step-cell">2</div>
                    <div class="col-11 row">
                        <div class="col-6">
                            <p>{{ $t('admin.analytics_go_to_report') }}</p>
                            <img
                                src="../../public/img/GA_rapporten.png">
                        </div>
                        <div class="col-6">
                            <p>{{ $t('admin.analytics_go_to_events') }}</p>
                            <img
                                src="../../public/img/GA_gebeurtenissen.png">
                        </div>
                    </div>
                </div>
                <div class="mt-5 row">
                    <div class="step-cell">3</div>
                    <div class="col-11">
                        <p>{{ $t('admin.analytics_go_to_events_table') }}</p>
                        <p>{{ $t('admin.analytics_go_to_specific_event') }}</p>
                        <img class="w-100"
                             src="../../public/img/GA_no_search_result.png">
                    </div>
                </div>
                <div class="mt-5 row">
                    <div class="step-cell">4</div>
                    <div class="col-11">
                        <p>{{ $t('admin.analytics_go_to_specific_event_value') }}</p>
                        <img
                            src="../../public/img/GA_event_value.png">
                    </div>
                </div>
            </b-tab>
            <b-tab :title="$t('admin.tab_database')">
                <div class="mb-4">
                    <b-alert
                        variant="info"
                        show>
                        <div class="d-flex align-items-center">
                            <font-awesome-icon :icon="['far','square-info']" size="xl" class="mr-3"></font-awesome-icon>
                            {{ $t('admin.warning') }}
                        </div>
                    </b-alert>
                    <div class="pt-2 mb-3">
                        <h3>{{ $t('admin.init') }}</h3>
                        <div class="d-flex justify-content-between">
                            <div>
                                <p v-html="$t('admin.init_info')"></p>
                            </div>
                            <div class="ml-3">
                                <button @click="triggerInitialize()"
                                        class="vgtc-button mt-2">{{ $t('admin.init_database') }}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="mb-3">
                        <h3>{{ $t('admin.sync') }}</h3>
                        <div class="d-flex justify-content-between">
                            <div>
                                <p v-html="$t('admin.sync_info')"></p>
                                <span class="font-italic">{{ $t('admin.last_sync') }}: {{ lastSyncExecutionTime
                                    }}</span>
                            </div>
                            <div class="ml-3">
                                <button @click="triggerSync()"
                                        class="vgtc-button mt-2">{{ $t('admin.sync_database') }}
                                </button>
                            </div>
                        </div>
                    </div>

                </div>
            </b-tab>
        </b-tabs>


        <div class="d-flex justify-content-center mt-2 m-3">
            <b-alert v-if="error"
                     variant="danger"
                     show>{{ error }}
            </b-alert>
        </div>
        <div class="d-flex justify-content-center mt-2 m-3">
            <b-alert v-if="info"
                     variant="info"
                     show>{{ info }}
            </b-alert>
        </div>
    </div>
</template>

<script lang="ts">
import { AxiosResponse } from 'axios';
import { Component, Vue } from 'vue-property-decorator';
import { ISignOverview } from '../../../functions/src/shared/contracts/api/GlossContract';
import { ISyncExecution } from '../../../functions/src/shared/contracts/SyncExecutionContract';
import SignOfTheWeek from '../components/admin/SignOfTheWeek.vue';
import { Constants } from '../shared/Constants';
import { adminService } from '../shared/services/AdminService';
import { signService } from '../shared/services/SignService';
import { axiosHttp } from '../util/AxiosHttp';
import { formatDateTime } from '../util/util-functions';
import SvgIcon from '@/components/SvgIcon.vue';

interface ISignOfTheWeek {
    sign: ISignOverview | null;
    week: Date;
}

@Component({ components: { SignOfTheWeek, SvgIcon } })
export default class Admin extends Vue {
    public info: string | null = null;
    public error: string | null = null;
    public loadingSigns: boolean = false;

    public signsOfTheWeek: ISignOfTheWeek[] = [];
    public signOfTheWeek: ISignOverview | null = null;
    public lastSync: ISyncExecution | null = null;

    public analyticsUrl: string = Constants.analyticsUrl;

    public async created(): Promise<void> {
        this.loadingSigns = true;
        [this.signsOfTheWeek] = await Promise.all([this.getSignsOfTheWeek(), this.setLastSync()]);
        this.loadingSigns = false;
    }

    public get lastSyncExecutionTime(): string | null {
        return this.lastSync && formatDateTime(new Date(this.lastSync.finishedOn as number));
    }

    public async triggerSync(): Promise<void> {
        this.info = this.$t('admin.sync_started').toString();
        try {
            this.error = '';
            const response: AxiosResponse = await axiosHttp.http.post(`${Constants.baseOperationsUrl}/sync`);
            await this.setLastSync();
            this.info = this.$t('admin.sync_ended').toString();
        } catch (error) {
            this.info = '';
            this.error = `${this.$t('admin.sync_failed')} ${error}`;
        }
    }

    public async triggerInitialize(): Promise<void> {
        this.info = this.$t('admin.init_started').toString();
        try {
            this.error = '';
            const response: AxiosResponse = await axiosHttp.http.post(`${Constants.baseOperationsUrl}/initialize`);
            this.info = this.$t('admin.init_ended').toString();
        } catch (error) {
            this.info = '';
            this.error = `${this.$t('admin.init_failed')} ${error}`;
        }
    }

    private async getSignsOfTheWeek(): Promise<ISignOfTheWeek[]> {
        return await Promise.all(
            signService.getSignOfTheWeekWeeks().map(async w => {
                const sign = await signService.getSignOfTheWeek(w);
                return {
                    sign,
                    week: w,
                } as ISignOfTheWeek;
            }),
        );
    }

    private async setLastSync(): Promise<void> {
        this.lastSync = await adminService.getLastSync();
    }
}
</script>

<style lang="scss">
.step-cell {
    background-color: $accent-color;
    color: white;
    width: 65px;
    height: 45px;
    font-size: 20px;
    font-weight: bold;
    border-radius: 0.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 15px;
}
</style>
