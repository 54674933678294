<template>
    <div>
        <div class="mt-4">
            <div class="d-flex align-items-center mb-3">
                <div>
                    <font-awesome-icon class="mr-2 color-brand" size="lg"
                                       :icon="icon" />
                </div>
                <div class="sign-feedback__sub-header__text "
                     v-html="$t(question)"></div>
            </div>

            <b-form-group v-slot="{ ariaDescribedby }">
                <b-form-radio-group
                    :checked="value"
                    :aria-describedby="ariaDescribedby"
                    :name="icon.join('')">
                    <div class="d-flex justify-content-around">
                        <div v-for="option in options"
                             :class="{'sign-feedback__tile--selected':value === option.value}"
                             class="sign-feedback__tile d-flex flex-column align-items-center justify-content-around py-3"
                             @click="answerChanged(option.value)">
                            <b-form-radio :value="option.value" class="m-0 ml-2"></b-form-radio>
                            <span class="text-bold">
                                         <SvgIcon class="option-icon mr-n1 ml-n2"
                                                  :icon="option.icon" />
                                                                            {{ $t(option.label) }}</span>
                        </div>
                    </div>
                </b-form-radio-group>
            </b-form-group>

        </div>

    </div>

</template>
<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import SvgIcon from '@/components/SvgIcon.vue';

@Component({ components: { SvgIcon } })
export default class FeedbackQuestionBlock extends Vue {

    @Prop() public value!: string;
    @Prop() question!: string;
    @Prop() icon!: string[];

    @Emit('input')
    public answerChanged(answer: string): string {
        return answer;
    }

    public options = [
        { label: 'sign_feedback.yes', value: 'yes', icon: 'ja' },
        { label: 'sign_feedback.slightly', value: 'slightly', icon: 'eenbeetje' },
        { label: 'sign_feedback.no', value: 'no', icon: 'nee' },
        { label: 'sign_feedback.unsure', value: 'unsure', icon: 'weetniet' }];
}
</script>

<style lang="scss" scoped>

.option-icon {
    height: 30px;
}
</style>

