import router from '@/router';
import { authService } from '@/shared/services/AuthService';
import { cookieService } from '@/shared/services/CookieService';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { IRootState, IUserState } from './contract';
import firebase from 'firebase/compat';

const userState: IUserState = {
    cookieConsent: null,
    userInfo: null,
};

const mutations: MutationTree<IUserState> = {
    setConsent(state: IUserState, payload: boolean) {
        state.cookieConsent = payload;
    },
    userSignedIn(state: IUserState, payload: firebase.User) {
        state.userInfo = payload;
    },
    userSignedOut(state: IUserState) {
        state.userInfo = null;
    },
};

const actions: ActionTree<IUserState, IRootState> = {
    async signIn({ commit, rootState }, payload: { email: string; password: string }): Promise<void> {
        const userCredential = await authService.signIn(payload.email, payload.password);
        if (userCredential.user) {
            await commit('userSignedIn', userCredential.user);
        }
    },
    async signOut({ commit, rootState }): Promise<void> {
        await authService.signOut();
        await commit('userSignedOut');
        if (router && router.currentRoute && router.currentRoute.meta && router.currentRoute.meta.requiresAuth) {
            router.push('/');
        }
    },
    async setUserFromCurrent({ commit, rootState }): Promise<void> {
        const userInfo = await authService.getCurrentUser();
        if (userInfo) {
            await commit('userSignedIn', userInfo);
        } else {
            await commit('userSignedOut');
        }
    },
    async acceptCookies({ commit, rootState }): Promise<void> {
        cookieService.acceptCookies();
        await commit('setConsent', true);
    },
    async refuseCookies({ commit, rootState }): Promise<void> {
        cookieService.refuseCookies();
        await commit('setConsent', false);
    },
    async initializeCookies({ commit, rootState }): Promise<void> {
        const cookieConsent = cookieService.hasConsentCookie() ? cookieService.hasConsentedToCookies() : null;
        await commit('setConsent', cookieConsent);
    }
};

const getters: GetterTree<IUserState, IRootState> = {
    hasConsentCookie(state): boolean {
        return state.cookieConsent != null;
    },
    hasConsentedToCookies(state): boolean {
        return !!state.cookieConsent;
    },
    isSignedIn(state): boolean {
        return !!state.userInfo;
    },
    user(state): firebase.User | null {
        return state.userInfo;
    },
};

export const user: Module<IUserState, IRootState> = {
    actions,
    getters,
    mutations,
    namespaced: true,
    state: userState,
};
