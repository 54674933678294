<template>
  <div class="sign-detail-card">
    <div class="d-flex mb-2 sign-detail-card__title align-items-center">
      <SearchTypeIcon class="mr-2"
                      :searchType="searchType" />
      <span>{{tileTitle}}</span>
      <button class="sign-detail-card__info-icon color-accent ml-auto"
              @click="onInfoButtonClicked()">
        <font-awesome-icon icon="info-circle" />
      </button>
    </div>
    <slot></slot>
    <slot name="empty-state"></slot>
  </div>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { SearchType } from '../search/SearchType';
import SearchTypeIcon from '../search/SearchTypeIcon.vue';

@Component({ components: { SearchTypeIcon } })
export default class SignDetailCard extends Vue {
    @Prop() public searchType!: SearchType;
    @Prop() public tileTitle!: string;

    @Emit('infoClicked')
    public onInfoButtonClicked(): void {
        return;
    }
}
</script>

<style lang="scss">
.sign-detail-card__title {
    font-size: $font-size-medium;
}

.sign-detail-card__info-icon {
    background-color: transparent;
    border: none;
    font-size: $font-size-big;
}
</style>