<template>
    <div class="">
        <div class="sign-feedback__sub-header__text ">
            {{ $t('sign_feedback.intro.explanation_sub_header_title') }}
        </div>
        <div class="row align-items-start pt-4">
            <div class="col-7" v-html="$t('sign_feedback.intro.explanation_sub_header_text')">
            </div>
            <div class="col-5 mt-n5 mb-n2">
                <VideoPlayer videoSrc="/video/WatIsSignBuddy.mp4" :show-download-button="false" />
            </div>
        </div>
        <div class="sign-feedback__sub-header__text ">{{ $t('sign_feedback.intro.usage_sub_header_title')
            }}
        </div>
        <div class="row align-items-start pt-4">
            <div class="col-7">
                <div class="d-flex pr-4">
                    <div class="d-flex pr-5 justify-content-center align-items-center">
                        <SignBuddy class="icon-sign-buddy text-primary ml-3"></SignBuddy>
                    </div>
                    <div>
                        <div class="row mb-3">
                                        <span
                                            class="col-10 font-italic"
                                            v-html="$t('sign_feedback.intro.usage_sub_header_camera')"></span>
                            <span class="col-2">
                                            <font-awesome-icon class="mr-2" size="xl"
                                                               :icon="['far', 'video']" />
                                        </span>
                        </div>
                        <div class="row mb-3">
                                        <span class="col-10 font-italic"
                                              v-html="$t('sign_feedback.intro.usage_sub_header_robot')"></span>
                            <span class="col-2">
                                              <SignBuddy class="icon-sign-buddy--small"></SignBuddy>
                                        </span>
                        </div>
                        <div class="row">
                                        <span class="col-10 font-italic"
                                              v-html="$t('sign_feedback.intro.usage_sub_header_question')"></span>
                            <span class="col-2">
                                            <font-awesome-icon class="ml-1" size="xl"
                                                               :icon="['far', 'square-info']" />
                                        </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-5 d-flex justify-center flex-column align-items-center">
                <div class="sign-feedback__consent__text mb-4">
                    {{ $t('sign_feedback.intro.consent') }}
                    <a href="https://vlaamsegebarentaal.be/signbuddy"
                       target="_blank">hier</a>.
                </div>
                <div>
                    <Button type="button" label="sign_feedback.intro.agree_next" :icon="['fas', 'thumbs-up']"
                            @click="agreeAndNext" :disabled="loading" :loading="loading"
                    ></Button>
                </div>
            </div>
        </div>
        <div class="row mt-5 pt-4 text-normal-small">
            <div class="col-12">
                {{ $t('sign_feedback.intro.logo1') }}
            </div>
            <div class="col-12">
                {{ $t('sign_feedback.intro.logo2') }}
            </div>
        </div>
        <div class="row mt-1 d-flex logo-box justify-content-around align-items-center">
            <img class="logo-box-vgtc"
                 src="../../../public/img/Logo_VGTc.png">
            <img class="logo-box-ugent"
                 src="../../../public/img/Logo_UGent.png">
            <img class="logo-box-amai"
                 src="../../../public/img/Logo_Amai.png">
        </div>
    </div>

</template>
<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import Button from '@/components/general/Button.vue';
import SignBuddy from '@/assets/svg/SignBuddy.svg';
import VideoPlayer from '@/components/video/VideoPlayer.vue';

@Component({ components: { VideoPlayer, Button, SignBuddy } })
export default class Introduction extends Vue {

    @Prop() loading!: boolean;

    @Emit('nextStep')
    public agreeAndNext() {
        return;
    }
}
</script>

<style lang="scss" scoped>
.sign-feedback {
    &__sub-header__text {
        font-size: 1.25rem;
        color: $text-title-color;
    }

    &__consent__text {
        font-size: 0.75rem;
    }
}

.logo-box {
    &-vgtc {
        height: 190px;
    }

    &-ugent {
        height: 170px;
    }

    &-amai {
        height: 110px;
    }
}

.icon-sign-buddy {
    height: 150px;

    &--small {
        height: 45px;
        margin-left: -4px;
        margin-top: -5px;
    }
}
</style>

