<template>
  <b-modal id="feedbackModal">
    <template v-slot:modal-title>
      <div class="d-flex align-items-center">
        <font-awesome-icon class="fa-2x color-brand mr-2"
                           :icon="['fas','comment-smile']" />
        <span class="color-brand modal-title">{{$t('feedback.give_feedback')}}</span>
      </div>
    </template>
    <i18n path="feedback.got_question">
      <a :href="faqUrl"
         target="_blank"
         class="pl-1">
        <font-awesome-icon class="faq-link__icon"
                           :icon="['fas', 'question-circle']" />
        <span class=" font-weight-bold"> {{ $t('general.help')}} </span>
      </a>
    </i18n>
    <br><br>
    {{$t('feedback.what_do_you_think')}}

    <StarRating v-model="rating"
                class="justify-content-center mt-3 mb-5" />
    <VideoTextInput v-model="feedback"
                    @focus="error=null"
                    class="mt-2 w-100 mx-auto">
      <template v-slot:text>{{$t('feedback.give_feedback_text')}}</template>
      <template v-slot:video>{{$t('feedback.give_feedback_vgt')}}</template>
    </VideoTextInput>
    <span>{{$t('feedback.optional_email_address')}}</span>
    <b-form-input v-model="email"
                  type="email"
                  class="mt-2 mb-4"
                  @focus="error=null"
                  :placeholder="$t('feedback.email_address_placeholder')" />
    <b-alert class="d-flex justify-content-center mt-2"
             variant="danger"
             :show="error">
      {{error}}
    </b-alert>
    <i18n class="font-italic feedback-modal__privacy-policy"
          path="feedback.privacy_statement.feedback"><a target="_blank"
         :href="privacyPolicyUrl">{{$t('feedback.privacy_policy')}}</a>
    </i18n>
    <template v-slot:modal-footer>
      <div :class="{'blurred-unclickable' : isSendingFeedback}"
           class="d-flex">
        <button @click="sendFeedback()"
                class="vgtc-button mr-4">
          <font-awesome-icon class="vgtc-button__icon"
                             :icon="['fal','paper-plane']" />
          {{$t('feedback.send')}}
        </button>
        <button @click="hideModal()"
                class="vgtc-button vgtc-button--invert">
          {{$t('feedback.cancel')}}
        </button>
      </div>
      <b-spinner class="position-absolute m-auto send-feedback-spinner"
                 v-if="isSendingFeedback"></b-spinner>
    </template>
  </b-modal>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { Constants } from '../../shared/Constants';
import { doorbellService } from '../../shared/services/DoorbellService';
import { feedbackService } from '../../shared/services/FeedbackService';
import { axiosHttp } from '../../util/AxiosHttp';
import StarRating from './StarRating.vue';
import VideoTextInput from './VideoTextInput.vue';

@Component({ components: { StarRating, VideoTextInput } })
export default class FeedbackModal extends Vue {
    public readonly faqUrl = Constants.faqUrl;
    public readonly privacyPolicyUrl = Constants.privacyPolicyUrl;

    public feedback: {
        textualFeedback?: string;
        feedbackVideo?: File | Blob;
        textInputActive: boolean;
        videoInputActive: boolean;
    } = { textInputActive: true, videoInputActive: false };

    public email: string = '';
    public error: string | null = null;

    public rating: number = 0;

    public isSendingFeedback: boolean = false;

    public get canSendFeedback(): boolean {
        return (
            this.feedback &&
            ((this.feedback.textualFeedback !== null &&
                this.feedback.textualFeedback !== undefined &&
                this.feedback.textualFeedback !== '' &&
                this.feedback.textInputActive) ||
                this.rating > 0 ||
                (this.feedback.feedbackVideo !== undefined &&
                    this.feedback.feedbackVideo !== null &&
                    this.feedback.videoInputActive))
        );
    }

    public showModal(): void {
        this.$bvModal.show('feedbackModal');
    }

    public hideModal(): void {
        this.error = null;
        this.$bvModal.hide('feedbackModal');
    }

    public async sendFeedback(): Promise<void> {
        if (!this.canSendFeedback) {
            this.error = this.$t('feedback.pick_rating_or_type_feedback').toString();
            return;
        }
        try {
            this.isSendingFeedback = true;
            await feedbackService.giveGeneralFeedback(
                this.feedback.textInputActive ? this.feedback.textualFeedback : undefined,
                this.feedback.videoInputActive ? this.feedback.feedbackVideo : undefined,
                (this.feedback.videoInputActive &&
                    this.feedback.feedbackVideo &&
                    (this.feedback.feedbackVideo as File).name) ||
                    undefined,
                this.email,
                this.rating,
            );
            this.hideModal();
            this.clearModal();
        } catch (err) {
            if (err === doorbellService.INVALID_EMAIL_ERROR) {
                this.error = this.$t('feedback.invalid_email').toString();
            } else if ((err as Error).message === 'Request failed with status code 400') {
                this.error = this.$t('feedback.wrong_video_format').toString();
            } else {
                this.error = this.$t('feedback.something_went_wrong').toString();
            }
        } finally {
            this.isSendingFeedback = false;
        }
    }

    @Watch('rating')
    private clearError(): void {
        this.error = null;
    }

    private clearModal(): void {
        this.email = '';
        this.rating = 0;
        this.feedback = { textInputActive: true, videoInputActive: false };
        this.error = null;
    }
}
</script>

<style lang="scss">
.feedback-modal__privacy-policy {
    font-size: $font-size-small;
}

.send-feedback-spinner {
    color: $accent-color;
    left: 0;
    right: 0;
}
</style>