<template>
  <b-modal id="reportProblemModal">
    <template v-slot:modal-title>
      <div class="d-flex align-items-center">
        <font-awesome-icon class="fa-2x color-brand mr-2"
                           :icon="['fas','comment-minus']" />
        <span class="color-brand modal-title">{{$t('feedback.report_problem')}}</span>
      </div>
    </template>
    <template>
      {{$t('feedback.pick_a_problem')}}
      <b-form-select class="mb-4 mt-2"
                     v-model="selectedProblem"
                     :options="problemOptions"
                     @input="error=null" />
      <VideoTextInput v-model="feedback"
                      @focus="error=null"
                      class="mt-2 w-100 mx-auto">
        <template v-slot:text>{{$t('feedback.give_problem_text')}}</template>
        <template v-slot:video>{{$t('feedback.give_problem_vgt')}}</template>
      </VideoTextInput>
      <span>{{$t('feedback.optional_email_address')}}</span>
      <b-form-input v-model="email"
                    type="email"
                    class="mb-4 mt-2"
                    :placeholder="$t('feedback.email_address_placeholder')"
                    @focus="error=null" />
      <b-alert class="d-flex justify-content-center"
               variant="danger"
               :show="error">
        {{error}}
      </b-alert>
      <i18n class="font-italic feedback-modal__privacy-policy"
            path="feedback.privacy_statement.problem"><a target="_blank"
           :href="privacyPolicyUrl">{{$t('feedback.privacy_policy')}}</a>
      </i18n>
    </template>
    <template v-slot:modal-footer>
      <div :class="{'blurred-unclickable' : isSendingFeedback}"
           class="d-flex">
        <button @click="sendFeedback()"
                class="vgtc-button mr-4">
          <font-awesome-icon class="vgtc-button__icon"
                             :icon="['fal','paper-plane']" />
          {{$t('feedback.send')}}
        </button>
        <button @click="hideModal()"
                class="vgtc-button vgtc-button--invert">
          {{$t('feedback.cancel')}}
        </button>
      </div>
      <b-spinner class="position-absolute m-auto send-feedback-spinner"
                 v-if="isSendingFeedback"></b-spinner>
    </template>
  </b-modal>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ISignDetail, ISignOverview } from '../../../../functions/src/shared/contracts/api/GlossContract';
import { Constants } from '../../shared/Constants';
import { doorbellService } from '../../shared/services/DoorbellService';
import { feedbackService } from '../../shared/services/FeedbackService';
import VideoTextInput from './VideoTextInput.vue';

@Component({ components: { VideoTextInput } })
export default class ReportProblemModal extends Vue {
    @Prop()
    public sign!: ISignDetail | ISignOverview;

    public readonly faqUrl = Constants.faqUrl;
    public readonly privacyPolicyUrl = Constants.privacyPolicyUrl;
    public error: string | null = null;
    public email: string = '';
    public selectedProblem: number | null = null;

    public feedback: {
        textualFeedback?: string;
        feedbackVideo?: File | Blob;
        textInputActive: boolean;
        videoInputActive: boolean;
    } = { textInputActive: true, videoInputActive: false };
    private isSendingFeedback: boolean = false;

    public get problemOptions(): Array<{ text: string; value: number | null }> {
        return [
            { text: this.$t('feedback.problem.select_one').toString(), value: null },
            { text: this.$t('feedback.problem.video_contains_error').toString(), value: 1 },
            { text: this.$t('feedback.problem.mistake_in_handshapes').toString(), value: 2 },
            { text: this.$t('feedback.problem.mistake_in_locations').toString(), value: 3 },
            { text: this.$t('feedback.problem.mistake_in_categories').toString(), value: 4 },
            { text: this.$t('feedback.problem.mistake_in_variants').toString(), value: 5 },
            { text: this.$t('feedback.problem.video_unclear').toString(), value: 6 },
            { text: this.$t('feedback.problem.video_not_working').toString(), value: 6 },
            { text: this.$t('feedback.problem.other_problem').toString(), value: 8 },
        ];
    }

    public hideModal(): void {
        this.$bvModal.hide('reportProblemModal');
        this.error = null;
    }

    public async sendFeedback(): Promise<void> {
        try {
            this.isSendingFeedback = true;
            this.error = null;
            if (!this.canSendFeedback) {
                this.error = this.$t('feedback.problem_or_text_required').toString();
                return;
            }
            const selectedProblemText = this.getSelectedProblemText();
            await feedbackService.giveFeedbackOnSign(
                this.sign,
                this.feedback.textInputActive ? this.feedback.textualFeedback : undefined,
                this.feedback.videoInputActive ? this.feedback.feedbackVideo : undefined,
                (this.feedback.videoInputActive &&
                    this.feedback.feedbackVideo &&
                    (this.feedback.feedbackVideo as File).name) ||
                    undefined,
                this.getSelectedProblemText(),
                this.email,
            );
            this.hideModal();
            this.clearModal();
        } catch (err) {
            if (err === doorbellService.INVALID_EMAIL_ERROR) {
                this.error = this.$t('feedback.invalid_email').toString();
            } else if ((err as Error).message === 'Request failed with status code 400') {
                this.error = this.$t('feedback.wrong_video_format').toString();
            } else {
                this.error = this.$t('feedback.something_went_wrong').toString();
            }
        } finally {
            this.isSendingFeedback = false;
        }
    }

    private get canSendFeedback(): boolean {
        return (
            this.feedback &&
            ((this.selectedProblem !== null && this.selectedProblem !== undefined) ||
                (this.feedback.textualFeedback !== null &&
                    this.feedback.textualFeedback !== undefined &&
                    this.feedback.textualFeedback !== '' &&
                    this.feedback.textInputActive) ||
                (this.feedback.feedbackVideo !== undefined &&
                    this.feedback.feedbackVideo !== null &&
                    this.feedback.videoInputActive))
        );
    }

    private clearModal(): void {
        this.error = null;
        this.email = '';
        this.selectedProblem = null;
        this.feedback = { textInputActive: true, videoInputActive: false };
    }

    private getSelectedProblemText(): string | undefined {
        if (!this.selectedProblem) {
            return undefined;
        }
        return this.problemOptions[this.selectedProblem].text;
    }
}
</script>

<style lang="scss">
</style>