import i18n from '@/init/i18n';
import { SearchType } from '../../../SearchType';

export function getInfoModal(searchType: SearchType): IInfoModal {
    switch (searchType) {
        case SearchType.Category:
            return categoryModal;
        case SearchType.Handshape:
            return handshapeModal;
        case SearchType.Location:
            return locationModal;
        case SearchType.Region:
            return regionModal;
        case SearchType.Label:
            return labelModal;
        default:
            return categoryModal;
    }
}

export interface IInfoModal {
    explanationText: string[];
    modalTitle: string;
    searchType: SearchType;
    videoUrl: string;
}

const categoryModal: IInfoModal = {
    explanationText: [i18n.t('info.categories_explanation').toString()],
    modalTitle: i18n.t('info.categories').toString(),
    searchType: SearchType.Category,
    videoUrl: '/video/categorie_help.mp4',
};

const labelModal: IInfoModal = {
    explanationText: [i18n.t('info.labels_explanation1').toString()],
    modalTitle: i18n.t('info.labels').toString(),
    searchType: SearchType.Label,
    videoUrl: '/video/label_help.mp4',
};

const handshapeModal: IInfoModal = {
    explanationText: [i18n.t('info.handshapes_explanation').toString()],
    modalTitle: i18n.t('info.handshapes').toString(),
    searchType: SearchType.Handshape,
    videoUrl: '/video/handvorm_help.mp4',
};

const locationModal: IInfoModal = {
    explanationText: [i18n.t('info.locations_explanation').toString()],
    modalTitle: i18n.t('info.locations').toString(),
    searchType: SearchType.Location,
    videoUrl: '/video/locatie_help.mp4',
};

const regionModal: IInfoModal = {
    explanationText: [i18n.t('info.regions_explanation').toString()],
    modalTitle: i18n.t('info.regions').toString(),
    searchType: SearchType.Region,
    videoUrl: '/video/regio_help.mp4',
};
