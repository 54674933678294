<template>
  <div class="search-option-card px-3 px-md-0 d-flex align-items-center justify-content-around flex-grow-1"
       :class="{ 'feature-inactive': searchOption.featureInactive, 'search-option-card--active': isSelected }"
       @click="onClick">
    <SearchTypeIcon class="ml-1 mr-3 mr-md-1 text-large search-option-card__icon"
                    :searchType="searchOption.searchType" />
    <span class="text-normal-small text-normal-small--wide">{{ $t(searchOption.optionName) }}
      <AmountIndicator class="search-option-card__amount ml-1"
                       type="accent"
                       size="small"
                       v-if="nrSelected"
                       :amount="nrSelected" /></span>
    <div class="ml-1 mr-1 d-none d-md-block"
         :class="{'search-option-card__active-indicator--active__arrow':isSelected, 'search-option-card__active-indicator--inactive__arrow':!isSelected}">
      <font-awesome-icon icon="chevron-right" />
    </div>
    <div class="ml-1 mr-1 icon-dark icon-bolder d-md-none ml-auto">
      <font-awesome-icon :icon="['far', 'search']" />
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import AmountIndicator from '../../AmountIndicator.vue';
import SearchTypeIcon from '../SearchTypeIcon.vue';
import { ISearchOption } from './searchOptions/SearchOptionModel';

@Component({ components: { AmountIndicator, SearchTypeIcon } })
export default class SearchOptionCard extends Vue {
    @Prop()
    public nrSelected!: number;

    @Prop()
    public searchOption!: ISearchOption;

    @Prop()
    public isSelected!: boolean;

    @Emit('onClick')
    public onClick(): ISearchOption {
        return this.searchOption;
    }
}
</script>

<style lang="scss">
.search-option-card {
    // TODO: find cleaner way todo css for mobile
    overflow: hidden;
    white-space: nowrap;
    background-color: transparent;
    color: $background-color;
    height: 3.5rem;

    @include media-breakpoint-up(md) {
        border: $card-border;
        color: $card-text-color;
        background-color: $card-background-color;
        box-shadow: 0.05rem 0.05rem 0.25rem 0.05rem rgba(54, 79, 140, 0.25);
        border-radius: $default-border-radius;
        &:hover {
            box-shadow: $box-shadow-hover;
        }
    }
    &:hover {
        cursor: pointer;
    }

    &--active {
        background-color: $side-menu-selected-color;
        @include media-breakpoint-up(md) {
            box-shadow: none;
            color: $brand-color;
            background-color: $card-background-color;
            border-bottom: 0.15rem solid $brand-color;
        }
        &:hover {
            box-shadow: none;
            cursor: default;
        }
    }
}

.search-option-card__icon {
    font-size: $font-size-medium;
    height: 1.5rem;
}

.search-option-card__amount {
    position: absolute;
    top: 0.5rem;
}

.search-option-card__active-indicator {
    &--active__arrow {
        transform: rotate(90deg);
        transition-duration: 0.3s;
    }
    &--inactive__arrow {
        transition-duration: 0.3s;
    }
}
</style>


