import { AxiosResponse } from 'axios';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';

import { axiosHttp } from '@/util/AxiosHttp';

import router from '@/router';
import { tagService } from '@/shared/services/TagService';
import { ISignOverview, ISignOverviewsWithTotal } from '../../../functions/src/shared/contracts/api/GlossContract';
import { SearchMode } from '../../../functions/src/shared/contracts/SearchMode';
import { ISearchOption } from '../components/search/option/searchOptions/SearchOptionModel';
import { SearchType } from '../components/search/SearchType';
import { ITag, tagsEqual } from '../components/search/TagModel';
import { Constants } from '../shared/Constants';
import { eventService } from '../shared/services/EventService';
import { IRootState, ISearchState } from './contract';

const searchState: ISearchState = {
    newSearchOngoing: false,
    relatedSearchResults: [],
    searchCombined: false,
    searchFailed: false,
    searchFailedReason: '',
    searchResults: [],
    searchTags: [],
    selectedSearchOption: undefined,
    totalRelatedSearchResultsAvailable: 0,
    totalSearchResultsAvailable: 0,
    userIsSearchingOnText: false,
};

const mutations: MutationTree<ISearchState> = {
    switchSearchCombined(state, isSearchCombined: boolean) {
        state.searchCombined = isSearchCombined;
    },
    searchCompleted(state, newState: ISearchState) {
        state.newSearchOngoing = false;
        state.relatedSearchResults = newState.relatedSearchResults;
        state.searchCombined = state.searchCombined;
        state.searchResults = newState.searchResults;
        state.searchTags = newState.searchTags;
        state.searchFailed = newState.searchFailed;
        state.searchFailedReason = newState.searchFailedReason;
        state.totalSearchResultsAvailable = newState.totalSearchResultsAvailable;
        state.totalRelatedSearchResultsAvailable = newState.totalRelatedSearchResultsAvailable;
        state.userIsSearchingOnText = false;
    },
    searchStarted(state, isNewSearch: boolean) {
        state.newSearchOngoing = isNewSearch;
    },

    tagsUpdated(state, newTags: ITag[]) {
        state.searchTags = newTags;
    },

    searchFailed(state, err: string) {
        // Way of keeping old search results but still know whether the new search failed.
        state.searchFailed = true;
        state.searchFailedReason = err;
        state.newSearchOngoing = false;
    },

    searchOptionChanged(state, newSearchOption: ISearchOption) {
        state.selectedSearchOption = newSearchOption;
    },

    userTextSearchingStateChanged(state, userIsSearching: boolean) {
        state.userIsSearchingOnText = userIsSearching;
    },
};

const actions: ActionTree<ISearchState, IRootState> = {
    async clear({ commit, state }): Promise<void> {
        const newState: ISearchState = {
            newSearchOngoing: false,
            relatedSearchResults: [],
            searchCombined: false,
            searchFailed: false,
            searchFailedReason: '',
            searchResults: [],
            searchTags: [],
            selectedSearchOption: undefined,
            totalRelatedSearchResultsAvailable: 0,
            totalSearchResultsAvailable: 0,
            userIsSearchingOnText: false,
        };
        await commit('searchCompleted', newState);
    },
    async clearSearchResults({ commit, state }) {
        const newState: ISearchState = {
            newSearchOngoing: false,
            relatedSearchResults: [],
            searchCombined: false,
            searchFailed: false,
            searchFailedReason: '',
            searchResults: [],
            searchTags: [],
            selectedSearchOption: state.selectedSearchOption,
            totalRelatedSearchResultsAvailable: 0,
            totalSearchResultsAvailable: 0,
            userIsSearchingOnText: false,
        };
        await commit('searchCompleted', newState);
    },
    async switchSearchCombined({ commit, state }, payload: { combined: Boolean }) : Promise<void> {
        await commit('switchSearchCombined', payload.combined)
    },
    async updateTags({ commit, state }, payload: { tagsToAdd: ITag[]; tagsToRemove: ITag[]; replace: Boolean }): Promise<void> {
        let tags = state.searchTags;
        if (payload.replace) {
            while (tags.length > 0) {
                tags.pop()
            }
        }
        tags = tags.filter(tag => !payload.tagsToRemove.some(rTag => tagsEqual(rTag, tag)));
        const filteredTagsToAdd = payload.tagsToAdd.filter(aTag => !tags.some(t => tagsEqual(aTag, t)));
        tags.push(...filteredTagsToAdd);
        eventService.trackAddTags(payload.tagsToAdd);
        await commit('tagsUpdated', tags);
        this.dispatch('search/searchSigns', { tags, isNewSearch: true });
    },

    async removeTag({ commit, state }, tag: ITag): Promise<void> {
        const tags = state.searchTags;
        const newTags = tags.filter(t => !tagsEqual(t, tag));
        await commit('tagsUpdated', newTags);
        if (newTags.length > 0) {
            this.dispatch('search/searchSigns', { tags: newTags, isNewSearch: true });
        } else {
            this.dispatch('search/clearSearchResults');
        }
    },
    async searchSigns({ commit, state }, payload: { tags: ITag[]; isNewSearch: boolean }): Promise<void> {
        await commit('searchStarted', payload.isNewSearch);
        const tags: ITag[] = payload.tags;
        const newSearch: boolean = payload.isNewSearch;
        const from = newSearch ? 0 : state.searchResults.length;
        try {
            const { signOverviews, totalNumberSignOverviews } = await searchSigns(tags, from, SearchMode.ANDExact);
            if (signOverviews.length > 0) {
                const newState: ISearchState = {
                    newSearchOngoing: payload.isNewSearch,
                    relatedSearchResults: [],
                    searchCombined: state.searchCombined,
                    searchFailed: false,
                    searchFailedReason: '',
                    searchResults: newSearch ? signOverviews : [...state.searchResults, ...signOverviews],
                    searchTags: tags,
                    selectedSearchOption: undefined,
                    totalRelatedSearchResultsAvailable: 0,
                    totalSearchResultsAvailable: newSearch
                        ? totalNumberSignOverviews
                        : state.totalSearchResultsAvailable,
                    userIsSearchingOnText: false,
                };
                await commit('searchCompleted', newState);
            } else if (payload.isNewSearch) {
                const label = payload.tags
                    .map(t => `${t.searchType}=${t.value}`)
                    .sort((t1, t2) => (t1 > t2 ? 1 : t2 < t1 ? -1 : 0))
                    .join(';');
                eventService.track(eventService.Events.SEARCH_NO_RESULTS, label);
                await this.dispatch('search/searchRelatedSigns', { isNewSearch: true });
            }
        } catch (err) {
            await commit('searchFailed', err);
            throw err;
        }
    },
    async searchRelatedSigns({ commit, state }, payload: { isNewSearch: boolean }): Promise<void> {
        await commit('searchStarted', payload.isNewSearch);
        const tags: ITag[] = state.searchTags;
        const newSearch: boolean = payload.isNewSearch;
        const from = newSearch ? 0 : state.relatedSearchResults.length;
        const searchMode = SearchMode.ORFuzzy;
        try {
            const { signOverviews, totalNumberSignOverviews } = await searchSigns(tags, from, searchMode);
            const newState: ISearchState = {
                newSearchOngoing: payload.isNewSearch,
                relatedSearchResults: newSearch ? signOverviews : [...state.relatedSearchResults, ...signOverviews],
                searchCombined: state.searchCombined,
                searchFailed: false,
                searchFailedReason: '',
                searchResults: [],
                searchTags: tags,
                selectedSearchOption: undefined,
                totalRelatedSearchResultsAvailable: newSearch
                    ? totalNumberSignOverviews
                    : state.totalRelatedSearchResultsAvailable,
                totalSearchResultsAvailable: 0,
                userIsSearchingOnText: false,
            };
            await commit('searchCompleted', newState);
        } catch (err) {
            await commit('searchFailed', err);
            throw err;
        }
    },
    async loadMoreSearchResults({ commit, state }): Promise<void> {
        const oldTags: ITag[] = state.searchTags;
        if (state.relatedSearchResults.length > 0) {
            await this.dispatch('search/searchRelatedSigns', { isNewSearch: false });
        } else {
            await this.dispatch('search/searchSigns', { tags: oldTags, isNewSearch: false });
        }
    },
    async selectSearchOption({ commit, state }, newSearchOption: ISearchOption) {
        if (
            state.selectedSearchOption &&
            newSearchOption &&
            state.selectedSearchOption.optionName === newSearchOption.optionName
        ) {
            await this.dispatch('search/clearSearchOptionSelection');
            return;
        }
        await commit('searchOptionChanged', newSearchOption);
    },
    async clearSearchOptionSelection({ commit, state }) {
        await commit('searchOptionChanged', undefined);
    },
    async initiateTextSearch({ commit, state }) {
        await commit('userTextSearchingStateChanged', true);
    },
    async stopTextSearch({ commit, state }) {
        if (!state.selectedSearchOption) {
            commit('userTextSearchingStateChanged', false);
        }
    },
};

function getTagQuery(tags: ITag[], type: SearchType): string {
    return JSON.stringify(tags.filter(tag => tag.searchType === type).map(tag => tag.value));
}

async function searchSigns(tags: ITag[], from: number, mode: SearchMode): Promise<ISignOverviewsWithTotal> {
    if (!tags || tags.length === 0) {
        return {
            signOverviews: [],
            totalNumberSignOverviews: 0,
        };
    }
    const categoryTagQuery = getTagQuery(tags, SearchType.Category);
    const handshapeTagQuery = getTagQuery(tags, SearchType.Handshape);
    const locationTagQuery = getTagQuery(tags, SearchType.Location);
    const glossTagQuery = getTagQuery(tags, SearchType.Gloss);
    const textTagQuery = getTagQuery(tags, SearchType.Text);
    const regionTagQuery = getTagQuery(tags, SearchType.Region);
    const labelTagQuery = getTagQuery(tags, SearchType.Label);

    try {
        const response: AxiosResponse<ISignOverviewsWithTotal> = await axiosHttp.http.get(
            `${Constants.baseApiUrl}/signs`,
            {
                params: {
                    c: categoryTagQuery,
                    from,
                    g: glossTagQuery,
                    h: handshapeTagQuery,
                    l: locationTagQuery,
                    lb:labelTagQuery,
                    mode,
                    q: textTagQuery,
                    r: regionTagQuery,
                    size: 12,
                },
            },
        );
        return response.data;
    } catch (err) {
        throw err;
    }
}

const getters: GetterTree<ISearchState, IRootState> = {
    searchCombined(state): Boolean {
        return state.searchCombined;
    },
    currentSearchResults(state): ISignOverview[] {
        return state.searchResults;
    },
    relatedSearchResults(state): ISignOverview[] {
        return state.relatedSearchResults;
    },
    searchTags(state): ITag[] {
        return state.searchTags;
    },
    selectedSearchOption(state): ISearchOption | undefined {
        return state.selectedSearchOption;
    },
    totalSearchResultsAvailable(state): number {
        return state.totalSearchResultsAvailable;
    },
    totalRelatedSearchResultsAvailable(state): number {
        return state.totalRelatedSearchResultsAvailable;
    },
    newSearchOngoing(state): boolean {
        return state.newSearchOngoing;
    },
    userIsSearching(state): boolean {
        return state.userIsSearchingOnText || !!state.selectedSearchOption || state.searchTags.length > 0;
    },
};

const namespaced: boolean = true;

export const search: Module<ISearchState, IRootState> = {
    actions,
    getters,
    mutations,
    namespaced,
    state: searchState,
};
