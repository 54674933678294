<template>
    <div>
        <div class="mobile-space d-lg-none position-relative d-flex flex-column"
             :class="{'mobile-space--hide': hideMobileHeader}">
            <div class="d-none d-md-flex justify-content-end">
                <FeedbackButton class="mobile-space__link my-2" />
                <MethodologyLink class="mobile-space__link m-2" />
                <FAQLink class="mobile-space__link m-2" />
                <SignOut class="mobile-space__link m-2" />
            </div>
            <div class="position-absolute mobile-space__text align-self-center">{{ $t('header.vgtc_dutch') }}</div>
        </div>

        <Hero></Hero>
        <div class="container pt-3 pt-md-4 pb-5">
            <SearchOptionContainer class="pt-1" />
            <EmptyState v-if="!hideEmptyState"
                        class="mt-4 mx-0"></EmptyState>
            <SearchResults v-if="searchTags.length"
                           :nrOfSearchResults="totalSearchResultsAvailable"
                           :nrOfRelatedSearchResult="totalRelatedSearchResultsAvailable"
                           :searchResults="searchResults" />
            <RelatedSearchResults id="relatedResults"
                                  v-if="showRelatedResults"
                                  :searchResults="relatedSearchResults"
                                  :totalNrOfSearchResults="totalRelatedSearchResultsAvailable"
                                  :searchTags="searchTags"
                                  class="mt-5" />
        </div>
        <infinite-loading class="pt-5 m-auto"
                          @infinite="infiniteHandler"
                          v-if="!allResultsLoaded"
                          :distance="300"
                          direction="bottom"
                          spinner="waveDots"
                          :ref="infiniteLoaderRef" />
    </div>
</template>
<script lang="ts">
import InfiniteLoading, { StateChanger } from 'vue-infinite-loading';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Dictionary } from 'vue-router/types/router';
import { namespace } from 'vuex-class';
import { ISignOverview } from '../../../functions/src/shared/contracts/api/GlossContract';
import EmptyState from '../components/brand/EmptyState.vue';
import VGTCLogo from '../components/brand/VGTCLogo.vue';
import FeedbackButton from '../components/feedback/FeedbackButton.vue';
import FAQLink from '../components/general/FAQLink.vue';
import MethodologyLink from '../components/general/MethodologyLink.vue';
import SignOut from '../components/general/SignOut.vue';
import Hero from '../components/search/Hero.vue';
import SearchOptionContainer from '../components/search/option/SearchOptionContainer.vue';
import RelatedSearchResults from '../components/search/RelatedSearchResults.vue';
import SearchBar from '../components/search/SearchBar.vue';
import SearchResults from '../components/search/SearchResults.vue';
import { ITag } from '@/components/search/TagModel';
import { tagService } from '@/shared/services/TagService';

const displayModule = namespace('display');
const searchModule = namespace('search');
const cacheModule = namespace('cache');

@Component({
    components: {
        EmptyState,
        FAQLink,
        FeedbackButton,
        Hero,
        InfiniteLoading,
        MethodologyLink,
        RelatedSearchResults,
        SearchBar,
        SearchOptionContainer,
        SearchResults,
        SignOut,
        VGTCLogo,
    },
})
export default class Search extends Vue {
    @searchModule.Getter
    public searchCombined!: Boolean;

    @searchModule.Getter
    public searchTags!: ITag[];

    private infiniteLoaderRef: string = 'infiniteLoader';

    @searchModule.Getter private totalSearchResultsAvailable!: number;

    @searchModule.Getter private totalRelatedSearchResultsAvailable!: number;

    @searchModule.Getter private currentSearchResults!: ISignOverview[];

    @searchModule.Getter private relatedSearchResults!: ISignOverview[];

    @searchModule.Getter private userIsSearching!: boolean;

    @searchModule.Getter private newSearchOngoing!: boolean;

    private hideableSearchResults: ISignOverview[] = [];

    @searchModule.Action
    private loadMoreSearchResults!: () => Promise<void>;

    @searchModule.Action
    private clear!: () => Promise<void>;

    @displayModule.Action
    private showFooter!: () => Promise<void>;

    @displayModule.Action
    private hideFooter!: () => Promise<void>;

    @cacheModule.Action
    private preloadData!: () => Promise<void>;

    @searchModule.Action
    private updateTags!: ({
                              tagsToAdd,
                              tagsToRemove,
                              replace,
                          }: { tagsToAdd: ITag[]; tagsToRemove: ITag[]; replace: Boolean }) => Promise<void>;

    public async mounted(): Promise<void> {
        await this.preloadData();
        await this.initSearchTags();
    }

    public async infiniteHandler($state: StateChanger) {
        this.loadMoreSearchResults();
    }

    public destroyed(): void {
        this.showFooter();
    }

    public created(): void {
        window.scrollTo(0, 0);
    }

    @Watch('$route.query')
    public queryChanged(): void {
        this.initSearchTags();
    }

    private async initSearchTags(): Promise<void> {
        const tagsToAdd: ITag[] = await tagService.mapQueryParametersToTags(this.$route.query as Dictionary<string>);
        if (tagsToAdd) {
            this.clear();
            this.updateTags({ tagsToAdd, tagsToRemove: [], replace: !this.searchCombined });
        }
    }

    /**
     * Make searchresults hideable so that unloadable videos can be hidden. The hideable results are a solution for this but with the
     * infinite loading, the behavior is not optimal. TODO: find a better way.
     * After searchresults are loaded: update the infiniteloading component.
     * The state of the infiniteloader must be set after the store is updated because it will
     * cause an infinite loop of events otherwise. TODO: review this
     */
    @Watch('allResults')
    private onChangeofSearchResults(): void {
        const infiniteLoader = this.$refs[this.infiniteLoaderRef] as InfiniteLoading;
        this.allResultsLoaded ? this.showFooter() : this.hideFooter();
        if (infiniteLoader) {
            if (this.allResultsLoaded) {
                infiniteLoader.stateChanger.complete();
            } else if (!this.searchResults || this.searchResults.length <= 0) {
                infiniteLoader.stateChanger.reset();
            } else {
                infiniteLoader.stateChanger.loaded();
            }
        }
    }

    @Watch('searchTags')
    private updateSearchPath(): void {
        const queryParams = tagService.mapTagsToQueryParams(this.searchTags);
        this.$router.push({ path: 'search', query: queryParams }).catch(() => {
        });
    }

    get searchResults(): ISignOverview[] {
        return this.currentSearchResults;
    }

    get allResultsLoaded(): boolean {
        return (
            this.allResults &&
            (this.allResults.length <= 0 ||
                this.allResults.length >= this.totalSearchResultsAvailable + this.totalRelatedSearchResultsAvailable)
        );
    }

    get searchResultsAvailable(): boolean {
        return this.totalSearchResultsAvailable > 0;
    }

    get hideMobileHeader(): boolean {
        return (
            this.userIsSearching ||
            (this.currentSearchResults && this.currentSearchResults.length > 0) ||
            (this.relatedSearchResults && this.relatedSearchResults.length > 0)
        );
    }

    get showRelatedResults(): boolean {
        return (
            this.totalRelatedSearchResultsAvailable > 0 &&
            !this.searchResultsAvailable &&
            (this.searchTags && this.searchTags.length > 0) &&
            !this.newSearchOngoing
        );
    }

    get hideEmptyState(): boolean {
        return this.searchResultsAvailable || (this.searchTags && this.searchTags.length > 0);
    }

    private get allResults(): ISignOverview[] {
        return [...this.currentSearchResults, ...this.relatedSearchResults];
    }
}
</script>

<style lang="scss">
.mobile-space {
    background-color: $brand-color;
    //background-image: linear-gradient(to bottom, $brand-color-light, $brand-color);
    background-image: url('../../public/img/vgtc-banner-bright.png');
    background-image: url('../../public/img/vgtc-banner-bright.png'), linear-gradient(to bottom, $brand-color-light, $brand-color);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    height: $navbar-mobile-hero-height;
    transition: 0.3s;

    @include media-breakpoint-down(sm) {
        &--hide {
            margin-top: calc(#{$navbar-mobile-hero-height} * -1);
            transition: 0.5s;
        }
    }
}

.mobile-space__text {
    white-space: nowrap;
    //top: $navbar-mobile-hero-height - 2.5rem;
    bottom: 0.25rem;
    font-size: calc(0.35rem + 3vw);
    color: $header-text-color;
    letter-spacing: 0.04rem;
    font-family: $font-family-accent;
}

.mobile-space__link,
.btn.mobile-space__link {
    color: $header-text-color;
    font-size: $header-text-size;

    &:hover {
        background-color: $brand-color-dark;
        border-radius: $default-border-radius;
        color: $header-text-color;
        text-decoration: none;
    }
}
</style>

