class CookieService {
    private readonly cookieVersion = '1';
    private readonly cookieConsentKey = 'vgtc-cookie-consent';

    public acceptCookies(): void {
        this.setCookieConsent(true, '1y');
    }

    public refuseCookies(): void {
        this.setCookieConsent(false, '1m');
    }

    public hasConsentedToCookies(): boolean {
        const consent = window.$cookies.get(this.cookieConsentKey) as ICookieConsent;
        return consent && consent.accepted && consent.version === this.cookieVersion;
    }

    public hasConsentCookie(): boolean {
        return window.$cookies.isKey(this.cookieConsentKey);
    }

    private setCookieConsent(accepted: boolean, lifetime: string): void {
        const consent: ICookieConsent = {
            accepted,
            version: this.cookieVersion,
        };
        window.$cookies.set(this.cookieConsentKey, consent, lifetime);
    }
}

interface ICookieConsent {
    accepted: boolean;
    version: string;
}

export const cookieService = new CookieService();
