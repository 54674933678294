import { SearchType } from './SearchType';

export interface ITag {
    text: string;
    searchType: SearchType;
    value: string;
}

export function tagsEqual(tag1: ITag, tag2: ITag): boolean {
    return tag1.text === tag2.text && tag1.searchType === tag2.searchType && tag1.value === tag2.value;
}

export function CategoryTag(tagText: string, tagValue: string): ITag {
    return {
        searchType: SearchType.Category,
        text: tagText,
        value: tagValue,
    };
}

export function HandshapeTag(tagText: string): ITag {
    return {
        searchType: SearchType.Handshape,
        text: tagText.replace(';', ' en '),
        value: tagText,
    };
}

export function LabelTag(tagText: string, tagValue: string): ITag {
    return {
        searchType: SearchType.Label,
        text: tagText,
        value: tagValue,
    };
}

export function LocationTag(tagText: string, tagValue: string): ITag {
    return {
        searchType: SearchType.Location,
        text: tagText,
        value: tagValue,
    };
}

export function GlossTag(tagText: string, tagValue: string): ITag {
    return {
        searchType: SearchType.Gloss,
        text: tagText,
        value: tagValue,
    };
}

export function TextTag(tagText: string): ITag {
    return {
        searchType: SearchType.Text,
        text: tagText,
        value: tagText,
    };
}

export function RegionTag(tagText: string, tagValue: string): ITag {
    return {
        searchType: SearchType.Region,
        text: tagText,
        value: tagValue,
    };
}
