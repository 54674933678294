<template>
  <div class="sign-list p-3">
    <div class="sign-video-list__header mt-1 mb-3 ml-3">
      <slot></slot>
    </div>
    <div class="divider divider--light"></div>
    <div class="sign-video-list__videos align-items-baseline row mt-n4 px-3">
      <Loading :active="loading"
               class="position-relative mx-auto mt-5"
               :is-full-page="false"
               backgroundColor="transparent" />
      <SearchResultCard class="col-12 col-md-6 col-xl-4 mt-5"
                        v-for="(result) in signs"
                        :key="result.signId"
                        :searchResult="result" />
      <div v-if="!loading && (!signs || signs.length <= 0)"
           class="mt-4 d-flex flex-grow-1">
        <slot name="empty-state"></slot>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Loading from 'vue-loading-overlay';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ISignOverview } from '../../../../functions/src/shared/contracts/api/GlossContract';
import SearchResultCard from '../search/SearchResultCard.vue';

@Component({ components: { Loading, SearchResultCard } })
export default class SignList extends Vue {
    @Prop() public signs!: ISignOverview[];
    @Prop({ default: false }) public loading!: boolean;
}
</script>

<style lang="scss">
.sign-list {
    border: $card-border;
    border-radius: $default-border-radius;
    box-shadow: 1px 2px 2px 0 rgba(62, 62, 64, 0.1);
}
</style>