<template>
    <div>
        <div class="d-flex text-white justify-content-end mb-1 mr-2">
            <span class="mr-2" v-on:click="switchSearchCombinedAction">{{ $t('search.search_on_combination') }}</span>
            <b-form-checkbox v-model="searchCombinedCheck" name="check-button" switch />
        </div>
        <div class="vgtc-searchbar align-self-stretch d-flex">
            <div class="d-none d-sm-block">
                <div class="vgtc-searchbar__input-prefix d-flex justify-content-center">
                    <span class="align-self-center">T</span>
                </div>
            </div>
            <vue-typeahead-bootstrap class="flex-grow-1"
                                     :showAllResults="true"
                                     v-model="searchText"
                                     :data="suggestions"
                                     :placeholder="$t(searchPlaceholder)"
                                     :serializer="s => s.text"
                                     @hit="searchOnTag($event)"
                                     ref="typeahead"
                                     inputClass="vgtc-searchbar__input"
                                     :minMatchingChars="1">
                <template #suggestion="{ data }">
                    <div class="d-flex align-items-center vgtc-searchbar__input__suggestion">
            <span v-if="data.category"
                  class="vgtc-searchbar__input__suggestion__icon">
              <font-awesome-icon :icon="getIcon(data.category)" />
            </span>
                        <span
                            class="vgtc-searchbar__input__suggestion__icon vgtc-searchbar__input__suggestion__icon--no-category"
                            v-else>T</span>
                        <span class="flex-grow-1 vgtc-searchbar__input__suggestion__text">{{ data.text }}</span>
                    </div>
                </template>
            </vue-typeahead-bootstrap>
            <div class="vgtc-searchbar__input-suffix d-flex justify-content-center"
                 @click="searchOnText()">
                <font-awesome-icon class="align-self-center"
                                   :icon="['far', 'search']" />
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import axios from 'axios';
import debounce from 'lodash/debounce';
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { SuggestionCategory } from '../../../../functions/src/services/search/contracts/SuggestionCategory';
import { ISuggestion } from '../../../../functions/src/shared/contracts/api/SuggestionContract';
import { Constants } from '../../shared/Constants';
import { logService } from '../../shared/services/LogService';
import { eventHub } from '../EventHub.vue';
import { CategoryTag, GlossTag, ITag, LabelTag, TextTag } from './TagModel';

const search = namespace('search');

@Component({
    components: {
        VueTypeaheadBootstrap,
    },
})
export default class SearchBar extends Vue {
    @search.Getter
    public searchCombined!: Boolean;

    public searchCombinedCheck: Boolean = false;

    public searchText: string | null = null;
    public searchTarget: Vue | null = null;
    public suggestions: ISuggestion[] = [];

    public windowWidth: number = 0;

    private debouncedSuggest = debounce(() => this.suggest(), 200);
    private resizeHandler!: () => void;

    @search.Action('switchSearchCombined')
    private switchSearchCombined!: ({ combined }: { combined: Boolean }) => Promise<void>;

    @search.Action('updateTags')
    private updateTags!: ({
                              tagsToAdd,
                              tagsToRemove,
                              replace,
                          }: { tagsToAdd: ITag[]; tagsToRemove: ITag[]; replace: Boolean }) => Promise<void>;

    @search.Action('initiateTextSearch')
    private initiateTextSearch!: () => Promise<void>;

    @search.Action('stopTextSearch')
    private stopTextSearch!: () => Promise<void>;

    public created(): void {
        this.resizeHandler = () => {
            this.windowWidth = window.innerWidth;
        };
        window.addEventListener('resize', this.resizeHandler);
        this.resizeHandler();
    }

    public mounted(): void {
        this.searchCombinedCheck = this.searchCombined;

        // TODO: Keep an eye on https://github.com/alexurquhart/vue-bootstrap-typeahead/issues/7 It's about
        // adding more input event handling to the typeahead component
        const typeahead = this.$refs.typeahead as Vue;
        const input = typeahead.$el.querySelector('input') as HTMLInputElement;
        input.addEventListener('keydown', event => {
            if (event.key === 'Enter') {
                this.searchOnText();
            }
        });
        input.addEventListener('focus', event => {
            eventHub.emitAppEvent('searchBarFocussed');
            this.initiateTextSearch();
        });
        input.addEventListener('blur', event => {
            this.stopTextSearch();
        });
        // These classes are a pain, remove them
        (typeahead.$el.querySelector('div') as HTMLDivElement).classList.remove('input-group');
        input.classList.remove('form-control');

        eventHub.onAppEvent('newSearchTriggered', () => {
            input.focus();
        });
    }

    public destroyed(): void {
        window.removeEventListener('resize', this.resizeHandler);
    }

    public searchOnTag(suggestion: ISuggestion): Promise<void> {
        const tag = this.createTag(suggestion);
        return this.search(tag);
    }

    public async searchOnText(): Promise<void> {
        if (!this.searchText) {
            return;
        }
        const tag = TextTag(this.searchText);
        return await this.search(tag);
    }

    public get searchPlaceholder(): string {
        return this.windowWidth < 380
            ? 'search.placeholder_veryshort'
            : this.windowWidth < 576
                ? 'search.placeholder_short'
                : 'search.placeholder';
    }

    public getIcon(category: SuggestionCategory): string[] | string {
        switch (category) {
            case SuggestionCategory.Translation:
                return ['fas', 'sign-language'];
            case SuggestionCategory.Category:
                return ['fas', 'th-large'];
            case SuggestionCategory.Label:
                return ['fas', 'tag'];
        }
    }

    public switchSearchCombinedAction(): void {
        this.searchCombinedCheck = !this.searchCombinedCheck;
    }

    private async search(tag: ITag): Promise<void> {
        this.searchText = '';
        // TODO: Currently the typeahead component does not repsond to clearing searchText
        // An open pull request fixes this (https://github.com/alexurquhart/vue-bootstrap-typeahead/pull/38)
        // so update the component after the pull request has been accepted
        (this.$refs.typeahead as any).inputValue = '';
        try {
            await this.updateTags({ tagsToAdd: [tag], tagsToRemove: [], replace: !this.searchCombinedCheck });
            if (this.$route.name !== 'search') {
                this.$router.push('search');
            }
        } catch (err: any) {
            logService.logError(err);
        }
    }

    private createTag(suggestion: ISuggestion): ITag {
        switch (suggestion.category) {
            case SuggestionCategory.Translation:
                return GlossTag(suggestion.text, suggestion.id);
            case SuggestionCategory.Label:
                return LabelTag(suggestion.text, suggestion.id);
            case SuggestionCategory.Category:
                return CategoryTag(suggestion.text, suggestion.id);
            default:
                return TextTag(suggestion.text);
        }
    }

    @Watch('searchCombinedCheck')
    private onSearchCombinedChange() {
        this.switchSearchCombined({ combined: this.searchCombinedCheck });
    }

    @Watch('searchText')
    private onSearchChange() {
        this.debouncedSuggest();
    }

    private async suggest(): Promise<void> {
        if (!this.searchText) {
            this.suggestions = [];
            return;
        }
        const response = await axios.get<ISuggestion[]>(`${Constants.baseApiUrl}/suggest/all`, {
            params: {
                q: this.searchText,
            },
        });
        const suggestions = response.data;
        suggestions.forEach(suggestion => {
            const duplicates = suggestions.filter(
                duplicate =>
                    duplicate.category === suggestion.category &&
                    duplicate.text === suggestion.text &&
                    duplicate.id !== suggestion.id,
            );
            if (duplicates.length) {
                suggestion.text = `${suggestion.text} (${suggestion.id})`;
                duplicates.forEach(duplicate => {
                    duplicate.text = `${duplicate.text} (${duplicate.id})`;
                });
            }
        });

        this.suggestions = suggestions.map((s, i) => {
            (s as any).nr = `e_${i}`;
            return s;
        });
    }
}
</script>

<style lang="scss">
$input-height: 2.5rem;
.vgtc-searchbar {
    position: relative;

    .list-group-item.active {
        background-color: $background-tag-color;
        border-color: $background-tag-color;
        color: $background-tag-text-color;
    }
}

.vgtc-searchbar__input-prefix {
    color: $background-divider-color;
    position: absolute;
    width: 1 + $input-height;
    font-family: $font-family-serif;
    font-size: 1.5rem;
    height: $input-height;
}

.vgtc-searchbar__input {
    border-radius: 0.4rem;
    box-shadow: $box-shadow-searchbar;
    background-color: $background-color;
    color: $background-text-color;
    border: $border-searchbar;
    @include media-breakpoint-up(md) {
        border-radius: 0.2rem;
        box-shadow: $box-shadow-searchbar-long;
    }
    height: $input-height;
    padding: 0;
    padding-left: 1rem;

    &::placeholder {
        color: $background-divider-color;
        font-style: italic;
    }

    @include media-breakpoint-up(sm) {
        padding-left: 1 + $input-height;
    }

    width: 100%;

    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration {
        display: none;
    }
}

.vgtc-searchbar__input-suffix {
    color: $accent-color;
    position: absolute;
    width: 1 + $input-height;
    font-family: $font-family-serif;
    font-size: 1.25rem;
    right: 0;
    height: $input-height;
    cursor: pointer;
}

.vgtc-searchbar__input__suggestion__icon {
    width: 3rem;
    text-align: center;

    &--no-category {
        font-family: $font-family-serif;
    }
}
</style>

