export function groupBy<K extends string, T extends Record<K, string>>(
    groupKey: K,
    items: T[],
): { [group: string]: T[] } {
    return items.reduce((acc: { [group: string]: T[] }, item: T) => {
        (acc[item[groupKey]] = acc[item[groupKey]] || []).push(item);
        return acc;
    }, {});
}

export function getLastURLSegment(url: string): string {
    const segment = /[^/]*$/.exec(url);
    return (segment && segment.toString()) || '';
}

export function getSafely<T>(f: () => T, defaultValue: T): T {
    try {
        return f();
    } catch {
        return defaultValue;
    }
}

export function formatDateTime(date: Date): string {
    return `${formatDate(date)} - ${formatDoubleDigits(date.getHours())}:${formatDoubleDigits(date.getMinutes())}`;
}

export function formatDate(date: Date): string {
    return `${formatDoubleDigits(date.getDate())}/${formatDoubleDigits(date.getMonth() + 1)}/${date.getFullYear()}`;
}

function formatDoubleDigits(nr: number): string {
    return `${nr < 10 ? '0' : ''}${nr}`;
}
