<template>
  <div class="missing_sign_video d-flex flex-column h-100 align-items-center justify-content-center">
    <VideoMissing class="missing_sign_video__img mb-2 h-75" />
    <i18n v-if="!feedbackSent"
          path="video.report_sign_video_error">
      <span class="missing_sign_video__report-link"
            place="action"
            @click="reportProblem()">
        <span class="missing_sign_video__report-link__text">{{$t('video.report')}}</span>
        <font-awesome-icon class="ml-1"
                           :icon="['fal','comment']" />
      </span>
    </i18n>
    <div class="d-flex align-items-center"
         v-else>
      <font-awesome-icon class="fa-2x mr-2"
                         :icon="['far', 'check']" />
      {{$t('video.problem_reported')}}
    </div>
  </div>
</template>

<script lang="ts">
import VideoMissing from '@/assets/svg/video-missing.svg';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ISignDetail, ISignOverview } from '../../../../functions/src/shared/contracts/api/GlossContract';
import { feedbackService } from '../../shared/services/FeedbackService';

@Component({ components: { VideoMissing } })
export default class MissingSignVideo extends Vue {
    @Prop()
    public sign!: ISignDetail | ISignOverview;

    public feedbackSent = false;

    private sendingFeedback = false;

    public async reportProblem(): Promise<void> {
        if (this.sendingFeedback) {
            return;
        }
        this.sendingFeedback = true;
        await feedbackService.giveFeedbackOnSign(
            this.sign,
            `Een gebruiker laat weten dat de video bij het gebaar ${this.sign.glossName} (${this.sign.regions.join(
                ', ',
            )}) niet werkt.`,
            undefined,
            this.$t('feedback.problem.video_not_working').toString(),
        );
        this.sendingFeedback = false;
        this.feedbackSent = true;
    }
}
</script>

<style lang="scss">
.missing_sign_video {
    background-color: $video-background-color;
    color: $header-text-color;
}

.missing_sign_video__img {
    max-height: 20rem;
}

.missing_sign_video__report-link {
    cursor: pointer;

    &:hover {
        color: darken($header-text-color, 15%);
    }
}

.missing_sign_video__report-link {
    text-decoration: underline;
}
</style>