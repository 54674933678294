import { ISignOverview } from '../../../../functions/src/shared/contracts/api/GlossContract';
import { Constants } from '@/shared/Constants';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';

class SignFeedbackService {
    public sessionId = null;

    public async getFeedbackSign(): Promise<ISignOverview> {
        this.sessionId = uuidv4();

        const response = await axios.get<ISignOverview>(`${Constants.baseApiUrl}/feedback/sign`,
            { headers: { sessionId: this.sessionId } });
        return response.data;
    }

    public async analyseRecording(landMarks: string[][], signKnowledge: string): Promise<{ gloss: ISignOverview, score: number, feedbackGloss: boolean }[]> {
        const response = await axios.post(
            `${Constants.baseApiUrl}/feedback/search`,
            {
                signKnowledge,
                landMarks,
            },
            { headers: { sessionId: this.sessionId } },
        );

        return response.data;
    }

    public async sendAnswers(answers: any): Promise<string> {
        const response = await axios.post<string>(
            `${Constants.baseApiUrl}/feedback/questions`,
            {
                answers,
            },
            { headers: { sessionId: this.sessionId } },
        );
        return response.data;
    }
}

export const signFeedbackService = new SignFeedbackService();
