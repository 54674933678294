<template>
  <div :ref="`vc-${videoRef}`"
       :class="{'video-player--fullscreen mt-0':videoFullScreen}"
       class="video-player d-flex flex-column">
    <div :class="{'video-player__container--fullscreen':videoFullScreen}"
         class="video-player__container">
      <video @click="togglePlay()"
             v-bind:ref="videoRef"
             :class="{'video-player__video--fullscreen':videoFullScreen}"
             muted="muted"
             class="video-player__video"
             :src="videoSrc"
             @error="loadingVideoFailed"
             v-if="!hasVideoLoadFailed"
             playsinline>
      </video>
      <img class="video-player__video"
           :src="videoSrc"
           v-if="hasVideoLoadFailed && isApple"/>
      <div v-if="hasVideoLoadFailed && !isApple"
           class="video-player__video-missing p-4">
        <slot name="missing-video">
          <div class="d-flex flex-column h-100 align-items-center justify-content-around">
            <VideoMissing />
            <div class="mt-3">{{ $t('video.video_error')}}</div>
          </div>
        </slot>
      </div>
    </div>
    <div v-if="showControls"
         :class="{'video-player__controls--fullscreen':videoFullScreen}"
         class="video-player__controls mt-2 d-flex justify-content-between align-items-center mb-1">
      <div>
        <button class="video-player__controls__button ml-1"
                v-bind:class="{'video-player__controls__button--highlighted': videoPlaying, 'video-player__controls__button--fullscreen mx-md-3': videoFullScreen}"
                @click="togglePlay()" :disabled="hasVideoLoadFailed && isApple">
          <font-awesome-icon :icon="playIcon" />
        </button>
        <button class="video-player__controls__button"
                @click="toggleSlowPlay()" :disabled="hasVideoLoadFailed && isApple"
                v-bind:class="{'video-player__controls__button--highlighted': videoSlow, 'video-player__controls__button--fullscreen mx-md-3': videoFullScreen}">
          <font-awesome-icon :icon="['far', 'turtle']" />
        </button>
        <button class="video-player__controls__button"
                v-bind:class="{'video-player__controls__button--highlighted': videoLooped, 'video-player__controls__button--fullscreen mx-md-3': videoFullScreen}"
                @click="toggleLoop()" :disabled="hasVideoLoadFailed && isApple">
          <font-awesome-icon :icon="['fas', 'repeat']" />
        </button>
      </div>
      <div>
        <a v-if="showDownloadButton"
           :href="videoDownloadLink"
           :download="downloadName">
          <button class="video-player__controls__button"
                  :class="{'video-player__controls__button--fullscreen mx-md-3': videoFullScreen}">
            <font-awesome-icon :icon="['fas','cloud-download']" />
          </button>
        </a>
        <button :class="{'video-player__controls__button--fullscreen mx-md-3': videoFullScreen}"
                class="video-player__controls__button"
                @click="toggleFullScreen()">
          <font-awesome-icon :icon="['fas', fullScreenIcon]" />
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import VideoMissing from '@/assets/svg/video-missing.svg';
import objectFitVideos from 'object-fit-videos';
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Constants } from '../../shared/Constants';
import { eventService } from '../../shared/services/EventService';
import { eventHub } from '../EventHub.vue';

const displayModule = namespace('display');

@Component({ components: { VideoMissing } })
export default class VideoPlayer extends Vue {
    @Prop() public videoSrc!: string;
    @Prop() public videoDownloadName!: string;
    @Prop({ default: true }) public showControls!: boolean;
    @Prop({ default: true }) public showDownloadButton!: boolean;

    public hasVideoLoadFailed = false;

    public isApple = navigator.vendor && navigator.vendor.indexOf('Apple') > -1;

    private videoRef: string = '';
    private videoFullScreen: boolean = false;
    private videoPlaying: boolean = false;
    private videoLooped: boolean = false;
    private videoSlow: boolean = false;

    public toggleFullScreen(): void {
        if (this.videoFullScreen) {
            this.videoFullScreen = false;
            this.closeFullscreen();
        } else {
            this.openFullscreen(this.getVideoContainer());
            if (document.addEventListener) {
                document.addEventListener('webkitfullscreenchange', this.fullscreenChangeHandler);
                document.addEventListener('mozfullscreenchange', this.fullscreenChangeHandler);
                document.addEventListener('fullscreenchange', this.fullscreenChangeHandler);
                document.addEventListener('MSFullscreenChange', this.fullscreenChangeHandler);
            }
        }
    }

    public togglePlay(): void {
        const videoEl: HTMLVideoElement = this.getVideoElement();
        if (!videoEl) {
            return;
        }
        if (videoEl.paused) {
            eventService.track(eventService.Events.VIDEO_PLAY_ACTION, this.getVideoName());
            videoEl.play();
        } else {
            eventService.track(eventService.Events.VIDEO_PAUSE_ACTION, this.getVideoName());
            videoEl.pause();
        }
    }

    public getVideoName(): string {
        if (!this.videoSrc) {
            return '';
        }
        const videoName = /[^/]*$/.exec(this.videoSrc); // regular expression to get last string after slash
        return videoName && videoName.length > 0 ? videoName[0] : '';
    }

    public get videoDownloadLink(): string {
        if (!this.videoSrc) {
            return '';
        }
        return this.videoSrc.startsWith('blob:') ? this.videoSrc : `${Constants.baseApiUrl}/video?url=${this.videoSrc}`;
    }

    public get downloadName(): string {
        if (this.videoDownloadName) {
            if (this.videoDownloadName.indexOf('.') >= 0) {
                return this.videoDownloadName;
            } else {
                const extension = /[^\.]*$/.exec(this.videoSrc);
                return `${this.videoDownloadName}.${extension}`;
            }
        }
        return this.getVideoName();
    }

    public toggleLoop(): void {
        this.videoLooped = !this.videoLooped;
        const videoEl: HTMLVideoElement = this.getVideoElement();
        videoEl.loop = this.videoLooped;
        if (this.videoLooped) {
            eventService.track(eventService.Events.VIDEO_LOOP, this.getVideoName());
        }
    }

    public toggleSlowPlay(): void {
        this.videoSlow = !this.videoSlow;
        const videoEl: HTMLVideoElement = this.getVideoElement();
        if (!this.videoPlaying) {
            this.togglePlay();
        }
        if (this.videoSlow) {
            videoEl.playbackRate = 0.5;
            eventService.track(eventService.Events.VIDEO_PLAY_SLOWLY, this.getVideoName());
        } else {
            videoEl.playbackRate = 1;
        }
    }

    public get playIcon(): string {
        return this.videoPlaying ? 'pause' : 'play';
    }

    public get fullScreenIcon(): string {
        return this.videoFullScreen ? 'compress' : 'expand';
    }

    @Emit('loadingVideoFailed')
    public loadingVideoFailed(): void {
        this.hasVideoLoadFailed = true;
        if (this.isApple) {
          this.videoLooped = true;
        }
        eventService.track(eventService.Events.VIDEO_FAILED_TO_LOAD, this.videoSrc);
        return;
    }

    @Watch('videoSrc')
    public resetVideo(): void {
        this.hasVideoLoadFailed = false;
        this.setListeners();
    }

    private setListeners(): void {
        const videoEl: HTMLVideoElement = this.getVideoElement();
        if (!videoEl) {
            return;
        }
        objectFitVideos(videoEl);

        // Use event listeners for pause and play.
        // Other controls are possible than our buttons, and we also need to capture these events to update the playing state.
        videoEl.addEventListener('pause', () => {
            this.videoPlaying = false;
        });
        videoEl.addEventListener('play', () => {
            this.videoPlaying = true;
            eventHub.emitAppEvent('videoStarted', this.videoRef);
        });

        // Hack for loading video image on iOS devices
        const onCanPlay = () => {
            videoEl.removeEventListener('canplaythrough', onCanPlay, false);
            videoEl.removeEventListener('load', onCanPlay, false);
        };
        videoEl.play().catch(e => {
            // autoplay was prevented
        });
        if (videoEl.readyState !== 4) {
            videoEl.addEventListener('canplaythrough', onCanPlay, false);
            videoEl.addEventListener('load', onCanPlay, false); //add load event as well to avoid errors, sometimes 'canplaythrough' won't dispatch.
            setTimeout(() => {
                videoEl.pause(); //block play so it buffers before playing
            }, 1); //it needs to be after a delay otherwise it doesn't work properly.
        }

        eventHub.onAppEvent('videoStarted', (videoRef: string) => {
            if (this.videoRef !== videoRef) {
                videoEl.pause();
            }
        });
    }

    private getVideoContainer(): HTMLDivElement {
        return this.$refs[`vc-${this.videoRef}`] as HTMLDivElement;
    }

    private created() {
        this.videoRef = `video-player-${this.videoSrc}-${Math.random()}`;
    }

    private mounted(): void {
        this.setListeners();
    }

    private getVideoElement(): HTMLVideoElement {
        return this.$refs[this.videoRef] as HTMLVideoElement;
    }

    private openFullscreen(elem: any) {
        if (elem.requestFullscreen) {
            elem.requestFullscreen();
        } else if (elem.mozRequestFullScreen) {
            /* Firefox */
            elem.mozRequestFullScreen();
        } else if (elem.webkitRequestFullscreen) {
            /* Chrome, Safari and Opera */
            elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) {
            /* IE/Edge */
            elem.msRequestFullscreen();
        }
        this.videoFullScreen = true;
    }

    private closeFullscreen() {
        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if ((document as any).mozCancelFullScreen) {
            /* Firefox */
            (document as any).mozCancelFullScreen();
        } else if ((document as any).webkitExitFullscreen) {
            /* Chrome, Safari and Opera */
            (document as any).webkitExitFullscreen();
        } else if ((document as any).msExitFullscreen) {
            /* IE/Edge */
            (document as any).msExitFullscreen();
        }
    }

    private fullscreenChangeHandler(event: Event): void {
        if (!this.isFullScreen()) {
            document.removeEventListener('webkitfullscreenchange', this.fullscreenChangeHandler);
            document.removeEventListener('mozfullscreenchange', this.fullscreenChangeHandler);
            document.removeEventListener('fullscreenchange', this.fullscreenChangeHandler);
            document.removeEventListener('MSFullscreenChange', this.fullscreenChangeHandler);
            this.videoFullScreen = false;
        }
        objectFitVideos(this.getVideoElement());
    }

    private isFullScreen(): boolean {
        return (
            (document as any).webkitIsFullScreen ||
            (document as any).mozFullScreen ||
            (document as any).msFullscreenElement
        );
    }
}
</script>

<style lang="scss">
.video-player--fullscreen {
    position: fixed !important;
    top: 0;
    left: 0;
    height: 100% !important;
    width: 100% !important;
    padding: 0 !important;
    z-index: $zindex-fullscreen;
    background-color: $playback-control-background-color;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    overflow: hidden;
}

.video-player__container {
    &--fullscreen {
        padding-bottom: unset;
        height: 100%;
    }
    overflow: hidden;
    width: 100%;
    height: 0;
    padding-bottom: 75%; // Aspect ratio 4:3
    position: relative;
    background-color: $video-background-color;
}

.video-player__video {
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    // object-fit cover for IE and Edge
    font-family: 'object-fit: cover';
    width: 100%;
    height: 100%;
    &--fullscreen {
        object-fit: contain;
        font-family: 'object-fit: contain';
    }
}

.video-player__video-missing {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: $default-text-color;
}

.video-player__controls {
    border-bottom: 1px solid $background-divider-color;
    &--fullscreen {
        background-color: $playback-control-background-color;
        border-bottom: none;
    }
}

.video-player__controls__button {
    color: $playback-control-color;
    background-color: transparent;
    border: none;
    font-size: 1rem;
    padding: 0 0.5rem 0 0.5rem;
    margin-right: 0.25rem;
    &--fullscreen {
        font-size: 2rem;
    }

    &--highlighted {
        color: $accent-color;
    }
}

.video-player__controls__button:disabled {
  color: $playback-control-light-color;
}

.video-player__controls__duration {
    font-size: 0.8rem;
}
</style>
