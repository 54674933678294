<template>
    <div>
        <div class="sign-feedback container mb-4">
            <div class="mt-3 mb-3">
            </div>
            <div v-if="isMobile" class="container vgtc-card pt-3 pb-5">
                <div class="text-center">
                    <SignBuddy class="icon-sign-buddy text-primary mt-4 mb-5"></SignBuddy>
                </div>
                <div class="px-4">{{ $t('sign_feedback.phone_screen') }}</div>
            </div>
            <div v-else>
                <!--            INTRODUCTION-->
                <SignFeedbackCard
                    v-if="step === 0"
                    title="sign_feedback.intro.header_title"
                    :icon="['fas', 'hand']">
                    <Introduction @nextStep="agreeAndNext" :loading="loading" />
                </SignFeedbackCard>

                <!--            STEP 1 - SIGN DETAIL -->
                <SignFeedbackCard
                    v-if="step === 1"
                    title="sign_feedback.step1.header_title"
                    :icon="['fas', 'eye']">
                    <FeedbackSignDetail :sign="feedbackSign" v-model="signKnowledge" @nextStep="nextStep"
                                        @cancel="cancel" />
                </SignFeedbackCard>

                <!--            STEP 2 - RECORD SIGN -->
                <SignFeedbackCard
                    v-if="step === 2"
                    title="sign_feedback.step2.header_title"
                    :icon="['fas', 'video']">
                    <RecordSign :holistic="holistic" :sign="feedbackSign" @nextStep="analyseRecording"
                                @cancel="cancel" />
                </SignFeedbackCard>

                <!--            STEP 3 - PROCESS SIGN LOADING SCREEN -->
                <SignFeedbackCard
                    v-if="step === 3"
                    title="sign_feedback.step3.header_title"
                    :icon="['far', 'hourglass-half']">
                    <ProcessSignLoading :loading="loading" @nextStep="nextStep" @cancel="cancel"
                                        @sendRecording="reSendRecording" />
                </SignFeedbackCard>

                <!--            STEP 4 - RESULT SCREEN -->
                <SignFeedbackCard
                    v-if="step === 4"
                    title="sign_feedback.step4.header_title"
                    :icon="['fas', 'square-check']">
                    <FeedbackResult :sign="feedbackSign" :feedbackResult="feedbackResult" @nextStep="nextStep"
                                    @cancel="noQuestions" />
                </SignFeedbackCard>

                <!--            STEP 5 - QUESTION SCREEN -->
                <SignFeedbackCard
                    v-if="step === 5"
                    title="sign_feedback.step5.header_title"
                    :icon="['far', 'lightbulb']">
                    <FeedbackQuestions :feedback-gloss="feedbackGloss"
                                       :match="feedbackSign.signId === feedbackGloss.gloss.signId"
                                       :loading="loading"
                                       @nextStep="sendAnswers"
                                       @cancel="cancel" />
                </SignFeedbackCard>

                <!--            STEP 6 - THANK YOU SCREEN -->
                <SignFeedbackCard
                    v-if="step === 6"
                    title="sign_feedback.step6.header_title"
                    :icon="['fas', 'heart']">
                    <ThankYou @nextStep="doAnotherSign" :loading="loading" @cancel="cancel" />
                </SignFeedbackCard>
            </div>
        </div>

        <b-toast id="error-toast" variant="danger" solid no-auto-hide>
            <template #toast-title>
                <div class="d-flex flex-grow-1 align-items-center">
                    <font-awesome-icon class="mr-2" size="lg" :icon="['far', 'triangle-exclamation']" />
                    <strong>{{ $t('sign_feedback.error.title') }}</strong>
                </div>
            </template>
            <div>{{ $t('sign_feedback.error.something_wrong') }}</div>
            <div>{{ $t('sign_feedback.error.go_to_start') }}
                <span @click="goToStep(0)" class="text-underline cancel-button">{{ $t('sign_feedback.error.start_page')
                    }}</span>.
            </div>
        </b-toast>
    </div>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import BackToSearch from '@/components/general/BackToSearch.vue';
import Introduction from '@/components/signFeedback/Introduction.vue';
import SignFeedbackCard from '@/components/signFeedback/SignFeedbackCard.vue';
import ProcessSignLoading from '@/components/signFeedback/ProcessSignLoading.vue';
import FeedbackResult from '@/components/signFeedback/FeedbackResult.vue';
import FeedbackQuestions from '@/components/signFeedback/FeedbackQuestions.vue';
import ThankYou from '@/components/signFeedback/ThankYou.vue';
import { signFeedbackService } from '@/shared/services/SignFeedbackService';
import { ISignOverview } from '../../../functions/src/shared/contracts/api/GlossContract';
import FeedbackSignDetail from '@/components/signFeedback/FeedbackSignDetail.vue';
import RecordSign from '@/components/signFeedback/RecordSign.vue';
import * as Holistic from '@mediapipe/holistic';
import SignBuddy from '@/assets/svg/SignBuddy.svg';

@Component({
    components: {
        ProcessSignLoading,
        RecordSign,
        FeedbackSignDetail,
        ThankYou,
        FeedbackQuestions,
        SignFeedbackCard,
        Introduction,
        BackToSearch,
        FeedbackResult,
        SignBuddy,
    },
})
export default class SignFeedback extends Vue {

    public step = 0;

    public feedbackSign: ISignOverview | null = null;
    public loading = false;
    public landMarks = null;
    public signKnowledge: string | null = null;
    public feedbackResult: { gloss: ISignOverview, score: number, feedbackGloss: boolean }[] = null;

    public isMobile = false;

    public holistic = null;

    mounted() {
        this.holistic = new Holistic.Holistic({
            locateFile: (file: string) => {
                return `mediapipe/${file}`;
            },
        });

        this.holistic.setOptions({
            modelComplexity: 2,
            smoothLandmarks: true,
            enableSegmentation: false,
            smoothSegmentation: false,
            refineFaceLandmarks: false,
            minDetectionConfidence: 0.5,
            minTrackingConfidence: 0.5,
        });

        this.onResize();
        window.addEventListener('resize', this.onResize);
    }

    onResize() {
        this.isMobile = window.innerWidth <= 760;
    }

    public nextStep() {
        this.hideErrorToast();
        this.step++;
        window.scrollTo(0, 0);
    }

    public async agreeAndNext() {
        this.loading = true;
        signFeedbackService.getFeedbackSign()
            .then(sign => {
                this.feedbackSign = sign;
                this.nextStep();
            })
            .catch(() => {
                this.showErrorToast();
            })
            .finally(() => this.loading = false);
    }

    public showErrorToast() {
        this.$bvToast.show('error-toast');
    }

    public hideErrorToast() {
        this.$bvToast.hide('error-toast');
    }

    public async analyseRecording(recordingLandmarks: any[]) {
        this.landMarks = recordingLandmarks;
        this.nextStep();
        this.loading = true;
        signFeedbackService.analyseRecording(recordingLandmarks, this.signKnowledge)
            .then(result => {
                this.feedbackResult = result;
                this.nextStep();
            })
            .catch(() => this.showErrorToast())
            .finally(() => this.loading = false);
    }

    public async reSendRecording() {
        this.loading = true;
        this.hideErrorToast();
        signFeedbackService.analyseRecording(this.landMarks, this.signKnowledge)
            .then(result => {
                this.landMarks = null;
                this.feedbackResult = result;
                this.nextStep();
            })
            .catch(() => this.showErrorToast())
            .finally(() => this.loading = false);
    }

    public get feedbackGloss() {
        return this.feedbackResult.find(res => res.feedbackGloss);
    }

    public noQuestions() {
        this.goToStep(6);
    }

    private goToStep(step: number) {
        this.hideErrorToast();
        this.step = step;
        window.scrollTo(0, 0);
    }

    public async sendAnswers(answers: any) {
        this.loading = true;
        signFeedbackService.sendAnswers(answers)
            .then(() => this.nextStep())
            .catch(() => this.showErrorToast())
            .finally(() => this.loading = false);
    }

    public doAnotherSign() {
        this.loading = true;
        this.feedbackResult = null;
        this.feedbackSign = null;
        signFeedbackService.getFeedbackSign()
            .then(sign => {
                this.feedbackSign = sign;
                this.goToStep(1);
            })
            .catch(() => {
                this.showErrorToast();
            })
            .finally(() => this.loading = false);
    }

    public cancel() {
        this.goToStep(0);
        this.signKnowledge = null;
        this.feedbackSign = null;
    }

    beforeDestroy() {
        this.holistic.close();
        window.removeEventListener('resize', this.onResize);
    }
}
</script>

<style lang="scss">
.sign-feedback {
    &__header__text {
        font-size: 1.5rem;
        color: $text-title-color;
        font-weight: bold;
        font-family: $font-family-accent;
        letter-spacing: 0.075rem;
        @include media-breakpoint-up(sm) {
            font-size: 2.1rem;
        }
    }

    &__sub-header__text {
        font-size: 1.25rem;
        color: $text-title-color;
    }

    &__consent__text {
        font-size: 0.75rem;
    }
}
</style>

<style lang="scss" scoped>
.icon-sign-buddy {
    height: 100px;
}
</style>
