<template>
  <div>
    <OffCanvasMenu :ref="offCanvasMenuRef" />
    <b-navbar type="light"
              :sticky="true"
              class="vgtc-hero justify-content-center align-items-stretch">
      <div class="vgtc-hero__content d-flex flex-column flex-grow-1 justify-content-between pb-lg-4 pb-2 pt-2">
        <Header class="d-none d-lg-block" />
        <div class="vgtc-hero__content__text d-none d-lg-block">
          <!-- <div class="vgtc-hero__content__text__welcome">{{ $t('header.welcome') }}</div> -->
          <div class="vgtc-hero__content__text__languages">{{ $t('header.vgtc_dutch') }}</div>
        </div>
        <div class="d-flex flex-row">
          <button @click="toggleSlideMenu"
                  class="menu-button d-md-none">
            <span class="menu-button__icon-bar"></span>
            <span class="menu-button__icon-bar"></span>
            <span class="menu-button__icon-bar"></span>
          </button>
          <SearchBar class="flex-grow-1 pr-2 pr-sm-0" />
        </div>
      </div>
    </b-navbar>
  </div>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Header from '../Header.vue';
import OffCanvasMenu from '../OffCanvasMenu.vue';
import SearchBar from './SearchBar.vue';

const search = namespace('search');

@Component({
    components: { Header, OffCanvasMenu, SearchBar },
})
export default class TopNavbar extends Vue {
    @search.Getter public userIsSearching!: boolean;

    private offCanvasMenuRef = 'off-canvas-menu';

    public toggleSlideMenu(e: any): void {
        // Way to prevent the mouseclick on the button to trigger the
        // "outside-menu-clicked" state which would close the menu.
        e.stopPropagation();
        (this.$refs[this.offCanvasMenuRef] as OffCanvasMenu).toggleSlideMenu();
    }
}
</script>
<style lang="scss">
.vgtc-hero.navbar {
    background-color: $header-color;
    box-shadow: $box-shadow-navbar;

    @include media-breakpoint-down(lg) {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        height: $navbar-height;
    }

    @include media-breakpoint-up(lg) {
        background-image: linear-gradient(to bottom, $brand-color-light, $brand-color);
        height: $navbar-hero-height;
    }
}

.vgtc-hero__content {
    @include media-breakpoint-up(lg) {
        background-image: url('../../../public/img/vgtc-banner-mute.png');
        background-position: center;
        background-repeat: no-repeat;
        top: -$navbar-hero-height + 6.25rem;
    }

    @include media-breakpoint-down(xl) {
        max-width: map-get($container-max-widths, xl);
    }
    @include media-breakpoint-down(lg) {
        max-width: map-get($container-max-widths, lg);
    }
    @include media-breakpoint-up(sm) {
        padding-left: $grid-gutter-width / 2;
        padding-right: $grid-gutter-width / 2;
    }
}

.vgtc-hero__content__text {
    color: $header-text-color;
    font-size: 2rem;
    font-family: $font-family-accent;
    text-align: center;
}

.vgtc-hero__content__text__languages {
    font-weight: bold;
}
</style>

