<template>
  <button @click="showFeedbackModal()"
          class="header__link d-flex btn header__button__icon">
    <font-awesome-icon class="faq-link__icon mr-md-2"
                       :icon="['fas','comment-smile']" />
    <span class="d-none d-md-block">{{$t('general.feedback')}}</span>
  </button>
</template>

<script lang="ts">
import { Component, Emit, Vue } from 'vue-property-decorator';

@Component({})
export default class FeedbackButton extends Vue {
    @Emit('click')
    public showFeedbackModal(): void {
        this.$bvModal.show('feedbackModal');
    }
}
</script>

<style lang="scss">
</style>