<template>
    <div>
        <div v-if="foundMatch" class="d-flex align-items-center box">
            <font-awesome-icon class="mr-2" size="3x"
                               style="--fa-primary-color: white;--fa-secondary-color: #00C0AD;--fa-secondary-opacity: 1"
                               :icon="['fad','badge-check']" />
            <div class="sign-feedback__sub-header__text">{{ $t('sign_feedback.step4.explanation_match') }}</div>
        </div>
        <div v-else class="d-flex align-items-center">
            <div class="sign-feedback__sub-header__text">{{ $t('sign_feedback.step4.explanation_no_match') }}</div>
        </div>
        <div class="mt-3">{{ $t('sign_feedback.step4.explanation_results', { amount: feedbackResult.length }) }}</div>

        <div class="row mt-3">
            <div class="col-4 mb-4 box" v-for="searchResult in feedbackResult"
                 :class="{'sign-match': searchResult.gloss.signId === sign.signId}">
                <SearchResultCard :search-result="searchResult.gloss"
                                  :show-region="false"
                                  :show-detail="false">
                </SearchResultCard>
                <div v-if="searchResult.gloss.signId === sign.signId" class="sign-match-icon">
                    <font-awesome-icon class="mr-2" size="4x"
                                       style="--fa-primary-color: white;--fa-secondary-color: #00C0AD;--fa-secondary-opacity: 1"
                                       :icon="['fad','badge-check']" />
                </div>
            </div>
        </div>

        <div class="d-flex align-items-center mt-4">
            <div>
                <SignBuddy class="icon-sign-buddy text-primary mr-3"></SignBuddy>
            </div>
            <div class="pt-2">
                <div class="sign-feedback__sub-header__text">{{ $t('sign_feedback.step4.question') }}</div>
                <div class="d-flex align-items-center mt-3">
                    <Button type="cancel"
                            label="sign_feedback.step4.no_thanks"
                            @cancel="cancel"></Button>
                    <Button class="ml-5" type="button" label="sign_feedback.step4.yes_ask" :icon="['far', 'share']"
                            @click="nextStep"
                    ></Button>
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import Button from '@/components/general/Button.vue';
import SearchResultCard from '@/components/search/SearchResultCard.vue';
import { ISignOverview } from '../../../../functions/src/shared/contracts/api/GlossContract';
import SignBuddy from '@/assets/svg/SignBuddy.svg';

@Component({ components: { Button, SearchResultCard, SignBuddy } })
export default class FeedbackResult extends Vue {

    @Prop() feedbackResult: { gloss: ISignOverview, score: number, feedbackGloss: boolean }[];

    @Prop() sign: ISignOverview;

    public get foundMatch(): boolean {
        return this.feedbackResult.findIndex(res => res.gloss.signId === this.sign.signId) > -1;
    }

    @Emit('nextStep')
    public nextStep() {
        return;
    }

    @Emit('cancel')
    public cancel() {
        return;
    }
}
</script>

<style lang="scss">
.sign-match {
    border: dotted 2px #00C0AD;
    border-radius: 8px;

    &-icon {
        position: absolute;
        right: -20px;
        top: -20px;
    }
}

.found-match-icon {
    position: absolute;
    left: 13px;
}
</style>
<style lang="scss" scoped>
.icon-sign-buddy {
    height: 130px;
    margin-top: -15px;

    &--small {
        height: 40px;
    }
}
</style>

