<template>
    <div>
        <div v-if="!sign" class="text-center mt-5">
            <b-spinner></b-spinner>
        </div>
        <div v-else class="row align-items-start pt-4">
            <div class="col-7">
                <div v-html="$t('sign_feedback.step1.explanation',{sign: sign.translations.join(', ')})"></div>
                <div class="d-flex align-items-center mt-4"><span>{{ $t('sign_feedback.step1.not_watching') }}</span>
                    <font-awesome-icon class="ml-4 color-brand" size="2x"
                                       :icon="['far', 'eye-slash']" />
                    <SignBuddy class="icon-sign-buddy text-primary ml-3"></SignBuddy>

                </div>
                <div v-if="!alreadyKnownKnowledge" class="mt-5">
                    <div class="sign-feedback__sub-header__text">{{ $t('sign_feedback.step1.question_title') }}</div>
                    <div class="my-3 font-bold text-bold">{{ $t('sign_feedback.step1.question_text') }}</div>
                    <b-form-group v-slot="{ ariaDescribedby }">
                        <b-form-radio-group
                            :checked="value"
                            :aria-describedby="ariaDescribedby"
                            name="radio-sub-component">
                            <div class="d-flex justify-content-around px-5">
                                <div v-for="option in options"
                                     :class="{'sign-feedback__tile--selected':value === option.value}"
                                     class="sign-feedback__tile d-flex flex-column align-items-center justify-content-around py-3"
                                     @click="answerChanged(option.value)">
                                    <b-form-radio :value="option.value" class="m-0 ml-2"></b-form-radio>
                                    <span class="text-bold">
                                    <SvgIcon class="option-icon mr-n1 ml-n2"
                                             :icon="option.icon" />
                                        {{ $t(option.label) }}</span>
                                </div>
                            </div>
                        </b-form-radio-group>
                    </b-form-group>
                </div>
            </div>

            <div class="box col-5">
                <SearchResultCard :search-result="sign"
                                  :show-region="false"
                                  :show-detail="false">
                </SearchResultCard>
                <div class="d-flex align-items-center justify-content-end  mt-5">
                    <Button type="cancel"
                            @cancel="cancel"></Button>
                    <Button class="ml-5" type="button" label="sign_feedback.next_step" :icon="['far', 'share']"
                            @click="nextStep"
                            :disabled="value === null"></Button>
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import VideoPlayer from '@/components/video/VideoPlayer.vue';
import MissingSignVideo from '@/components/video/MissingSignVideo.vue';
import SearchResultCard from '@/components/search/SearchResultCard.vue';
import Button from '@/components/general/Button.vue';
import { ISignOverview } from '../../../../functions/src/shared/contracts/api/GlossContract';
import SvgIcon from '@/components/SvgIcon.vue';
import SignBuddy from '@/assets/svg/SignBuddy.svg';


@Component({ components: { SearchResultCard, MissingSignVideo, VideoPlayer, Button, SvgIcon, SignBuddy } })
export default class FeedbackSignDetail extends Vue {
    @Prop() sign: ISignOverview;
    @Prop() value: string | null;

    public alreadyKnownKnowledge = false;

    public mounted() {
        if (this.value !== null)
            this.alreadyKnownKnowledge = true;
    }

    public options = [
        { label: 'sign_feedback.yes', value: 'yes', icon: 'ja' },
        { label: 'sign_feedback.slightly', value: 'slightly', icon: 'eenbeetje' },
        { label: 'sign_feedback.no', value: 'no', icon: 'nee' }];

    @Emit('nextStep')
    public nextStep() {
        return;
    }

    @Emit('input')
    public answerChanged(answer: string): string {
        return answer;
    }

    @Emit('cancel')
    public cancel() {
        return;
    }
}
</script>

<style lang="scss">
.sign-feedback {
    &__sub-header__text {
        font-size: 1.25rem;
        color: $text-title-color;
    }

    &__tile {
        width: 120px;
        height: 120px;
        background-color: $category-card-background-color;
        border: $card-border;
        box-shadow: $box-shadow-light;
        border-radius: $default-border-radius;
        color: $category-card-front-color;
        cursor: pointer;

        &--selected {
            border-color: $brand-color;
            color: $brand-color;
        }
    }
}


.text-highlight {
    color: $accent-color;
}

.option-icon {
    height: 30px;
}
</style>


<style lang="scss" scoped>
.icon-sign-buddy {
    height: 65px;
}
</style>
