import store from '@/store/store';
import { getSafely } from '@/util/util-functions';
import Vue from 'vue';
import { IRegion } from '../../../functions/src/shared/contracts/api/MasterdataContract';

Vue.filter('translateRegions', (value: string[]): string[] => {
    if (!value) {
        return [];
    }
    return value.map(region => {
        return getSafely(() => (store.state.cache.regions.find(r => r.id === region) as IRegion).name, '')
    }
    )
});