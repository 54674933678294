<template>
    <BelgiumRegions :ref="svgRef" />
</template>

<script lang="ts">
import BelgiumRegions from '@/assets/svg/regions/belgium_regions.svg';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Region } from '../../../../functions/src/shared/contracts/Region';

@Component({ components: { BelgiumRegions } })
export default class RegionIcon extends Vue {
    @Prop() public regions!: Region[];

    private svgRef: string = `belgium_regions_${Math.random()}`;
    private svg!: Vue;

    public mounted() {
        this.svg = this.$refs[this.svgRef] as Vue;
        this.deselectAllRegions();
        this.selectRegions();
    }

    @Watch('regions')
    private selectRegions() {
        this.deselectAllRegions();
        if (this.regions) {
            if (this.regions.find(r => r === Region.Vlaanderen)) {
                this.selectAllRegions();
            } else {
                this.regions.forEach(this.selectRegion);
            }
        }
    }

    private selectAllRegions() {
        Object.values(Region).forEach((r: Region) => this.selectRegion(r));
    }

    private deselectAllRegions() {
        Object.values(Region).forEach((r: Region) => this.deselectRegion(r));
    }

    private selectRegion(region: Region): void {
        const htmlRegion = this.svg.$el.querySelector(`.${region}`) as HTMLElement | undefined;
        const htmlOutline = this.svg.$el.querySelector(`.${region}_region-outline`) as HTMLElement | undefined;
        if (htmlRegion && htmlOutline) {
            htmlRegion.style.opacity = '1';
            htmlOutline.style.strokeOpacity = '1';
        }
    }

    private deselectRegion(region: Region): void {
        const htmlRegion = this.svg.$el.querySelector(`.${region}`) as HTMLElement | undefined;
        const htmlOutline = this.svg.$el.querySelector(`.${region}_region-outline`) as HTMLElement | undefined;
        if (htmlRegion && htmlOutline) {
            htmlRegion.style.opacity = '0';
            htmlOutline.style.strokeOpacity = '0';
        }
    }
}
</script>

<style lang="scss">
</style>
