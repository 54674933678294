<template>
    <div class="container vgtc-card sign-detail pt-3 pb-5">
        <div class="sign-detail__header d-flex align-items-start justify-content-between ml-4 mr-3">
            <div class="d-flex align-items-center">
                <font-awesome-icon class="mr-2 color-brand sign-detail__header__book-icon"
                                   :icon="['fas', 'book']" />
                <div class="ml-2 sign-detail__header__text">{{ signTranslations }}</div>
            </div>
            <div class="mt-1"
                 v-RequireAdmin>
                <div v-if="!signOfTheWeekSuccess">
                    {{ $t('gloss_detail.set_as_sign_of_week') }}
                    <button v-for="(week, index) in weeks"
                            :key="index"
                            class="btn btn-admin ml-2"
                            @click="setAsSignOfTheWeek(week)">
                        {{ getDateText(week) }}
                    </button>
                </div>
                <div v-else>{{ $t('gloss_detail.set_as_sign_ot_week_success') }}</div>
            </div>
        </div>
        <div class="divider divider--light my-1 mx-3"></div>
        <div class="sign-detail__body row mt-4 px-3">
            <div class="sign-detail__body__video col-md-8 col-lg-9 ">
        <span class="d-flex align-items-end flex-grow-1 pb-4 align-items-center">
          <RegionIcon :regions="signDetail && signDetail.regions"
                      class="sign-detail__body__video__region-icon" />
          <span
              class="ml-2 sign-detail__body__video__region-text">{{ signDetail && signDetail.regions | translateRegions | join(', ')
              }}</span>
        </span>
                <VideoPlayer :videoSrc="signDetail && signDetail.video"
                             :videoDownloadName="videoDownloadName">
                    <template slot="missing-video">
                        <MissingSignVideo :sign="signDetail" />
                    </template>
                </VideoPlayer>
            </div>
            <div class="sign-detail__body__details d-flex flex-column col-md-4 col-lg-3 mt-5 mt-md-0">
                <SignDetailCard class="mb-5"
                                :searchType="handsHapeType"
                                :tileTitle="$t('info.handshapes')"
                                @infoClicked="showInfoModal(handsHapeType)">
                    <template>
                        <div v-if="hasHandshapes"
                             class="d-flex justify-content-between">
                            <Tile class="sign-detail__tile sign-detail__tile--handshape"
                                  v-if="signDetail.startHandshape"
                                  :icon="getHandshapeIcon(signDetail.startHandshape)"
                                  :displayText="getHandshapeText(signDetail.startHandshape)"
                                  :labelSmall="true"
                                  @cardSelected="startSearchOnHandshape(signDetail.startHandshape)" />
                            <Tile class="sign-detail__tile sign-detail__tile--handshape"
                                  v-if="signDetail.endHandshape && signDetail.endHandshape !== signDetail.startHandshape"
                                  :icon="getHandshapeIcon(signDetail.endHandshape)"
                                  :displayText="getHandshapeText(signDetail.endHandshape)"
                                  :labelSmall="true"
                                  @cardSelected="startSearchOnHandshape(signDetail.endHandshape)" />
                        </div>
                    </template>
                    <template v-if="!hasHandshapes"
                              v-slot:empty-state>{{ $t('gloss_detail.no_handshape_yet') }}
                    </template>
                </SignDetailCard>
                <SignDetailCard class="mb-5"
                                :searchType="locationType"
                                :tileTitle="$t('info.locations')"
                                @infoClicked="showInfoModal(locationType)">
                    <template v-if="hasLocations">
                        <div class="d-flex justify-content-between">
                            <Tile class="sign-detail__tile sign-detail__tile--location"
                                  v-if="signDetail.startLocation"
                                  :icon="getLocationIcon(signDetail.startLocation)"
                                  :displayText="getLocationText(signDetail.startLocation)"
                                  :labelSmall="true"
                                  @cardSelected="startSearchOnLocation(signDetail.startLocation)" />
                            <Tile class="sign-detail__tile sign-detail__tile--location"
                                  v-if="signDetail.endLocation && signDetail.startLocation !== signDetail.endLocation"
                                  :icon="getLocationIcon(signDetail.endLocation)"
                                  :displayText="getLocationText(signDetail.endLocation)"
                                  :labelSmall="true"
                                  @cardSelected="startSearchOnLocation(signDetail.endLocation)" />
                        </div>
                    </template>
                    <template v-if="!hasLocations"
                              v-slot:empty-state>{{ $t('gloss_detail.no_location_yet') }}
                    </template>
                </SignDetailCard>
                <SignDetailCard :searchType="categoryType"
                                :tileTitle="$t('info.categories')"
                                @infoClicked="showInfoModal(categoryType)">
                    <template v-if="signDetail && signDetail.categories">
                        <div class="ml-2">
                            <router-link v-for="cat in categoryTranslations"
                                         @click.native="trackClickOnCategory(cat.id)"
                                         :key="cat.id"
                                         :to="{name: 'search', query:{c:cat.id}}"
                                         class="d-flex color-accent align-items-center sign-detail__body__details__categories__item mb-2">
                                <SearchTypeIcon class="mr-2"
                                                :searchType="categoryType" />
                                <span class="text-underline">{{ cat.name }}</span>
                            </router-link>
                        </div>
                    </template>
                    <template v-if="!signDetail || !signDetail.categories"
                              v-slot:empty-state>{{ $t('gloss_detail.no_categories_yet') }}
                    </template>
                </SignDetailCard>
                <SignDetailCard :searchType="labelType" class="mt-4"
                                :tileTitle="$t('info.labels')"
                                @infoClicked="showInfoModal(labelType)">
                    <template v-if="signDetail && signDetail.labels">
                        <div class="ml-2">
                            <router-link v-for="lab in labelTranslations"
                                         @click.native="trackClickOnLabel(lab.id)"
                                         :key="lab.id"
                                         :to="{name: 'search', query:{lb:lab.id}}"
                                         class="d-flex color-accent align-items-center sign-detail__body__details__categories__item mb-2">
                                <b-badge pill
                                         variant="accent"
                                         class="label-badge">
                                    {{ lab.name }}
                                </b-badge>
                            </router-link>
                        </div>
                    </template>
                    <template v-if="!signDetail || !signDetail.labels || signDetail.labels.length === 0"
                              v-slot:empty-state>{{ $t('gloss_detail.no_labels_yet') }}
                    </template>
                </SignDetailCard>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import RequireAdmin from '@/directives/RequireAdminDirective';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { ISignDetail } from '../../../../functions/src/shared/contracts/api/GlossContract';
import { ICategory, ILabel, ILocation } from '../../../../functions/src/shared/contracts/api/MasterdataContract';
import { Category } from '../../../../functions/src/shared/contracts/Category';
import { Handshape } from '../../../../functions/src/shared/contracts/Handshape';
import { Location } from '../../../../functions/src/shared/contracts/Location';
import { Label } from '../../../../functions/src/shared/contracts/Label';
import { eventService } from '../../shared/services/EventService';
import { handshapeService } from '../../shared/services/HandshapeService';
import { locationService } from '../../shared/services/LocationService';
import { signService } from '../../shared/services/SignService';
import { IIcon } from '../../util/gui-decorators';
import { formatDate, getSafely } from '../../util/util-functions';
import RegionIcon from '../general/RegionIcon.vue';
import Tile from '../search/option/searchTypes/tileSearchType/Tile.vue';
import { SearchType } from '../search/SearchType';
import SearchTypeIcon from '../search/SearchTypeIcon.vue';
import SvgIcon from '../SvgIcon.vue';
import MissingSignVideo from '../video/MissingSignVideo.vue';
import VideoPlayer from '../video/VideoPlayer.vue';
import SignDetailCard from './SignDetailCard.vue';

const cacheModule = namespace('cache');
const displayModule = namespace('display');

@Component({
    components: { MissingSignVideo, RegionIcon, SearchTypeIcon, SignDetailCard, SvgIcon, Tile, VideoPlayer },
    directives: { RequireAdmin },
})
export default class SignDetail extends Vue {
    @Prop() public signDetail!: ISignDetail;
    @Prop({ default: true }) public loading!: boolean;

    public weeks = signService.getSignOfTheWeekWeeks();

    public videoDownloadName: string | null = null;

    public handsHapeType: SearchType = SearchType.Handshape;
    public locationType: SearchType = SearchType.Location;
    public categoryType: SearchType = SearchType.Category;
    public labelType: SearchType = SearchType.Label;

    public signOfTheWeekSuccess = false;
    public signOfTheWeekError: string | null = null;

    @cacheModule.Getter
    private categories!: ICategory[];

    @cacheModule.Getter
    private labels!: ILabel[];

    @cacheModule.Getter
    private locations!: ILocation[];

    @displayModule.Action
    private showInfoModal!: (searchType: SearchType) => Promise<void>;

    public created(): void {
        this.watchSignDetail();
    }

    @Watch('signDetail')
    public async watchSignDetail(): Promise<void> {
        if (!this.signDetail) {
            return;
        }
        this.videoDownloadName = await signService.getSummaryName(this.signDetail);
    }

    public get signTranslations() {
        return this.signDetail && this.signDetail.translations.join(', ');
    }

    public get hasHandshapes() {
        return this.signDetail && (this.signDetail.startHandshape || this.signDetail.endHandshape);
    }

    public get hasLocations() {
        return this.signDetail && (this.signDetail.startLocation || this.signDetail.endLocation);
    }

    public get categoryTranslations(): ICategory[] {
        return (
            (this.signDetail &&
                this.signDetail.categories &&
                this.categories &&
                this.categories.filter(c => this.signDetail.categories.includes(c.id))) ||
            []
        );
    }

    public get labelTranslations(): ILabel[] {
        return (
            (this.signDetail &&
                this.signDetail.labels &&
                this.labels &&
                this.labels.filter(l => this.signDetail.labels.includes(l.id))) ||
            []
        ).sort((a: ILabel, b: ILabel) => a.name.localeCompare(b.name));
    }

    public getHandshapeIcon(handshape: Handshape): IIcon | undefined {
        return handshapeService.getIconFor(handshape);
    }

    public getHandshapeText(handshape: Handshape): string {
        return handshape && handshape.replace(';', ' en ');
    }

    public getLocationIcon(location: Location): IIcon | undefined {
        return locationService.getIconFor(location);
    }

    public getLocationText(location: Location): string {
        return getSafely<string>(() => this.locations.find(l => l.id === location)!.name, '');
    }

    public trackClickOnCategory(category: Category): void {
        eventService.track(eventService.Events.SEARCH_FROM_DETAILS_PAGE, category.toString());
    }

    public trackClickOnLabel(label: Label): void {
        eventService.track(eventService.Events.SEARCH_FROM_DETAILS_PAGE, label.toString());
    }

    public startSearchOnLocation(searchQuery: Location): void {
        const query = { l: searchQuery };
        this.$router.push({ path: '/search', query });
    }

    public startSearchOnHandshape(searchQuery: Handshape): void {
        const query = { h: searchQuery };
        this.$router.push({ path: '/search', query });
    }

    public async setAsSignOfTheWeek(week: Date): Promise<void> {
        try {
            await signService.setSignOfTheWeek(week, this.signDetail.glossName, this.signDetail.signId);
            this.signOfTheWeekSuccess = true;
        } catch (e: any) {
            (this as any).$bvModal.msgBoxOk(`${this.$t('gloss_detail.set_as_sign_ot_week_error')}: ${e}`);
            this.signOfTheWeekError = e;
        }
    }

    public getDateText(date: Date): string {
        return formatDate(date);
    }
}
</script>

<style lang="scss" scoped>
.label-badge {
    font-size: 0.95rem;
    padding: 5px 10px;
}
</style>
<style lang="scss">
.sign-detail__header__text {
    font-size: 1.5rem;
    color: $text-title-color;
    font-weight: bold;
    font-family: $font-family-accent;
    letter-spacing: 0.075rem;
    @include media-breakpoint-up(sm) {
        font-size: 2.1rem;
    }
}

.sign-detail__header__book-icon {
    width: 1.5rem !important;
    height: 1.5rem;
    @include media-breakpoint-up(sm) {
        width: 2.1rem !important;
        height: 2.1rem;
    }
}

.sign-detail__body__video__region-icon {
    height: 2.5rem;
    width: 3.5rem;
}

.sign-detail__body__video__region-text {
    letter-spacing: 0.075rem;
}

.sign-detail__body__details__categories__item {
    font-size: $font-size-default;
    font-weight: 700;
    cursor: pointer;
}

.sign-detail__tile {
    width: 45%;

    &--handshape {
        svg {
            width: 75% !important;
            height: 75% !important;
        }
    }

    &--location {
        svg {
            width: 100% !important;
            height: 100% !important;
        }
    }
}
</style>
