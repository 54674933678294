<template>
    <b-navbar type="light"
              :sticky="true"
              class="vgtc-navbar justify-content-center align-items-stretch">
        <div class="vgtc-navbar__content d-flex flex-column flex-grow-1 justify-content-between pb-2 pt-2">
            <Header />
        </div>
    </b-navbar>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import Header from './Header.vue';

@Component({ components: { Header } })
export default class Navbar extends Vue {
}
</script>

<style lang="scss">
.vgtc-navbar {
    background-color: $header-color;
}

.vgtc-navbar__content {
    @include media-breakpoint-down(xl) {
        max-width: map-get($container-max-widths, xl);
    }
    @include media-breakpoint-down(lg) {
        max-width: map-get($container-max-widths, lg);
    }
    @include media-breakpoint-up(sm) {
        padding-left: $grid-gutter-width / 2;
        padding-right: $grid-gutter-width / 2;
    }
}
</style>
