// TODO: add logger service for frontend?
class LogService {

    public logException(message: string, params?: any) {
        // tslint:disable-next-line
        console.log(message);
    }

    public logError(message: string, params?: any) {
        // tslint:disable-next-line
        console.log(message);
    }
}
export const logService = new LogService();