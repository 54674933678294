<template>
    <button class="search-filter-tag d-flex align-items-center"
            @click="deleteTag()">
        <SearchTypeIcon v-if="tag.searchType !== 'Text'"
                        class="ml-1"
                        :searchType="tag.searchType" />
        <span v-else
              class="ml-1 search-filter-tag__prefix search-filter-tag__prefix--text">T</span>
        <span class="search-filter-tag__text my-1 my-md-2 pl-2 pr-3">{{ tag.text }}</span>
        <span class="search-filter-tag__icon d-flex align-self-stretch mt-1 mb-1 pl-2 pr-1 align-items-center">
            <font-awesome-icon :icon="['fal', 'times']" /></span>
    </button>

</template>
<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { SearchType } from './SearchType';
import SearchTypeIcon from './SearchTypeIcon.vue';
import { ITag } from './TagModel';

@Component({ components: { SearchTypeIcon } })
export default class SearchFilterTag extends Vue {
    @Prop() private tag!: ITag;

    @Emit('delete')
    public deleteTag() {
        return this.tag;
    }
}
</script>
<style lang="scss">
.search-filter-tag {
    border: none;
    background-color: $background-tag-color;
    color: $background-tag-text-color;
    border-radius: 0.3rem;
    font-size: 0.9rem;
    @include media-breakpoint-up(md) {
        font-size: 1rem;
    }
}
.search-filter-tag__text {
    border-right: solid 1px rgba($background-tag-text-color, 0.4);
}

.search-filter-tag__icon {
    font-size: 1.25rem;
}
.search-filter-tag__prefix {
    &--text {
        font-family: $font-family-serif;
    }
}
</style>

