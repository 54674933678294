// "AND" search inidactes that locations, handshapes and categories are filters and prune the set of results.
// "OR" search indicates that locations, handshapes and categories which match the sign, are included

// "Fuzzy" search inidactes that searchStrings are fuzzied.
// "Exact" search indicates that searchStrings must be a exact match.
export enum SearchMode {
    ORFuzzy = 'ORFuzzy',
    ANDExact = 'ANDExact',
    ANDFuzzy = 'ANDFuzzy',
    DEFAULT_SEARCH_MODE = ANDExact,
}
