<template>
    <div class="vgtc-logo">
        <SvgIcon v-if="!dark && !small" icon="logo-VGTC-wit"></SvgIcon>
        <img v-if="dark && !small"
             src="../../../public/img/VGTC_logo_65.png">
        <img v-if="!dark && small"
             src="../../../public/img/VGTC_logo_small_white.png">
        <img v-if="dark && small"
             src="../../../public/img/VGTC_logo_small_color.png">
    </div>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import SvgIcon from '@/components/SvgIcon.vue';

@Component({ components: { SvgIcon } })
export default class VGTCLogo extends Vue {
    @Prop() public dark!: boolean;
    @Prop() public small!: boolean;
}
</script>

<style lang="scss">
.vgtc-logo {
    display: inline-block;

    svg {
        fill: white;
        width: 100px;
        height: 100px;
    }
}
</style>




