<template>
  <div class="d-flex flex-column align-items-center">
    <VideoTextSwitch class="w-100"
                     :value="value"
                     @input="emitActiveInput">
      <template v-slot:text>
        <slot name="text"></slot>
      </template>
      <template v-slot:video>
        <slot name="video"></slot>
      </template>
    </VideoTextSwitch>
    <b-form-textarea v-show="value.textInputActive"
                     :value="value.textualFeedback"
                     @update="emitTextualFeedback"
                     rows="8"
                     class="flex-grow-1 mb-4 input-frame" />
    <RTCVideoRecorder v-if="value.videoInputActive && !enableFallback"
                      :value="value.feedbackVideo"
                      @input="emitFeedbackVideo"
                      @alternativeButtonClicked="enableFallback=true"
                      class="mb-4 input-frame"
                      @incompatibleWithBrowser="enableFallback=true">
      <!-- <template>
        <div class="d-flex flex-column align-items-center ">
          <font-awesome-icon class="fa-2x"
                             :icon="['far','upload']" />
          <span>Upload</span>
        </div>
      </template> -->
    </RTCVideoRecorder>
    <UploadVideoFrame v-if="value.videoInputActive && enableFallback"
                      v-model="value.feedbackVideo"
                      class="w-100 mb-4 input-frame " />

  </div>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import RTCVideoRecorder from '../video/RTCVideoRecorder.vue';
import UploadVideoFrame from '../video/UploadVideoFrame.vue';
import VideoTextSwitch from './VideoTextSwitch.vue';

@Component({ components: { RTCVideoRecorder, VideoTextSwitch, UploadVideoFrame } })
export default class VideoTextInput extends Vue {
    @Prop() public value!: {
        textualFeedback?: string;
        feedbackVideo?: File | Blob;
        textInputActive: boolean;
        videoInputActive: boolean;
    };

    public enableFallback: boolean = false;

    public emitTextualFeedback(textualFeedback: string): void {
        this.emit({
            feedbackVideo: this.value.feedbackVideo,
            textInputActive: true,
            textualFeedback,
            videoInputActive: false,
        });
    }

    public emitFeedbackVideo(feedbackVideo: Blob | File): void {
        this.emit({
            feedbackVideo,
            textInputActive: false,
            textualFeedback: this.value.textualFeedback,
            videoInputActive: true,
        });
    }

    public emitActiveInput(activeInput: { textInputActive: boolean; videoInputActive: boolean }): void {
        this.emit({
            feedbackVideo: this.value.feedbackVideo,
            textInputActive: activeInput.textInputActive,
            textualFeedback: this.value.textualFeedback,
            videoInputActive: activeInput.videoInputActive,
        });
    }

    @Emit('input')
    public emit(feedback: {
        textualFeedback?: string;
        feedbackVideo?: File | Blob;
        textInputActive: boolean;
        videoInputActive: boolean;
    }): void {
        return;
    }

    public activateText(): void {
        this.emit({
            feedbackVideo: this.value.feedbackVideo,
            textInputActive: true,
            textualFeedback: this.value.textualFeedback,
            videoInputActive: false,
        });
    }

    public activateVideo(): void {
        this.emit({
            feedbackVideo: this.value.feedbackVideo,
            textInputActive: false,
            textualFeedback: this.value.textualFeedback,
            videoInputActive: true,
        });
    }
}
</script>

<style lang="scss">
.input-frame {
    border: solid 0.1rem $brand-color !important;
    border-top: 0 !important;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    border-bottom-left-radius: $default-border-radius !important;
    border-bottom-right-radius: $default-border-radius !important;
}
</style>