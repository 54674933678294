<template>
  <div>
    <!-- Quick links for mobile -->
    <div class="d-sm-none position-fixed quick-links">
      <div v-scroll-to="'#top'"
           class="d-flex quick-link-button justify-content-center align-items-center mb-3">
        <font-awesome-icon class="fa-flip-horizontal"
                           :icon="['far', 'arrow-to-top']" />
      </div>

      <div v-scroll-to="'#etymology'"
           class="d-flex quick-link-button quick-link-button--thick justify-content-center align-items-center mb-3">
          <font-awesome-icon class="fa-flip-horizontal"
                             :icon="['fal', 'tree-deciduous']" />
      </div>

      <div v-scroll-to="'#variants'"
           class="d-flex quick-link-button quick-link-button--thick justify-content-center align-items-center mb-3">
        <SvgIcon icon="belgium" />
      </div>

      <div v-scroll-to="'#similar'"
           class="d-flex quick-link-button justify-content-center align-items-center mb-3">
        <SvgIcon icon="is-approximately-equal-to"
                 :fill="true" />
      </div>

      <div v-scroll-to="'#subsigns'"
           :class="{'feature-inactive feature-inactive--background':!hasSubSigns}"
           class="d-flex quick-link-button justify-content-center align-items-center mb-3">
        <font-awesome-icon class="fa-flip-horizontal"
                           :icon="['far', 'puzzle-piece']" />
      </div>
    </div>
    <!-- End of quick links for mobile -->
    <loading :active="loadingGloss"
             :is-full-page="true" />
    <div class="gloss-detail container mb-4">
      <div v-if="gloss">
        <div class="gloss-detail__header d-flex justify-content-between mt-3 mb-3">
          <BackToSearch class="d-none d-lg-block ml-4 pl-2" />
          <div class="d-none d-sm-flex ml-auto mr-4">
            <span class="d-flex"
                                v-b-tooltip.hover="{ customClass: 'tooltip--accent' }"
                                :title="$t('gloss_detail.etymology')">
              <font-awesome-icon v-scroll-to="'#etymology'"
                                 class="color-accent clickable fa-flip-horizontal"
                                 :icon="['fal', 'tree-deciduous']" />
            </span>
            <div class="divider divider--vertical divider--light mx-3"></div>

            <SvgIcon v-scroll-to="'#variants'"
                     icon="belgium"
                     class="color-accent clickable"
                     v-b-tooltip.hover="{ customClass: 'tooltip--accent' }"
                     :title="$t('gloss_detail.regional_variants')" />
            <div class="divider divider--vertical divider--light mx-3"></div>

            <SvgIcon v-scroll-to="'#similar'"
                     icon="is-approximately-equal-to"
                     class="color-accent clickable"
                     v-b-tooltip.hover="{ customClass: 'tooltip--accent' }"
                     :title="$t('gloss_detail.similar_signs')" />
            <div class="divider divider--vertical divider--light mx-3"></div>
            <span class="d-flex"
                  v-b-tooltip.hover="{ customClass: hasSubSigns ? 'tooltip--accent' : 'tooltip--disabled' }"
                  :title="$t('gloss_detail.sign_composition')">
              <font-awesome-icon v-scroll-to="'#subsigns'"
                                 :class="{'feature-inactive':!hasSubSigns}"
                                 class="color-accent clickable fa-flip-horizontal"
                                 :icon="['far', 'puzzle-piece']" />
            </span>
          </div>
        </div>

        <SignDetail id="top"
                    class="mb-4"
                    :signDetail="currentActiveSign"
                    :loading="loadingVariants" />

        <div class="container vgtc-card mb-4 d-flex py-3 align-items-center position-relative">
          <font-awesome-icon class="fa-3x color-accent mr-3"
                             :icon="['fal','comment-minus']" />
          <div>{{$t('feedback.something_wrong_with_sign')}}</div>
          <button @click="$bvModal.show('reportProblemModal')"
                  class="ml-auto vgtc-button">
              <font-awesome-icon class="vgtc-button__icon"
                                 :icon="['fal','comment']" />
              <div>{{$t('feedback.report_problem')}}</div>
          </button>
        </div>

        <div id="etymology" class='mb-4'>
            <div class="sign-list p-3">
                <div class="sign-video-list__header mt-1 mb-3 ml-3">
                  <div class="d-flex align-items-center">
                      <font-awesome-icon class="fa-flip-horizontal gloss-detail__sign-list__icon mr-2"
                                         :icon="['fal', 'tree-deciduous']" />
                      <span class="gloss-detail__variants__header__text">{{$t('gloss_detail.etymology')}}</span>
                  </div>
                </div>
                <div class="divider divider--light"></div>
                <div class="sign-video-list__videos align-items-baseline row">
                  <div v-if="gloss.vimeo" class="m-left-95 mt-4">
                      <vue-vimeo-player :video-url='gloss.vimeo' :player-width='vimeoWidth'></vue-vimeo-player>
                  </div>
                  <div v-if="!gloss.vimeo" class="d-flex flex-grow-1">
                      <div class="d-flex justify-content-center align-items-center mx-auto my-4">
                        <span>{{$t('gloss_detail.no_etymology_video')}}</span>
                      </div>
                  </div>
                </div>
            </div>
        </div>

        <SignList :signs="variantsOfSign"
                  id="variants">
          <template>
            <SvgIcon class="gloss-detail__variants__header__region-icon mr-1"
                     icon="belgium" />
            <span class="gloss-detail__variants__header__text">{{$t('gloss_detail.regional_variants')}}</span>
          </template>
          <template v-slot:empty-state>
            <div class="d-flex justify-content-center align-items-center mx-auto my-4">
              <span>{{$t('gloss_detail.no_regional_variants')}}</span>
            </div>
          </template>
        </SignList>

        <SignList id="similar"
                  class="mt-4"
                  :signs="similarSigns"
                  :loading="loadingSimilarSigns">
          <template>
            <SvgIcon class="gloss-detail__sign-list__icon mr-2"
                     icon="is-approximately-equal-to"
                     :fill="true" />
            <span class="gloss-detail__variants__header__text">{{$t('gloss_detail.similar_signs')}}</span>
          </template>
          <template v-slot:empty-state>
            <div class="d-flex justify-content-center align-items-center mx-auto my-4">
              <span>{{$t('gloss_detail.no_similar_signs')}}</span>
            </div>
          </template>

        </SignList>

        <SignList v-if="hasSubSigns"
                  id="subsigns"
                  class="mt-4"
                  :signs="subSigns">
          <template>
            <div class="d-flex align-items-center">
              <font-awesome-icon class="fa-flip-horizontal gloss-detail__sign-list__icon mr-2"
                                 :icon="['far', 'puzzle-piece']" />
              <span class="gloss-detail__variants__header__text">{{$t('gloss_detail.sign_composition')}}</span>
            </div>
          </template>
          <template v-slot:empty-state>
            <div class="d-flex justify-content-center align-items-center mx-auto my-4">
              <span>{{$t('gloss_detail.no_subsigns')}}</span>
            </div>
          </template>
        </SignList>
      </div>
    </div>
    <ReportProblemModal :sign="currentActiveSign" />
  </div>
</template>
<script lang="ts">
import Loading from 'vue-loading-overlay';
import { Component, Vue, Watch } from 'vue-property-decorator';
import {
    IGlossDetail,
    ISignDetail,
    ISignOverview,
} from '../../../functions/src/shared/contracts/api/GlossContract';
import SignDetail from '../components/detail/SignDetail.vue';
import SignList from '../components/detail/SignList.vue';
import ReportProblemModal from '../components/feedback/ReportProblemModal.vue';
import BackToSearch from '../components/general/BackToSearch.vue';
import SvgIcon from '../components/SvgIcon.vue';
import { signService } from '../shared/services/SignService';
import { getSafely } from '../util/util-functions';
import { vueVimeoPlayer } from 'vue-vimeo-player';

@Component({ components: { BackToSearch, Loading, ReportProblemModal, SignDetail, SignList, SvgIcon, vueVimeoPlayer } })
export default class GlossDetail extends Vue {
    public gloss: IGlossDetail | null = null;

    public similarSigns: ISignOverview[] = [];

    public currentActiveSign: ISignDetail | null = null;

    public loadingVariants: boolean = false;
    public loadingSimilarSigns: boolean = false;
    public loadingGloss: boolean = false;

    private innerWidth: number = window.innerWidth;

    private loadedSignId: number = -1;

    public async created(): Promise<void> {
        this.initDetails();
    }

    public mounted(): void {
        window.onresize = () => {
            this.innerWidth = window.innerWidth
        }
    }

    public get vimeoWidth(): number {
        let maxWidth = 390;
        let newWidth = this.innerWidth - 100;
        if (newWidth > maxWidth) {
            return maxWidth;
        } else {
            return newWidth;
        }
    }

    public get variantsOfSign(): ISignDetail[] {
        return this.gloss!.variants.filter(sign => sign.signId !== this.loadedSignId);
    }

    public get subSigns(): ISignOverview[] {
        return getSafely(() => this.currentActiveSign!.subSigns, []);
    }

    public get hasSubSigns(): boolean {
        return this.subSigns.length > 0;
    }

    @Watch('$route.query.sid')
    private setActiveSign(): void {
        this.loadedSignId = Number.parseInt(this.$router.currentRoute.query.sid as string, 10);
        if (!isNaN(this.loadedSignId)) {
            const variant = this.gloss && this.gloss.variants.find(sign => sign.signId === this.loadedSignId);
            this.currentActiveSign = variant ? variant : null;
            window.scrollTo(0, 0);
        }
    }

    @Watch('$route.params.id')
    private async onGlossIdChange(): Promise<void> {
        await this.initDetails();
    }

    private async initDetails(): Promise<void> {
        const glossId = this.$router.currentRoute.params.id;
        await this.retrieveGloss(glossId);
        this.setActiveSign();
        await this.loadSimilarSigns();
    }

    private async loadSimilarSigns(): Promise<void> {
        if (!this.currentActiveSign) {
            return;
        }
        this.loadingSimilarSigns = true;

        this.loadingSimilarSigns = false;
        const overview = await signService.getSimilarSigns(this.currentActiveSign);
        this.similarSigns = overview.signOverviews.filter(so => so.signId !== this.loadedSignId);
    }

    private async retrieveGloss(glossId: string): Promise<void> {
        this.loadingGloss = true;
        try {
            this.gloss = await signService.getGlossDetail(glossId);
        } catch (e) {
            // TODO: handle error!
        } finally {
            this.loadingGloss = false;
        }
    }
}
</script>

<style lang="scss">
.gloss-detail__header__back {
    color: $accent-color;
    font-size: 0.875rem;

    &:hover {
        color: $accent-color-hover;
    }
}

.gloss-detail__variants__header__region-icon {
    height: 2rem;
    width: 3rem;
    color: $brand-color;
    path {
        fill: $footer-text-color;
    }
}

.gloss-detail__sign-list__icon {
    height: auto !important;
    width: 2rem !important;
    color: $brand-color;
}

.gloss-detail__variants__header__text {
    color: $text-title-color;
    font-size: $font-size-default;
    letter-spacing: 0.075rem;
    font-weight: normal;
    @include media-breakpoint-up(md) {
        font-size: $font-size-medium;
    }
}

.gloss-detail__signs__content {
    color: $card-text-color;
}

.gloss-detail__signs__content__icon {
    height: 1.5rem;
}

.quick-links {
    z-index: $zindex-popover;
    right: 0;
    bottom: 30%;
}

.quick-link-button {
    box-shadow: $box-shadow;
    width: 2.5rem;
    height: 2.5rem;
    color: white;
    border: none;
    background-color: $accent-color;
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;

    &--thick {
        path {
            stroke-width: 2rem;
        }
    }
}

.m-left-95 {
    margin-left: 30px !important;
}
</style>

