<template>
  <div class="d-flex flex-column">
    <div class="related-results-card p-4">
      <div class="d-flex align-items-center mb-4">
        <span class="related-results-card__overview__text">{{$t('search.did_you_mean')}}</span>
      </div>
      <div class="divider divider--light"></div>

      <div class="row mt-n4 align-items-baseline">
        <SearchResultCard class="col-12 col-md-6 col-xl-4 mt-5"
                          v-for="(result) in searchResults"
                          :key="result.signId"
                          :searchResult="result" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ISignOverview } from '../../../../functions/src/shared/contracts/api/GlossContract';
import AmountIndicator from '../AmountIndicator.vue';
import SearchFilterTagList from './SearchFilterTagList.vue';
import SearchResultCard from './SearchResultCard.vue';
import { SearchType } from './SearchType';
import { ITag } from './TagModel';

@Component({ components: { SearchFilterTagList, SearchResultCard, AmountIndicator } })
export default class RelatedSearchResult extends Vue {
    @Prop() public searchTags!: ITag[];
    @Prop() public totalNrOfSearchResults!: number;
    @Prop() public searchResults!: ISignOverview[];
}
</script>

<style lang="scss">
.sub-title {
    color: $sub-title-color;
}

.sub-title__text {
    letter-spacing: 0.1625rem;
}

.related-results-card {
    border: $card-border;
    border-radius: $default-border-radius;
    box-shadow: 1px 2px 2px 0 rgba(62, 62, 64, 0.1);
}

.related-results-card__overview__text {
    color: $text-title-color;
    font-size: $font-size-medium;
    letter-spacing: 0.075rem;
    @include media-breakpoint-up(md) {
        font-size: $font-size-big;
    }
}
</style>