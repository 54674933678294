<template>
  <div class="container">
    <div v-if="loading"
         class="d-flex justify-content-center mt-5">
      <b-spinner />
    </div>
    <div v-else-if="deleted"
         class="d-flex justify-content-center mt-5">
      <b-alert variant="danger"
               show>
        {{ $t('feedback.video_does_not_exist') }}
      </b-alert>
    </div>
    <div v-else-if="deleteSuccess"
         class="d-flex justify-content-center mt-5">
      <b-alert variant="success"
               show>
        {{ $t('feedback.video_delete_success') }}
      </b-alert>
    </div>
    <div v-else>
      <div class="d-flex justify-content-center">
        <h1 class="feeback-video__title text-large text-center pb-1 my-3">
          {{ $t('feedback.about')}}: <router-link v-if="sign"
                       :to="{ name: 'glossdetail', params: { id: sign.glossName }, query: { sid: sign.signId }}">
            {{ signSummary }}
          </router-link>
          <span v-else>
            {{ $t('feedback.application') }}
          </span>
        </h1>
      </div>
      <div class="row">
        <div class="col-12 col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3">
          <VideoPlayer :videoSrc="video"
                       :videoDownloadName="videoDownloadName" />
          <div class="d-flex justify-content-end mt-3">
            <button class="btn btn-danger"
                    @click="confirmDelete()">
              {{ $t('feedback.delete_video') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { ISignDetail } from '../../../functions/src/shared/contracts/api/GlossContract';
import VideoPlayer from '../components/video/VideoPlayer.vue';
import { feedbackService } from '../shared/services/FeedbackService';
import { signService } from '../shared/services/SignService';

@Component({ components: { VideoPlayer } })
export default class FeedbackVideo extends Vue {
    public deleted = false;
    public deleteSuccess = false;
    public loading = false;
    public sign: ISignDetail | null = null;
    public video: string | null = null;

    private videoName!: string;

    public async created(): Promise<void> {
        this.loading = true;
        try {
            const glossId = this.$router.currentRoute.query.glossId as string;
            const signId = this.$router.currentRoute.query.signId as string;
            if (glossId && signId) {
                const gloss = await signService.getGlossDetail(glossId);
                this.sign = gloss.variants.find(v => `${v.signId}` === signId) || null;
            }
            this.videoName = this.$router.currentRoute.params.name;
            const video = await feedbackService.getFeedbackVideo(this.videoName);
            this.video = URL.createObjectURL(video);
        } catch (e) {
            this.deleted = true;
        } finally {
            this.loading = false;
        }
    }

    public get videoDownloadName(): string {
        return `feedback-${this.$router.currentRoute.params.name}`;
    }

    public get signSummary(): string {
        return (this.sign && `${this.sign.translations.join(', ')} (${this.sign.regions.join(', ')})`) || '';
    }

    public async confirmDelete(): Promise<void> {
        const confirmed = await this.$bvModal.msgBoxConfirm(this.$t('feedback.delete_video_confirm').toString(), {
            cancelTitle: this.$t('general.cancel').toString(),
            okTitle: this.$t('general.ok').toString(),
        });
        if (confirmed) {
            await feedbackService.deleteFeedbackVideo(this.videoName);
            this.deleteSuccess = true;
        }
    }
}
</script>

<style lang="scss">
.feeback-video__title {
    color: $background-text-color;
    border-bottom: $divider;
}
</style>