<template>
  <div class="px-2 py-3">
    <VideoPlayer v-if="videoSrc"
                 class="mb-2"
                 :videoSrc="videoSrc"
                 :showDownloadButton="false"></VideoPlayer>
    <b-form-file v-model="value"
                 accept="video/mkv, video/webm, video/avi, video/mp4, video/mov"
                 :placeholder="$t('feedback.choose_video')"
                 :browseText="$t('feedback.select_video')">
    </b-form-file>
  </div>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import VideoPlayer from './VideoPlayer.vue';

@Component({ components: { VideoPlayer } })
export default class UploadVideoFrame extends Vue {
    public value: File | null = null;

    @Watch('value')
    @Emit('input')
    public emitFile(): File | null {
        return this.value;
    }

    public get videoSrc(): string | undefined {
        return this.value ? URL.createObjectURL(this.value) : undefined;
    }
}
</script>

<style lang="scss">
.custom-file-input {
    white-space: nowrap;
    cursor: pointer;
}

.custom-file-label {
    cursor: pointer;
    border: none !important;
    white-space: nowrap;
    color: transparent !important;
    text-overflow: clip;
    overflow: hidden;
    &::after {
        left: 0;
        text-align: center;
        border-radius: $default-border-radius !important;
        background-color: $accent-color !important;
        color: $accent-text-color !important;
        border: $medium-border-size solid $accent-color !important;
    }
}
</style>
