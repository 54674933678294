import {
    IAgeCategory,
    ICategory,
    ILocation,
    IRegion,
    ISex,
    ILabel
} from '@/../../functions/src/shared/contracts/api/MasterdataContract';
import { logService } from '@/shared/services/LogService';
import { axiosHttp } from '@/util/AxiosHttp';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { IHandshape } from '../../../functions/src/shared/contracts/HandshapeContract';
import { Constants } from '../shared/Constants';
import { ICacheState, IRootState } from './contract';
/**
 * Vuex store for preloading content from the backend
 * such as categories and handshapes.
 */

const cacheState: ICacheState = {
    ageCategories: [],
    categories: [],
    handshapes: [],
    labels: [],
    locations: [],
    regions: [],
    sexes: [],
};

const mutations: MutationTree<ICacheState> = {
    setAgeCategories(state: ICacheState, ageCategories: IAgeCategory[]) {
        state.ageCategories = ageCategories;
    },
    setCategories(state: ICacheState, categories: ICategory[]) {
        state.categories = categories;
    },
    setHandshapes(state: ICacheState, handshapes: IHandshape[]) {
        state.handshapes = handshapes;
    },
    setLabels(state: ICacheState, labels: ILabel[]) {
        state.labels = labels;
    },
    setLocations(state: ICacheState, locations: ILocation[]) {
        state.locations = locations;
    },
    setRegions(state: ICacheState, regions: IRegion[]) {
        state.regions = regions;
    },
    setSexes(state: ICacheState, sexes: ISex[]) {
        state.sexes = sexes;
    },
};
const actions: ActionTree<ICacheState, IRootState> = {
    async preloadData({ commit, state }): Promise<void> {
        await Promise.all([
            this.dispatch('cache/loadAgeCategories'),
            this.dispatch('cache/loadCategories'),
            this.dispatch('cache/loadHandshapes'),
            this.dispatch('cache/loadLabels'),
            this.dispatch('cache/loadLocations'),
            this.dispatch('cache/loadRegions'),
            this.dispatch('cache/loadSexes'),
        ]);
    },
    async loadAgeCategories({ commit, state }): Promise<void> {
        if (state.ageCategories && state.ageCategories.length) {
            // ageCategories have already been preloaded
            return;
        }
        try {
            const ageCategoryResponse = await axiosHttp.http.get(`${Constants.baseApiUrl}/ageCategories`);
            const ageCategories = ageCategoryResponse.data as IAgeCategory[];
            await commit('setAgeCategories', ageCategories);
        } catch (error) {
            logService.logError(`age-categories could not be retrieved: ${error}`);
            throw error;
        }
    },
    async loadCategories({ commit, state }): Promise<void> {
        if (state.categories && state.categories.length) {
            // categories have already been preloaded
            return;
        }
        try {
            const categoryResponse = await axiosHttp.http.get(`${Constants.baseApiUrl}/categories`);
            const categories = categoryResponse.data as ICategory[];
            await commit('setCategories', categories);
        } catch (error) {
            logService.logError(`Categories could not be retrieved: ${error}`);
            throw error;
        }
    },
    async loadHandshapes({ commit, state }): Promise<void> {
        if (state.handshapes && state.handshapes.length) {
            // categories have already been preloaded
            return;
        }
        try {
            const handshapeResponse = await axiosHttp.http.get(`${Constants.baseApiUrl}/handshapes`);
            const handshapes = handshapeResponse.data as IHandshape[];
            await commit('setHandshapes', handshapes);
        } catch (error) {
            logService.logError(`handshapes could not be retrieved: ${error}`);
            throw error;
        }
    },
    async loadLabels({ commit, state }): Promise<void> {
        if (state.labels && state.labels.length) {
            // labels have already been preloaded
            return;
        }
        try {
            const labelResponse = await axiosHttp.http.get(`${Constants.baseApiUrl}/labels`);
            const labels = labelResponse.data as ILabel[];
            await commit('setLabels', labels);
        } catch (error) {
            logService.logError(`Labels could not be retrieved: ${error}`);
            throw error;
        }
    },
    async loadLocations({ commit, state }): Promise<void> {
        if (state.locations && state.locations.length) {
            // categories have already been preloaded
            return;
        }
        try {
            const locationResponse = await axiosHttp.http.get(`${Constants.baseApiUrl}/locations`);
            const locations = locationResponse.data as ILocation[];
            await commit('setLocations', locations);
        } catch (error) {
            logService.logError(`locations could not be retrieved: ${error}`);
            throw error;
        }
    },
    async loadRegions({ commit, state }): Promise<void> {
        if (state.regions && state.regions.length) {
            // categories have already been preloaded
            return;
        }
        try {
            const regionResponse = await axiosHttp.http.get(`${Constants.baseApiUrl}/regions`);
            const regions = regionResponse.data as IRegion[];
            await commit('setRegions', regions);
        } catch (error) {
            logService.logError(`regions could not be retrieved: ${error}`);
            throw error;
        }
    },
    async loadSexes({ commit, state }): Promise<void> {
        if (state.sexes && state.sexes.length) {
            // sexes have already been preloaded
            return;
        }
        try {
            const sexResponse = await axiosHttp.http.get(`${Constants.baseApiUrl}/sexes`);
            const sexes = sexResponse.data as ISex[];
            await commit('setSexes', sexes);
        } catch (error) {
            logService.logError(`sexes could not be retrieved: ${error}`);
            throw error;
        }
    },
};

const getters: GetterTree<ICacheState, IRootState> = {
    ageCategories(state): IAgeCategory[] {
        return state.ageCategories;
    },
    categories(state): ICategory[] {
        return state.categories;
    },
    handshapes(state): IHandshape[] {
        return state.handshapes;
    },
    labels(state): ILabel[] {
        return state.labels;
    },
    locations(state): ILocation[] {
        return state.locations;
    },
    regions(state): IRegion[] {
        return state.regions;
    },
    sexes(state): ISex[] {
        return state.sexes;
    },
};

const namespaced = true;

export const cache: Module<ICacheState, IRootState> = {
    actions,
    getters,
    mutations,
    namespaced,
    state: cacheState,
};
