<template>
  <div class="d-flex">
    <div v-for="(nb,index) in nbOfStars"
         :key="index"
         :ref="`${baseRef}`"
         @click="ratingChanged(index)">
      <font-awesome-icon class="fa-3x color-brand clickable"
                         :icon="getIcon(index)" />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

@Component({ components: {} })
export default class StarRating extends Vue {
    @Prop({ default: 5 }) public nbOfStars!: number;
    @Prop() public value!: number;
    public baseRef: string = `ratingStar_${Math.random()}`;

    private hoveredNb: number = -1;

    public mounted(): void {
        this.getStars().forEach((star, index) => {
            star.addEventListener('mouseenter', () => {
                this.hoveredNb = index;
            });
            star.addEventListener('mouseleave', () => {
                this.hoveredNb = -1;
            });
        });
    }

    @Emit('input')
    public ratingChanged(index: number): number {
        return index + 1;
    }

    public getIcon(index: number): string[] {
        return index >= Math.max(this.value, this.hoveredNb + 1) ? ['far', 'star'] : ['fas', 'star'];
    }

    public getStars(): HTMLDivElement[] {
        return this.$refs[`${this.baseRef}`] as HTMLDivElement[];
    }
}
</script>

<style lang="scss">
</style>