import messages from '@/lang/nl';
import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const i18n = new VueI18n({
    fallbackLocale: 'en',
    locale: 'nl',
    messages,
});

const loadedLanguages = ['nl'];

function setI18nLanguage(lang: string) {
    i18n.locale = lang;
    return lang;
}

export async function loadLanguage(lang: string): Promise<string> {
    if (i18n.locale === lang) {
        return lang;
    }
    if (loadedLanguages.includes(lang)) {
        return setI18nLanguage(lang);
    }
    const msgs = await import(/* webpackChunkName: "lang-[request]" */ `@/lang/${lang}`);
    i18n.setLocaleMessage(lang, msgs.default);
    loadedLanguages.push(lang);
    return setI18nLanguage(lang);
}

document.title = i18n.t('header.vgtc_dutch').toString();

export default i18n;
